/* eslint-disable */
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button, Spinner } from 'reactstrap'
import CloseIcon from '@/images/closeIcon'
import PaperPlaneIcon from '@/images/paperPlaneIcon'


const ModalModeration = ({open, close }) => {   

    return(
        <>
            <Modal className="moderationModal" show={open} onHide={close} size="md" centered >
                <Button onClick={close} className="modalconfirm-closebtn"><CloseIcon color="#383938" /></Button>
                <Modal.Body className="text-center" >
                    <PaperPlaneIcon color="#009ADC" />
                    <h3 className="mt-3"><b>Request sent!</b></h3>
                    <p>As soon as the changed information is moderated, it will be automatically updated in your profile</p>
                    <p>The moderation status will be displayed on the profile overview page</p>
                    <div className="pt-4">
                        <Button color="cancel" className="mb-0 mr-3" onClick={close}>Cancel</Button>
                        <Button color="primary" className="mb-0" onClick={close}>Ok</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalModeration;