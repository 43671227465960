/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import PencilIcon from '@/images/pencilIcon'
import DataTable from "react-data-table-component";
import ApiServices from "@/utils/ApiServices";
import Empty from "@/components/Empty";
import { TABLE_SETTINGS, USERLEVELS } from "@/utils/constant";
import ToggleSwitch from '@/components/ToggleSwitch'
import CustomPagination from '@/components/CustomPagination'

const StaffTable = (props) => {     
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowCount, setRowCount] = useState(0)
    const page_ref = useRef(1);
    const saerch_ref = useRef(null);

    const columns = useMemo(
        () => [
            {
                name: "Username",
                selector: "username",
                sortable: false,
            },
            {
                name: "Full Name",
                selector: "fullname",
                sortable: false,
            },
            {
                name: "User level",
                selector: "user_level",
                sortable: false,
            },
            {
                name: "Phone No. 1",
                selector: "homephone1",
                sortable: false,
            },
            {
                name: "Phone No. 2",
                selector: "homephone2",
                sortable: false,
            },
            {
                name: "Email Address",
                selector: "email",
                sortable: false,
            },
            {
                name: "isActive	",
                selector: "status",
                sortable: false,
                center: true
            },
            // {
            //     cell: (row) => (
            //         <button className="btn-link" onClick={()=> changePassword(row)}>Change Password</button>
            //     ),
            // },
            {
                cell: (row) => (
                    <Button color="secondary" className="mb-0" onClick={()=> handlEdit(row)}><PencilIcon /> Edit</Button>
                ),
            },
        ],
    );

    
    const changePassword = (row) => {
       props.onChangePassword(parseInt(row.id))
    } 

    const handlEdit = (row) => {
        history.push({ pathname: `/account-page/update/${row?.id}`})
   } 
    
    
    const fetchData = async (page, size = perPage, ) => {  
        ApiServices.staffList({'page' : page, 'per_page': size, status: props.status})
        .then((result) => {     
            setData(result?.data?.data)
            setRowCount(result.data.data.length)
            setTotalRows(result.data.total)
            setLoading(false)
        })
        .catch(function (error) {
        });
    };

    useEffect(() => {
        fetchData(1);
    }, [props]);
    

   
    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => { 
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };
    const dataTable = []
    if(Array.isArray(data)){
        for (let index = 0; index < data.length; index++) {
            dataTable.push({
                id: data[index].id,
                username: data[index].username,
                fullname: `${data[index].client_first_name} ${data[index].client_last_name}`,
                user_level: data[index].userlevel === USERLEVELS.ADMIN ? 'Admin' : 'Staff',
                company_name: data[index].company_name,
                homephone1: data[index].homephone1,
                homephone2: data[index].homephone2,
                email: data[index].email,
                status: <div>
                    <ToggleSwitch id={`general-switch`} checked={data[index].status === 'A' ? true: false} disabled={true} // onChange={onChangeStatus}
                            /></div>
            })
        }
    }

    return (
        <div className="activeProperties__wrapper">
            <DataTable
                columns={columns}
                data={dataTable}
                progressPending={loading}
                pagination={false}
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                striped={TABLE_SETTINGS.STRIPED}
                highlightOnHover={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                paginationRowsPerPageOptions={TABLE_SETTINGS.ROW_PER_PAGE}
                noDataComponent={<Empty />}
            />
               <CustomPagination rowCount={rowCount} currentPage={currentPage} totalRows={totalRows} perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE} handlePageChange={handlePageChange} />
       </div>
    );
};

export default StaffTable;
