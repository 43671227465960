/* eslint-disable */
import React, { useState, useRef } from "react";
import queryString from "query-string";
import { useForm } from "react-hook-form";
import { Button, Spinner } from 'reactstrap'
import { Link, NavLink, useHistory, useParams } from "react-router-dom";
import ResetPasswordForm from "@/shared/components/reset_password/ResetPasswordForm";
import _ from "lodash";
import { successMsg, errorMsgCustom, successMsgCustom } from "@/utils/message_herlper"
import { HOME_API } from '@/utils/constant'
import ApiServices from "@/utils/ApiServices"; 
import "@/shared/components/login_register/register.css";
import './reset.scss';


const ResetForm = (props) => {
    const { register, errors, handleSubmit, watch } = useForm({});
    const history = useHistory();
    const password = useRef({});
    password.current = watch("password", "");

    const [isSubmit, setSubmit] = useState(false);
    let { key } = useParams(); 
    let url_params = atob(key);
    let { email, token} = queryString.parse(url_params);
  
    const onSubmit = (item) => {
        setSubmit(true)
        item.email = email
        item.token = token
        ApiServices.resetPasswordNew(item).then((result) => {
            successMsgCustom('Your password was successfully updated.');
            history.push({pathname: `/login`});
        }).catch(function (error) {
            setSubmit(false)
            errorMsgCustom("Token has expired!");
        });
    };

    return (
        <div className="account account--not-photo">
            <div className="account__wrapper">
                <div className="account__card">
                    <Link className="topbar__logo" to="/" height="150px" />
                    <div className="client-registration-form-container">
                    <form  onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <label>Password</label>
                                    <input
                                        name="password"
                                        type="password"
                                        ref={register({
                                        required: "You must specify a password",
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters"
                                        }
                                        })}
                                        className={errors?.password ? 'error-field' : ''}
                                    />
                                    {errors.password && <p className="form__form-group-error">{errors.password.message}</p>}
                                </div>
                                <div className="mt-3">
                                    <label>Repeat password</label>
                                    <input
                                        name="password_repeat"
                                        type="password"
                                        ref={register({
                                        validate: value =>
                                            value === password.current || "The passwords do not match"
                                        })}
                                        className={errors?.password_repeat ? 'error-field' : ''}
                                    />
                                    {errors.password_repeat && <p className="form__form-group-error">{errors.password_repeat.message}</p>}
                                </div>
                                <div className="client-create-btns  mt-4">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmit}
                                    >
                                        {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}
                                        Change Password
                                    </Button>
                                </div>
                            </form>
                        </div>
                    
                    <div className="account__have-account">
                        <p>
                            Remember your password?{" "}
                            <NavLink to="/login">Login</NavLink>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetForm;
