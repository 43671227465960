/* eslint-disable */
import React, { useEffect, useMemo, useState, useRef } from "react";
import queryString from "query-string";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from "reactstrap";
import { Link,  useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import TableLoading from "@/components/TableLoading";
import ApiServices from "@/utils/ApiServices";
import { TABLE_SETTINGS } from "@/utils/constant";
import { globalError } from "@/utils/message_herlper";
import Empty from "@/components/Empty";
import AngleArrowLeft from '@/images/angleArrowLeft';

const PropertiesByZip = (props) => {
    const history = useHistory()
    const location = useLocation()
    const [isSubmit, setSubmit] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [year, setYear] = useState(null);
    const yearRef = useRef(null);
    const countyIDRef = useRef(null);
    const countyRef = useRef(null);
    const countyNameRef = useRef(null);
    const propertyRef = useRef(null)
    const billingRef = useRef(null)
    const resultRef = useRef(null)
    const idRef = useRef(null)
    const nameRef = useRef(null)
    const [startDate, setStartDate] = useState(new Date());
    const dataTable = [];
    const list = data;

    useEffect(() => {  
        let { key } =  props?.match?.params
        if(key){
            let url_params = atob(key)
            console.log(url_params)
            let { year, id, name } = queryString.parse(url_params) 
            console.log(year, id)
            idRef.current = id;
            yearRef.current = year;
            nameRef.current = name
            setYear(year);
            fetchData(1);
        }
    }, [location]);

  
    const fetchData = async (
        page,
        size = perPage,
        id = idRef.current,
        year = yearRef.current,
    ) => {
        setData([]);
        ApiServices.accountSpecificBilling({
            page: page,
            per_page: size,
            id: id,
            year: year,
        })
            .then((result) => {   
                let details = result?.data;
                setData(result.data.data);
                setTotalRows(result.data.total);
                setLoading(false);
            })
            .catch((error) => {
                globalError();
            });
    };

    const handlView  =  data  => {
        console.log(data.county_id)
    }


    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };

    const columns = useMemo(() => [
        {
            name: "Owner's Name",
            selector: "owner_name",
            sortable: false,
        },
        {
            name: "Account No.",
            selector: "account_number",
            sortable: false,
        },
        {
            name: "Noticed Value",
            selector: "noticedValue",
            sortable: false,
        },
        {
            name: "Final Value",
            selector: "settledValue",
            sortable: false,
        },
        {
            name: "Amount of Reduction",
            selector: "reduction",
            sortable: false,
        },
        {
            name: "Tax Rate",
            selector: "taxrate",
            sortable: false,
        },
        {
            name: "Tax Savings",
            selector: "taxsavings",
            sortable: false,
        },
        {
            name: "Billing Percentage",
            selector: "billingPercentage",
            sortable: false,
        },
        {
            name: "Invoice Amount",
            selector: "invoiceamount",
            sortable: false,
        },
        {
            name: "Results",
            selector: "result",
            sortable: false,
        },
        {
            name: "Agent",
            selector: "agent_id",
            sortable: false,
        },
    ]);

    if(Array.isArray(list)){
        for (let index = 0; index < list.length; index++) {
            dataTable.push({
                owner_name: list[index].owner_name,
                account_number: list[index].account_number,
                noticedValue: `$${list[index].noticedValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                settledValue: `$${list[index].settledValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                reduction: `$${list[index].reduction.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                taxrate: `${list[index].taxrate}%`,
                taxsavings: `$${list[index].taxsavings.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                billingPercentage: `${list[index].billingPercentage}%`,
                invoiceamountOrig: list[index].invoiceamount,
                invoiceamount: `$${list[index].invoiceamount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                result: list[index].result_name,
                agent_id: list[index].agent_fname ? list[index].agent_fname + ' ' + list[index].agent_lname:'',
            });
        }
    }

    const exportReport = () => {
        setSubmit(true);
        ApiServices.notInvoiceExport({ year: yearRef.current })
            .then((result) => { 
                setTimeout(() => {
                    setSubmit(false);
                    const url = window.URL.createObjectURL(
                        new Blob([result.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    const fileName = `Not Invoiced Report for Year ${yearRef.current}.csv`;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }, 1000);
            })
            .catch((error) => {
                setSubmit(false);
                globalError();
            });
    };

    const goBack = () => {
        history.push({ pathname: `/reports/not-invoiced`})
    };

    console.log('year', year)
    return (
        <Container>
            <TitleBreadCrumbs title={`${year} Account Specific Report Billing`}>
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="#">Reports</Link>
                    <Link to="#">{year}Account Specific Report</Link>
                    <span className="current_breadcrumbs">  Account Specific Report Billing </span>
                </div>
                <div className="mb-4">
                    <Link className="backto-link" to={`/reports/account-specific-report/${idRef.current}/${nameRef.current}`}><AngleArrowLeft color="#FFA647" />Back to  Account Specific Report</Link>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} className="edit-county-wrap">
                                    <DataTable
                                        columns={columns}
                                        data={dataTable}
                                        progressPending={loading}
                                        progressComponent={
                                            <TableLoading />
                                        }
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={
                                            currentPage
                                        }
                                        striped={TABLE_SETTINGS.STRIPED}
                                        highlightOnHover={true}
                                        onChangeRowsPerPage={
                                            handlePerRowsChange
                                        }
                                        onChangePage={handlePageChange}
                                        paginationPerPage={
                                            TABLE_SETTINGS.PAGINATION_PER_PAGE
                                        }
                                        paginationRowsPerPageOptions={
                                            TABLE_SETTINGS.ROW_PER_PAGE
                                        }
                                        noDataComponent={<Empty />}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default PropertiesByZip;