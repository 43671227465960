/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { 
    Card, CardBody, Col, Container, Row
  } from 'reactstrap';

import { Field, reduxForm } from 'redux-form';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import AccountOutlineIcon from 'mdi-react/AccountIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import UndoIcon from 'mdi-react/UndoIcon';
import { removeSpecials } from '@/utils/helpers';
import ApiServices from "@/utils/ApiServices";
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import AngleArrowLeft from '@/images/angleArrowLeft';


const ClientSearch = (props) => {
    localStorage.setItem('account', 'false');
    const history = useHistory();
    const formInitialData = ({ fname: '', lname: '', phone: '', email: '', company: '', address: '', address2: '', city: '', state: '', county: '', zipcode: ''});
    const [formData, updateFormData] = useState(formInitialData);
    const [isActive, setIsActive] = useState(true);
    const [counties, setCounty] = useState([]);

    useEffect(() => { 
        getCounty()
    },[props])

    const getCounty = () =>{
        ApiServices.county()
        .then((result) => {   
            setCounty(result.data);
        })
        .catch(function (error) {
        });
    }

    const handleisActive = () => {
        setIsActive(!isActive);
    }

    const handleInputChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name] : e.target.value.trim()
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        let params = `?type=client&first_name=${removeSpecials(formData?.fname)}&last_name=${removeSpecials(formData?.lname)}&phone=${removeSpecials(formData?.phone)}&email=${formData?.email}&company=${removeSpecials(formData?.company)}&address=${removeSpecials(formData?.address)}&address2=${removeSpecials(formData?.address2)}&city=${removeSpecials(formData?.city)}&state=${removeSpecials(formData?.state)}&county=${formData?.county}&zipcode=${removeSpecials(formData?.zipcode)}&active=${isActive}`
        history.push({ pathname: `/custom-search-results/${btoa(params)}` });
      }

    const resetFields = () => {
        document.getElementById('client_search_form').reset();
    }

    return (
        <Container>
            <TitleBreadCrumbs title="Client Search"   link={`/clients`}  btn="View all clients" >
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="/clients">Clients</Link>
                    <span className="current_breadcrumbs">Client Search</span>
                </div>
                {/* <div className="mb-4">
                   <Link className="backto-link" to={`/clients`}><AngleArrowLeft color="#FFA647" />View to all clients</Link>
                </div> */}
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card className="client-search-container">
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <p>You can search for a client using the following filters:</p>
                                </Col>
                                <Col md={6}>
                                    <ButtonToolbar className="activeInactiveSearch">
                                        <ButtonGroup className="me-2" >
                                            <Button type="button" onClick={handleisActive} className={`${isActive ? 'active' : ''}`}>Active</Button>
                                            <Button  onClick={handleisActive} className={`${ !isActive ? 'active' : ''}`} type="button">Inactive</Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </Col>
                                <Col md={12}> 
                                    <form id="client_search_form" onSubmit={handleFormSubmit}>
                                        <div className="form__form-group-two">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">First Name:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <AccountOutlineIcon />
                                                    </div>
                                                    <Field
                                                        name="fname"
                                                        component="input"
                                                        type="text"
                                                        placeholder="First Name"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">Last Name:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <AccountOutlineIcon />
                                                    </div>
                                                    <Field
                                                        name="lname"
                                                        component="input"
                                                        type="text"
                                                        placeholder="Last Name"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__form-group-two">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">Phone #:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <AccountOutlineIcon />
                                                    </div>
                                                    <Field
                                                        name="phone"
                                                        component="input"
                                                        type="text"
                                                        placeholder="Phone Number"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">Email Address:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <AccountOutlineIcon />
                                                    </div>
                                                    <Field
                                                        name="email"
                                                        component="input"
                                                        type="email"
                                                        placeholder="Email Address"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Company Name:</span>
                                            <div>
                                                <Field
                                                    name="company"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Company Name"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Address:</span>
                                            <div className="form__form-group-field">
                                                <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div>
                                                <Field
                                                    name="address"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Address"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Address 2:</span>
                                            <div className="form__form-group-field">
                                                <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div>
                                                <Field
                                                    name="address2"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Address 2"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group-two">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">City:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <MapMarkerIcon />
                                                    </div>
                                                    <Field
                                                        name="city"
                                                        component="input"
                                                        type="text"
                                                        placeholder="City"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">State:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <MapMarkerIcon />
                                                    </div>
                                                    <Field
                                                        name="state"
                                                        component="input"
                                                        type="text"
                                                        placeholder="State"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__form-group-two">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">County:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <MapMarkerIcon />
                                                    </div>
                                                    <Field
                                                        name="county"
                                                        component="select"
                                                        className="select-light-border"
                                                        onChange={handleInputChange}
                                                        >
                                                           <option value="">Select County</option>
                                                        {counties.map((obj,i) => (
                                                            <option key={i} value={obj.id}>{obj.name}</option>
                                                        ))}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">ZipCode:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <MapMarkerIcon />
                                                    </div>
                                                    <Field
                                                        name="zipcode"
                                                        component="input"
                                                        type="text"
                                                        placeholder="ZipCode"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="property-search-btns">
                                            <Button type="button" className="cancel__btn" onClick={resetFields}><UndoIcon /> Reset</Button>
                                            <Button type="submit" color="primary" className="search_btn"><SearchIcon /> Search</Button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default reduxForm({
    form: 'client_search',
})(ClientSearch);