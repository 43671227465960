/* eslint-disable */
import React, { useEffect, useMemo, useState, useRef } from "react";
import queryString from "query-string";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Spinner,
} from "reactstrap";
import moment from "moment"
import { Link,  useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import TableLoading from "@/components/TableLoading";
import ApiServices from "@/utils/ApiServices";
import { TABLE_SETTINGS } from "@/utils/constant";
import { globalError } from "@/utils/message_herlper";
import Empty from "@/components/Empty";

const PropertiesByZip = (props) => {
    const history = useHistory()
    const location = useLocation()
    const [isSubmit, setSubmit] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [year, setYear] = useState(null);
    const yearRef = useRef(null);
    const countyIDRef = useRef(null);
    const countyRef = useRef(null);
    const [startDate, setStartDate] = useState(new Date());
    const dataTable = [];
    const list = data;


    useEffect(() => {  
        let {county_id, year, county} =  props?.match?.params
        if(county_id, year){  
            yearRef.current = parseInt(year);
            countyIDRef.current = parseInt(county_id);
            countyRef.current = county;
           fetchData(1);
        }
    }, [location]);

  
    const fetchData = async (
        page,
        size = perPage,
        year = yearRef.current,
        county_id = countyIDRef.current,
    ) => {
        setData([]);
        ApiServices.notInvoiceProperties({
            page: page,
            per_page: size,
            year: year,
            county_id: county_id,
        })
            .then((result) => {    console.log(result?.data)
                let details = result?.data;
                setData(result.data.data);
                setTotalRows(result.data.total);
                setLoading(false);
            })
            .catch((error) => {
                globalError();
            });
    };

    const handlView  =  data  => {
        console.log(data.county_id)
    }

    const onSubmit = (event) => {
        event.preventDefault()
        let year = parseInt(moment(startDate).format('YYYY'));
        history.push({ pathname: `/reports/not-invoiced/${year}`})
    };

    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };

    const columns = useMemo(() => [
        {
            name: "Owner's Name",
            selector: "owner_name",
            sortable: false,
        },
        {
            name: "Address",
            selector: "property_address",
            sortable: false,
        },
        {
            name: "City",
            selector: "city",
            sortable: false,
        },
        {
            name: "State",
            selector: "state",
            sortable: false,
        },
        {
            name: "Zip",
            selector: "zip",
            sortable: false,
        },
        {
            name: "Account No.",
            selector: "account_number",
            sortable: false,
        },
    ]);

    if(Array.isArray(list)){
        for (let index = 0; index < list.length; index++) {
            dataTable.push({
                owner_name: list[index].owner_name,
                property_address: list[index].property_address,
                city: list[index].property_city,
                state: list[index].property_state,
                zip: list[index].property_zip,
                account_number: list[index].account_number,
            });
        }
    }

    const exportReport = () => {
        setSubmit(true);
        ApiServices.notInvoiceExport({ year: yearRef.current })
            .then((result) => { 
                setTimeout(() => {
                    setSubmit(false);
                    const url = window.URL.createObjectURL(
                        new Blob([result.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    const fileName = `Not Invoiced Report for Year ${yearRef.current}.csv`;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }, 1000);
            })
            .catch((error) => {
                setSubmit(false);
                globalError();
            });
    };

    const goBack = () => {
        history.push({ pathname: `/reports/not-invoiced`})
    };

    return (
        <Container>
            <TitleBreadCrumbs title="Properties Not Invoiced">
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="#">Reports</Link>
                    <Link to="/reports/not-invoiced">Properties By Zip</Link>
                    <span className="current_breadcrumbs">   Properties Results</span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} className="edit-county-wrap">
                                    <div className="text-center mb-4">
                                        <h4>{countyRef.current}'s Properties Not Invoiced for The Year {yearRef.current}:</h4>
                                    </div>
                                    <DataTable
                                        columns={columns}
                                        data={dataTable}
                                        progressPending={loading}
                                        progressComponent={
                                            <TableLoading />
                                        }
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={
                                            currentPage
                                        }
                                        striped={TABLE_SETTINGS.STRIPED}
                                        highlightOnHover={true}
                                        onChangeRowsPerPage={
                                            handlePerRowsChange
                                        }
                                        onChangePage={handlePageChange}
                                        paginationPerPage={
                                            TABLE_SETTINGS.PAGINATION_PER_PAGE
                                        }
                                        paginationRowsPerPageOptions={
                                            TABLE_SETTINGS.ROW_PER_PAGE
                                        }
                                        noDataComponent={<Empty />}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default PropertiesByZip;