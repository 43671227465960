/* eslint-disable */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Button, Row, Col } from 'reactstrap';
import { useForm } from "react-hook-form";
import { FormField } from "@/shared/components/FormField";
import ListNotes from '@/components/note-list'
import LoadingIcon from 'mdi-react/LoadingIcon';


const ClientNotes = ({ onSubmit, isSubmit, notes, resetNotes, setCount, deleteNotes, editNotes }) => { 
    const { handleSubmit, control, errors, setValue} = useForm();

    useEffect(() => { 
        if(resetNotes) 
           setValue('notes', '')
    },[resetNotes])

    useEffect(() => {
        setCount(notes.length)
    }, [notes])

    const expandClass = classNames({
        icon: true,
        expand: true,
        'expand--load': isSubmit,
    });

    return (
        <Row>
            <Col md={12} className="pt-4">
                <p className="tab-content-title mb-4">Notes</p>
            </Col>
            <Col md={12}>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <FormField
                        className="notes-text"
                        name="notes"
                        component="textarea"
                        control={control}
                        errors={errors}
                        placeholder="Write here.."
                        rows={4}
                        rules={{
                            required: "This is required field",
                        }}
                    // onChange={handleChange}
                    />
                    {errors?.notes && <span className="form__form-group-error">{errors?.notes?.message}</span>}

                    <div className="pt-2">
                        <Button type="submit" color="primary" className={isSubmit ? expandClass : ''}>
                        {isSubmit ? <LoadingIcon /> : ''}
                        Submit
                        </Button>
                    </div>
                </form>
            </Col>
            <Col md={12}>
                <ListNotes notes={notes} isClient={true}  deleteNotes={deleteNotes} editNotes={editNotes}/> 
            </Col>            
        </Row>
    );
}

export default ClientNotes;