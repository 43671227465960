/* eslint-disable */
import React from 'react';
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import { Row, Col, Container, Card, CardBody, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import AllActivities from './components/allActivities';

const Activities = () => {

    let { type } = useParams()

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <TitleBreadCrumbs title="Activities" ></TitleBreadCrumbs>
                </Col>
                <Col md={12}>
                    <Card className="pb-0">
                        <CardBody>
                            <AllActivities type={type} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
export default Activities;