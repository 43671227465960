/* eslint-disable */

const TrashIcon = ({ color="#A8B5BF", custom }) => {
    return (
    <svg className={`${custom && custom} custom`} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 3.7998H2.4H13.6" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.4999 3.8V2.4C4.4999 2.0287 4.6474 1.6726 4.90995 1.41005C5.1725 1.1475 5.5286 1 5.8999 1H8.6999C9.07121 1 9.4273 1.1475 9.68985 1.41005C9.9524 1.6726 10.0999 2.0287 10.0999 2.4V3.8M12.1999 3.8V13.6C12.1999 13.9713 12.0524 14.3274 11.7899 14.5899C11.5273 14.8525 11.1712 15 10.7999 15H3.7999C3.4286 15 3.0725 14.8525 2.80995 14.5899C2.5474 14.3274 2.3999 13.9713 2.3999 13.6V3.8H12.1999Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.8999 7.2998V11.4998" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.7002 7.2998V11.4998" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}
export default TrashIcon;