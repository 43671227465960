/* eslint-disable */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CloseIcon from "mdi-react/CloseIcon";
import SearchIcon from "mdi-react/SearchIcon";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Button } from "reactstrap";
import { errorMsg } from "../../../utils/message_herlper"
import "./topbar-style.css";

const TopbarSearch = () => {
    const history = useHistory();
    const [typeSearch, setTypeSearch] = useState("ALL");
    const [type, setType] = useState("all");
    const [keyword, setKeyword] = useState("");
    const [isActive, setIsActive] = useState('true');
    const handleisActive = (type) => {
        setIsActive(type);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault()
        if(!keyword) {
            document.getElementById("top-search").focus();
            errorMsg('EROR_SEARCH');
            return
        }
        history.push({pathname: `/search-results/${type}/${keyword}/${isActive}`});
    };

    return (
        <form className="topbar__search" onSubmit={handleSearchSubmit}>
            <Dropdown className="topbar-search-toggle">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {typeSearch}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item
                        onClick={() => {
                            setTypeSearch("All");
                            setType("all");
                        }}
                    >
                        All
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                            setTypeSearch("Property Search");
                            setType("property");
                        }}
                    >
                        Property Search
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                            setTypeSearch("Client Search");
                            setType("client");
                        }}
                    >
                        Client Search
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <input
                id="top-search"
                placeholder="Search..."
                className="topbar__search-field"
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
            />
            <Button type="submit" className="topbar-search-btn">
                <SearchIcon />
            </Button>
         <ButtonToolbar className="activeInactiveSearch">
            <ButtonGroup className="me-2" >
                <Button type="button" onClick={()=> handleisActive('true')} className={`${isActive === 'true' ? 'active' : ''}`}>Active</Button>
                <Button   onClick={()=> handleisActive('false')} className={`${ isActive === 'false' ? 'active' : ''}`} type="button">Inactive</Button>
                <Button   onClick={()=> handleisActive('all')} className={`${ isActive === 'all' ? 'active' : ''}`} type="button">&nbsp;&nbsp;&nbsp;All&nbsp;&nbsp;&nbsp;</Button>
            </ButtonGroup>
            </ButtonToolbar>
        </form>
    );
};

export default TopbarSearch;
