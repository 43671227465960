/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Spinner,
} from "reactstrap";
import { FormField, FormSelect } from "@/shared/components/FormField";
import APIServices from "../../../utils/ApiServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ARBITRATION_1 } from "@/utils/constant";
import moment from "moment";

/**
 *
 * @param {{
 *  id?: number,
 * }} param0
 * @returns
 */
export default function ArbitrationForm({ id, firstData, arbitration }) {  
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [type, setType] = useState('submit');
    const [confirmedArb, setConfirmedArb] = useState(null);
    const [deniedArb, setDeniedArb] = useState(null);
    const [billPerc, setBillPerc] = useState(0);
    const {
        handleSubmit,
        reset,
        control,
        errors,
        register,
        setValue,
        clearErrors,
        formState,
        watch,
        setError,
    } = useForm();

    useEffect(() => {
        if (firstData) {
            let final_value = firstData?.settledValue || 0;
            setValue("final_value", final_value);
            setValue("tax_year", firstData?.year);
            setValue("billing_percentage", firstData?.billingPercentage);
            setBillPerc(firstData?.billingPercentage);
            setValue("tax_rate", firstData?.taxrate);
        }
    }, [firstData]);

    useEffect(() => {
      if (arbitration) {
          const{option_of_value, upfront_filing_fee, refunded, rpt_filing_fee, filing_fee, deadline, confirmed_arb_lit, denied_arb_lit} = arbitration;
          setValue("option_of_value", option_of_value);
          setValue("upfront_filing_fee", upfront_filing_fee);
          setValue("filing_fee", filing_fee);
          setValue("refunded", refunded);
          setValue("rpt_filing_fee", rpt_filing_fee);
          setValue("confirmed", confirmed_arb_lit);
          setValue("denied", denied_arb_lit);
          setConfirmedArb(confirmed_arb_lit);
          setDeniedArb(denied_arb_lit);
          //alert(deadline);
          let d = new Date(deadline);
          setStartDate(d);
      }
  }, [arbitration]);

    

    const onSubmit = async (form) => {
        console.log(form);
        let show_msg = 'Send Arbitration Email?';
        if(type == 'save')
            show_msg = 'Save Data?';
        if (window.confirm(show_msg)) {
            form.deadline =  moment(startDate).format('YYYY-MM-DD'); 
            form.type = type;
            setAllowSubmit(true);
            APIServices.sendArbitrationLetter({
                id,
                ...form,
            })
                .then((result) => {
                    console.log(result);
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setAllowSubmit(false);
                });
        }
    };

    const [startDate, setStartDate] = useState(null);

    // const watchedValues = watch();

    // useEffect(() => {
    //   //watchedValues?.option_of_value
    // }, [watchedValues]);

    // Watch the specific field
    const optionOfValue = watch("option_of_value");
    const filing_fee = watch("filing_fee");
    const rpt_filing_fee = watch("rpt_filing_fee");

    useEffect(() => {
        let final_value = firstData?.settledValue || 0;
        let taxrate = firstData?.taxrate || 0;
        let potencial_reduction = final_value - optionOfValue;
        let potential_tax_savings = potencial_reduction * taxrate;
        let filing_fee_refund = parseFloat(filing_fee) - 50;
        if (optionOfValue !== undefined) {
            const isNumber = !isNaN(optionOfValue);
            if (!isNumber) {
                setError("option_of_value", {
                    type: "manual",
                    message: "Value must be a number",
                });
                setValue("potencial_deduction", 0);
            } else {
                clearErrors("option_of_value");
                setValue("potencial_deduction", potencial_reduction);
                setValue("potential_tax_savings", potential_tax_savings.toFixed(2));
            }
        }

        if (filing_fee !== undefined) {
            const isNumber = !isNaN(filing_fee);
            if (!isNumber) {
                setError("filing_fee", {
                    type: "manual",
                    message: "Value must be a number",
                });
                setValue("filing_fee_refund", 0);
            } else {
                clearErrors("filing_fee");
                setValue("filing_fee_refund", filing_fee_refund || 0);
            }
        }

        let total_savings = potential_tax_savings - (potential_tax_savings * (billPerc / 100) ) - rpt_filing_fee - 50;
        setValue("total_savings", total_savings.toFixed(2) || 0);

        //total_savings
    }, [optionOfValue, filing_fee, rpt_filing_fee, setError, clearErrors]);

    return (
        <Container>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <div className="edit-county-wrap">
                                <form
                                    className="userdetails-edit-form acknowledgment_form"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Current Tax Year
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <FormField
                                                name="tax_year"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                placeholder="Current Tax Year"
                                                defaultValue=""
                                                disabled
                                            />
                                        </div>
                                    </div>      
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Final Value
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <FormField
                                                name="final_value"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                placeholder="Final Value"
                                                defaultValue=""
                                                disabled
                                            />
                                        </div>
                                    </div>                                                                  
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Opinion of Value:
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <FormField
                                                name="option_of_value"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                rules={{
                                                    required:
                                                        "This is required field",
                                                }}
                                                placeholder="Opinion of Value"
                                                defaultValue=""
                                            />
                                            {errors?.option_of_value && (
                                                <span className="form__form-group-error">
                                                    {
                                                        errors?.option_of_value
                                                            ?.message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {/*<div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Upfront Filing Fee:
                                        </span>
                                        <div className="form__form-group-field  d-flex flex-column">
                                            <FormField
                                                name="upfront_filing_fee"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                rules={{
                                                    required:
                                                        "This is required field",
                                                }}
                                                placeholder=" Upfront Filing Fee"
                                                defaultValue=""
                                            />
                                            {errors?.upfront_filing_fee && (
                                                <span className="form__form-group-error">
                                                    {
                                                        errors
                                                            ?.upfront_filing_fee
                                                            ?.message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </div>*/}

                                    {/*
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Refunded:
                                        </span>
                                        <div className="form__form-group-field  d-flex flex-column">
                                            <FormField
                                                name="refunded"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                rules={{
                                                    required:
                                                        "This is required field",
                                                }}
                                                placeholder=" Refunded"
                                                defaultValue=""
                                            />
                                            {errors?.refunded && (
                                                <span className="form__form-group-error">
                                                    {errors?.refunded?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    */}
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Potential Amount of Reduction:
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <FormField
                                                name="potencial_deduction"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                placeholder="Potential Amount of Reduction"
                                                defaultValue=""
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Tax Rate
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <FormField
                                                name="tax_rate"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                placeholder="Tax Rate"
                                                defaultValue=""
                                                disabled
                                            />
                                        </div>
                                    </div>                                    
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Potential Tax Savings
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <FormField
                                                name="potential_tax_savings"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                placeholder="Potential Tax Savings"
                                                defaultValue=""
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            State Filing Fee
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <FormField
                                                name="filing_fee"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                placeholder="State Filing Fee"
                                                defaultValue=""
                                            />
                                            {errors?.filing_fee && (
                                                <span className="form__form-group-error">
                                                    {
                                                        errors?.filing_fee
                                                            ?.message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            State Filing Fee Refund
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <FormField
                                                name="filing_fee_refund"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                placeholder="State Filing Fee Refund"
                                                defaultValue=""
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            RPT Upfront Filing Fee
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <FormField
                                                name="rpt_filing_fee"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                placeholder="RPT Upfront Filing Fee"
                                                defaultValue=""
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Billing Percentage
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <FormField
                                                name="billing_percentage"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                placeholder="Billing Percentage"
                                                defaultValue=""
                                                disabled
                                            />
                                        </div>
                                    </div>                                    
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Total Savings
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <FormField
                                                name="total_savings"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                placeholder="Total Savings"
                                                defaultValue=""
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Deadline to File
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <DatePicker
                                                selected={startDate}
                                                // yearItemNumber={9}
                                                onChange={(date) =>
                                                    setStartDate(date)
                                                }
                                                className="date-picker"
                                                //maxDate={new Date()}
                                                isClearable={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                        Lead Type
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <div className="form__options_wrapper">
                                                <label>
                                                        <FormField
                                                        name="confirmed"
                                                        component="input"
                                                        type="radio"
                                                        control={control}
                                                        errors={errors}
                                                        defaultValue="n/a"
                                                        value="n/a" 
                                                        checked={(confirmedArb == 'n/a' || !confirmedArb) ? true : false}
                                                        onClick={(e) => {
                                                            setConfirmedArb('n/a');
                                                            e.target.value = "n/a";
                                                          }}
                                                        />
                                                        Lead
                                                    </label>
                                                    <label>
                                                        <FormField
                                                        name="confirmed"
                                                        component="input"
                                                        type="radio"
                                                        control={control}
                                                        errors={errors}
                                                        defaultValue="conflead"
                                                        value="conflead" 
                                                        checked={(confirmedArb == 'conflead') ? true : false}
                                                        onClick={(e) => {
                                                            setConfirmedArb('conflead');
                                                            e.target.value = "conflead";
                                                          }}
                                                        />
                                                        Confirmed
                                                    </label>
                                                    <label style={{ marginLeft: 20 }} >
                                                        <FormField
                                                        name="confirmed"
                                                        component="input"
                                                        type="radio"
                                                        control={control}
                                                        errors={errors}
                                                        defaultValue="denlead"
                                                        value="denlead" 
                                                        checked={(confirmedArb == 'denlead') ? true : false}
                                                        onClick={(e) => {
                                                            setConfirmedArb('denlead');
                                                            e.target.value = "denlead";
                                                          }}
                                                        />
                                                        Denied
                                                    </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/*
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                        Denied Arbitration / Litigation
                                        </span>
                                        <div className="form__form-group-field d-flex flex-column">
                                            <div className="form__options_wrapper"  >
                                                <label>
                                                        <FormField
                                                        name="denied"
                                                        component="input"
                                                        type="radio"
                                                        control={control}
                                                        errors={errors}
                                                        defaultValue="n/a"
                                                        value="n/a" 
                                                        checked={(deniedArb == 'n/a' || !deniedArb) ? true : false} 
                                                        onClick={(e) => {
                                                            setDeniedArb('n/a');
                                                            e.target.value = "n/a";
                                                          }}
                                                        />
                                                        N/A
                                                    </label>
                                                    <label>
                                                        <FormField
                                                        name="denied"
                                                        component="input"
                                                        type="radio"
                                                        control={control}
                                                        errors={errors}
                                                        defaultValue="arbitration"
                                                        value="arbitration" 
                                                        checked={(deniedArb == 'arbitration') ? true : false}
                                                        onClick={(e) => {
                                                            setDeniedArb('arbitration');
                                                            e.target.value = "arbitration";
                                                          }}
                                                        />
                                                        Arbitration
                                                    </label>
                                                    <label style={{ marginLeft: 20 }} >
                                                        <FormField
                                                        name="denied"
                                                        component="input"
                                                        type="radio"
                                                        control={control}
                                                        errors={errors}
                                                        defaultValue="litigation"
                                                        value="litigation" 
                                                        checked={(deniedArb == 'litigation') ? true : false} 
                                                        onClick={(e) => {
                                                            setDeniedArb('litigation');
                                                            e.target.value = "litigation";
                                                          }}
                                                        />
                                                        Litigation
                                                    </label>
                                            </div>
                                        </div>
                                    </div>
                                    */}
                                    <div className="text-center">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            onClick={()=> setType('email')}
                                            disabled={allowSubmit}
                                        >
                                            {allowSubmit ? (
                                                <Spinner
                                                    size="sm"
                                                    color="secondary"
                                                    className="mr-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            Send Email
                                        </Button>                                          
                                        <Button
                                            type="submit"
                                            color="secondary"
                                            disabled={allowSubmit}
                                            onClick={()=> setType('save')}
                                        >
                                            {allowSubmit ? (
                                                <Spinner
                                                    size="sm"
                                                    color="secondary"
                                                    className="mr-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            Save
                                        </Button>
                                      
                                    </div>

                                    <div className="text-left">

                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
}
