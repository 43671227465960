/* eslint-disable */

const PlusIcon = ({ color="#FFA647", custom}) => {
    return (
    <svg className={`${custom && custom} custom`} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 1V13" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 7H13" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}

export default PlusIcon;