/* eslint-disable */
import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.75 6.925V7.5a6.25 6.25 0 11-3.706-5.712"
        stroke="#00C48C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 2.5L7.5 8.756 5.625 6.881"
        stroke="#00C48C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
