/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Col, Row } from 'reactstrap';
import AccountMultipleIcon from '@/images/userMultipleIcon'
import ArrowUpRightIcon from 'mdi-react/ArrowUpRightIcon'
import { Details } from '@material-ui/icons';
import { USERLEVELS } from '@/utils/constant'
import Moment from 'moment';


const ActiveClientDetails = ({activeClient, activeClientYear}) => {
    const [isMobile, setIsMobile] = useState(false)
    const userData = JSON.parse(localStorage.getItem('userData'));
    const history = useHistory();
    let year = new Date().getFullYear();
    const client_details = (type) => {
        history.push({
            pathname: `/dashboard-details/${type}`,
        });
    }

    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
      }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })


    return (
    <>
        <Row>
            <Col md={12} className="mb-4" >
                <div className="dashboard-card-title">
                    <p><AccountMultipleIcon color="#009ADC" custom="controlled" /> { userData.userlevel === USERLEVELS.ADMIN ? 'Clients' : 'Client' }</p>
                </div>
            </Col>
            { userData.userlevel === USERLEVELS.ADMIN && <> 
            <Col md={6} onClick={()=> client_details('active-clients')} className="cursor-pointer" title="View Active Clients">
                <div className="mobile-app-widget">
                    <div className="mobile-app-widget__title">
                        <h5>Active Clients</h5>
                    </div>
                    <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                        <p className="mobile-app-widget__total-stat normal">{activeClient || 0}</p>
                    </div>
                </div> 
            </Col>
            <Col md={6}  onClick={()=> client_details('new-clients')} className="cursor-pointer" title={`View New Client Accounts in ${year}`} >
                <div className="mobile-app-widget">
                    <div className="mobile-app-widget__title">
                        <h5>New Client Accounts in {year}</h5>
                    </div>
                    <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                        <p className="mobile-app-widget__total-stat">{activeClientYear || 0} <ArrowUpRightIcon /></p>
                    </div>
                </div>
            </Col>
            </>}
            { userData.userlevel !== USERLEVELS.ADMIN && <> 
            <Col md={6}>
                <div className="mobile-app-widget">
                    <div className="mobile-app-widget__title">
                        <h5>Account created</h5>
                    </div>
                    <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise" style={{ minWidth: isMobile ? "":400 }}>
                        <p className="mobile-app-widget__total-stat normal">{Moment( userData.created_at ).format('MMMM DD, YYYY')}</p>
                    </div>
                </div>
            </Col>
            </>}
        </Row>
    </>
    );
}

export default ActiveClientDetails;