/* eslint-disable */
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Progress } from "reactstrap";
import PaperIcon from "@/images/paperIcon";
import TrashIcon from "@/images/trashIcon";
import CardDocument from "./CardDocument";

const FileLoading = ({ isOpen, files, state, is_view, property_id, id, onDelete }) => {
    return (
        <div>
            <Modal backdrop={false} size="lg" isOpen={isOpen}>
                <ModalBody>
                    <h5>Uploading {files?.length} Files...</h5>
                    {Array.isArray(files) &&
                        files.map((item, i) => {
                            return (
                                <CardDocument
                                    key={i}
                                    progress={state?.[i]}
                                    name={item?.name}
                                    type={item?.type}
                                    is_view={is_view}
                                    property_id={property_id}
                                    id={id}
                                    onDelete={onDelete}
                                    owner_name={item?.owner_name}
                                />
                            );
                        })}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default FileLoading;
