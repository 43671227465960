/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { UserProps, AuthOProps } from '@/shared/prop-types/ReducerProps';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { USERLEVELS } from "@/utils/constant";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = ({  user }) => { 
  const [cookies,removeCookie] = useCookies(['name']);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const history = useHistory();  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const userData = JSON.parse(localStorage.getItem('userData'));
  let isAuthenticated = true
  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    setFirstName(localStorage.getItem('first_name'));
    setLastName(localStorage.getItem('last_name'));
  }, [cookies]);

  const logout = (e) => { 
    removeCookie('userDetails')
    removeCookie('token')
    removeCookie('userID')
    window.location= '/login'
  };

  return (
    <div className="topbar__profile">
      <div className="topbar__avatar">
        <p className="topbar__avatar-name">
          {userData.userlevel === USERLEVELS.ADMIN ? 'Admin: ' : 'Client: '} <span>{firstName}</span>
        </p>
      </div>
    </div>
  );
};

TopbarProfile.propTypes = {
  user: UserProps,
};

TopbarProfile.defaultProps = {
  user: {},
};

export default TopbarProfile;
