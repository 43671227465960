/* eslint-disable */
import React, { useState } from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import { Link } from "react-router-dom"
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import PropertiesTable from '@/components/PropertiesTable'
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import { USERLEVELS } from "@/utils/constant";

const Properties = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [countAll,setCountAll] = useState(0)
    const [countAll2,setCountAll2] = useState(0)
    const [isReloaded,setIsReload] = useState(false)
    const isReload = () => {
        setIsReload(!isReloaded)
    }


    return (
        <Container>
            <Row>
                <Col md={12}>
                    <TitleBreadCrumbs title="Properties "  link={`/property/create/${userData.id}/${userData.client_first_name} ${userData.client_last_name}`}  btn={userData.userlevel ===  USERLEVELS.CLIENT ? `Create Property`  :''} linkSearch={userData.userlevel !==  USERLEVELS.CLIENT ? "properties-search"  :''}  linkMultiple="/multi-upload-documents/properties"></TitleBreadCrumbs>
                    { userData.userlevel === USERLEVELS.ADMIN && 
                    <Card className="card-controlled no-view-all">
                        <CardBody>
                           <PropertiesTable title="New Properties"  status="A" is_new={true} isReload={()=> isReload()} />
                        </CardBody>
                    </Card>   
                    }                 
                </Col>
                <Col md={12}>
                    <Card className="card-controlled">
                        <CardBody>
                           <Tabs defaultActiveKey="active" className="mb-3 datatable-tab-container">
                              <Tab eventKey="active" title={<>Active {countAll !== 0 && <span className={`tab-counter tab-length-${countAll?.toString().length}`}>{`${countAll}`}</span> }</>}>
                                  <PropertiesTable  title="Active Properties" status="A" is_new={false} isTotal={(value)=> setCountAll(value)} isReloaded={isReloaded} />
                              </Tab>
                              <Tab eventKey="inactive" title={<>Inactive {countAll2 !== 0 && <span className={`tab-counter tab-length-${countAll2?.toString().length}`}>{`${countAll2}`}</span> }</>}>
                                 <PropertiesTable  title="Inactive Properties" status="I" is_new={false} isTotal={(value)=> setCountAll2(value)}  isReloaded={isReloaded} />
                              </Tab>
                            </Tabs>
                        </CardBody>
                    </Card> 
                </Col>
            </Row>
        </Container>
    )
}
export default Properties;