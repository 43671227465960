/* eslint-disable */
import toast from 'react-hot-toast';
import { MESSAGES } from './constant'
import CheckboxMarkedCircleOutline from "mdi-react/CheckboxMarkedCircleOutlineIcon";
import CloseCircle from "mdi-react/CloseCircleIcon";

export const globalError = () => {
    toast("Something went wrong.", {
      style: {
        background: '#D8000C',
        borderRadius: 5,
        textAlign: 'left',
        color: '#fff'
        
      },
      icon: <CloseCircle size="15" />
    });
};

export const formError = (props) => {
  toast("Unable to submit form. Please check error. bellow.", {
    style: {
      background: '#D8000C',
      borderRadius: 5,
      textAlign: 'left',
      color: '#fff'
      
    },
    icon: <CloseCircle size="15" />
  });
};

export const successMsg = (message) => {
    toast(MESSAGES[message], {
        style: {
          background: '#00C48C',
          borderRadius: 5,
          textAlign: 'left',
          color: '#fff'
        },
        icon: <CheckboxMarkedCircleOutline size="15" />
      });
};


export const errorMsg = (message) => {
    toast(MESSAGES[message], {
        style: {
          background: '#D8000C',
          borderRadius: 5,
          textAlign: 'left',
          color: '#fff'
        },
        icon: <CloseCircle size="15" />
      });
};

export const errorMsgCustom = (message) => {
    toast(message, {
        style: {
          background: '#D8000C',
          borderRadius: 5,
          textAlign: 'left',
          color: '#fff'
          
        },
        icon: <CloseCircle size="15" />
      });
};

export const successMsgCustom = (message) => {
    toast(message, {
        style: {
          background: '#00C48C',
          borderRadius: 5,
          textAlign: 'left',
          color: '#fff'
        },
        icon: <CheckboxMarkedCircleOutline size="15" />
      });
};

export const toastLoading = (message) => {
  toast.loading('Waiting for the action to complete...');
};

export const toastLoadingUpload = (message) => {
  toast.loading('Waiting for the action to complete...');
};