/* eslint-disable */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Card, CardBody, Col, Container, Row, Tooltip, Button, Collapse } from 'reactstrap';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import ApiServices from "@/utils/ApiServices";
import PaperIcon from '@/images/paperIcon'
import CameraIcon from '@/images/paperIcon'
import { globalError } from "@/utils/message_herlper"
import InfoIcon from '@/images/infoIcon'
import { USERLEVELS  } from '@/utils/constant'
import PropertyDescriptionDocs from "@/components/propertyDescriptionDoc";


const PropertDescription = ({id}) => { 
    const [details, setDetails] = useState([])
    const userData = JSON.parse( localStorage.getItem('userData') )
    const [purchasedTwoYearsDoc, setPurchasedTwoYearsDoc] = useState([]);
    const [hasDmgDocs, setHasDmgDocs] = useState([]);
    const [remodeledDocs, setRemodeledDocs] = useState([]);

    const propertyDetails = () => { 
        ApiServices.propertyDetails(id).then((result) => {   
           setDetails(result?.data);
        })
        .catch((error) => { 
            globalError();
        })
    }

    useEffect(() => {
        propertyDetails()
        handleQuestionedDocs(1)
        handleQuestionedDocs(2)
        handleQuestionedDocs(3)
    }, [id])

    const handleQuestionedDocs = (type) => {
        ApiServices.propertyDescriptionFiles({id: id, type: type}).then((result) => { 
           if(type == 1){
            setPurchasedTwoYearsDoc(result.data)
           }
           if(type == 2){
            setHasDmgDocs(result.data)
           }
           if(type == 3){
            setRemodeledDocs(result.data)
           }
        })
        .catch((error) => {
            globalError();
        })
    }

    const ConvertBlobToFile = (blob, filename) => {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    const agentFile = (files) => { 
        var a = document.createElement("a"); //Create <a>
        a.href = "data:image/png;base64," + files.file; //Image Base64 Goes here
        a.download = files.name; //File name Here
        a.click();
    }
    
    return (
        <div> 
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                    background: '#363636',
                    color: '#fff',
                    },
                    // Default options for specific types
                    success: {
                    duration: 3000,
                    theme: {
                        primary: 'green',
                        secondary: 'black',
                    },
                    },
                }}
            />  
            <div className=" mb-4">
            <div className="prop-description-list-item-wrapper">
                            <div className="prop-description-list-item">
                                <div className="prop-description-list-question">
                                    <span>1</span>
                                    <p>Have you purchased this property within the last two years?</p>
                                </div>
                                {/* Dynamic data */}
                              
                                <p>{ details?.purchased_within_two_years  == 1 ? 'Yes' : 'No' } <i style={{ color: '#898989' }}>{ details?.purchased_within_two_years_date ? '(' + moment(details?.purchased_within_two_years_date).format('MMM DD, YYYY hh:mm A') + ')' : '' }</i></p>
                                {details?.purchased_within_two_years == 1 && 
                                <a> View the closing disclosure</a>
                                }
                                {purchasedTwoYearsDoc.map((obj, i) => {
                                    return (
                                        <>
                                            <React.Fragment key={i}>
                                                {" "}
                                                <PropertyDescriptionDocs
                                                    index={i}
                                                    docuType="1"
                                                    id={i}
                                                    status={obj?.status}
                                                    name={obj?.name}
                                                    file_type={obj?.type}
                                                    onDelete={() =>
                                                        deleteDocument(i, 2)
                                                    }
                                                    onStatus={(data) =>
                                                        onStatus(data)
                                                    }
                                                    hideDelete={true}
                                                    is_view={true}
                                                    agentFile={(file) =>agentFile(file)}
                                                    obj={obj}
                                                />
                                            </React.Fragment>
                                        </>
                                    );
                                })}
                            </div>
                            <div className="prop-description-list-item">
                                <div className="prop-description-list-question">
                                    <span>2</span>
                                    <p>Does the property have any major damage? </p>
                                </div>
                                {/* Dynamic data */}
                                <p>{ details?.has_major_dmg == 1 ? 'Yes' : 'No' }  <i style={{ color: '#898989' }}>{ details?.has_major_dmg_date ? '(' + moment(details?.has_major_dmg_date).format('MMM DD, YYYY hh:mm A') + ')' : '' }</i></p>
                                {details?.has_major_dmg == 1 && <>
                                <p>
                                    <span>Description</span>
                                    <span>{details?.has_major_dmg_description}</span>
                                </p>
                                <a>Photo or Invoices for repairs </a>
                                </> }
                                {hasDmgDocs.map((obj, i) => {
                                    return (
                                        <>
                                            <React.Fragment key={i}>
                                                {" "}
                                                <PropertyDescriptionDocs
                                                    index={i}
                                                    docuType="1"
                                                    id={i}
                                                    status={obj?.status}
                                                    name={obj?.name}
                                                    file_type={obj?.type}
                                                    onDelete={() =>
                                                        deleteDocument(i, 2)
                                                    }
                                                    onStatus={(data) =>
                                                        onStatus(data)
                                                    }
                                                    hideDelete={true}
                                                    is_view={true}
                                                    agentFile={(file) =>agentFile(file)}
                                                    obj={obj}
                                                />
                                            </React.Fragment>
                                        </>
                                    );
                                })}
                            </div>
                            <div className="prop-description-list-item">
                                <div className="prop-description-list-question">
                                    <span>3</span>
                                    <p>Have you done any remodels or upgrades to the property within the last three years?</p>
                                </div>
                                {/* Dynamic data */}
                                <p>{ details?.remodels_within_three_years == 1 ? 'Yes' : 'No' } <i style={{ color: '#898989' }}>{ details?.remodels_within_three_years_date ? '(' + moment(details?.remodels_within_three_years_date).format('MMM DD, YYYY hh:mm A') + ')' : '' }</i></p>
                                {details?.remodels_within_three_years == 1 && <>
                                <p>
                                    <span>Description of the remodel or upgrades:</span>
                                    <span>
                                    {details?.remodels_within_three_years_description}
                                    </span>
                                </p>
                                <a> Closing disclosure</a>
                                </>}
                                {remodeledDocs.map((obj, i) => {
                                    return (
                                        <>
                                            <React.Fragment key={i}>
                                                {" "}
                                                <PropertyDescriptionDocs
                                                    index={i}
                                                    docuType="1"
                                                    id={i}
                                                    status={obj?.status}
                                                    name={obj?.name}
                                                    file_type={obj?.type}
                                                    onDelete={() =>
                                                        deleteDocument(i, 2)
                                                    }
                                                    onStatus={(data) =>
                                                        onStatus(data)
                                                    }
                                                    hideDelete={true}
                                                    is_view={true}
                                                    agentFile={(file) =>agentFile(file)}
                                                    obj={obj}
                                                />
                                            </React.Fragment>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
            </div>
        </div>
    )
}

export default PropertDescription;