/* eslint-disable */
import React, { useEffect, useMemo, useState, useRef } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Spinner,
} from "reactstrap";
import moment from "moment"
import DatePicker from "react-datepicker";
import { Link,  useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import TableLoading from "@/components/TableLoading";
import ApiServices from "@/utils/ApiServices"
import { TABLE_SETTINGS, API } from "@/utils/constant";
import { globalError } from "@/utils/message_herlper";
import { numberWithCommas } from "@/utils/helpers";
import Empty from "@/components/Empty";
import "react-datepicker/dist/react-datepicker.css";

const YearlyAccounting = (props) => {
    const history = useHistory()
    const location = useLocation()
    const [isSubmit, setSubmit] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [year, setYear] = useState(null);
    const yearRef = useRef(null);
    const [startDate, setStartDate] = useState(new Date());
    const dataTable = [];
    const list = data;

    useEffect(() => {  
        if(props?.match?.params?.year){
            setYear(props?.match?.params?.year)
            yearRef.current = parseInt(props?.match?.params?.year);
            fetchData(1);
        }else{
            setYear(null)
            yearRef.current = null
        }
    }, [location]);

  
    const fetchData = async (
        page,
        size = perPage,
        year = yearRef.current
    ) => {
        setData([]);
        ApiServices.yearlyAccountingRerport({
            page: page,
            per_page: size,
            year: year,
        })
            .then((result) => {  
                setData(result.data);
                setLoading(false);
            })
            .catch((error) => {
                globalError();
            });
    };

    const handlView  =  data  => {
        history.push({ pathname: `/reports/not-invoiced-properties/${data?.county_id}/${year}/${data?.name}`})
    }

    const onSubmit = (event) => {
        event.preventDefault()
        let year = parseInt(moment(startDate).format('YYYY'));
        history.push({ pathname: `/reports/yearly-accounting-totals/${year}`})
    };

    const handlePageChange = (page) => {
        fetchListing(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchListing(page, newPerPage);
        setPerPage(newPerPage);
    };


    const columns = useMemo(() => [
        {
            name: "County Name ",
            selector: "county_name",
            sortable: false,
            center: true,
        },
        {
            name: "Invoiced Amount",
            selector: "total_invoice",
            sortable: false,
            center: true,
        },
    ]);
    
    let total_invoice = 0
    let total_reductions = 0
    let total_savings = 0
    if(Array.isArray(list)){
        for (let index = 0; index < list.length; index++) {
            total_invoice+=list[index].total_invoice
            total_reductions+=list[index].total_reduction
            total_savings+=list[index].total_savings
            dataTable.push({
                county_id: list[index].county,
                year: list[index].year,
                county_name: list[index].county_name,
                // total_reduction: list[index].total_reduction?.toFixed(2),
                // total_savings: list[index].total_savings?.toFixed(2),
                total_invoice: `$${numberWithCommas(list[index].total_invoice)}`,
            });
        }
        dataTable.push({
            county_name: <b className="table-bold">{year} Total Invoice Amounts:</b>,
            total_invoice: <b className="table-bold">{`$${numberWithCommas(total_invoice)}`}</b>
        })
        dataTable.push({
            county_name: <b className="table-bold">{year} Total Amount of Reductions:</b>,
            total_invoice: <b className="table-bold">{`$${numberWithCommas(total_reductions)}`}</b>
        })
        dataTable.push({
            county_name: <b className="table-bold">{year} Total Savings:</b>,
            total_invoice: <b className="table-bold">{`$${numberWithCommas(total_savings)}`}</b>
        })
    }
    
    const exportReport = (type) => {
        window.open(`${API}report/yearly-accounting-export/${yearRef.current}/${type}`, '_blank');
        // setSubmit(true);
        // ApiServices.yearlyAccountingExport({ year: yearRef.current })
        //     .then((result) => { 
        //         setTimeout(() => {  
        //             setSubmit(false);
        //             const url = window.URL.createObjectURL(
        //                 new Blob([result.data])
        //             );
        //             const link = document.createElement("a");
        //             link.href = url;
        //             const fileName = `Yearly Accounting for Year ${yearRef.current}.csv`;
        //             link.setAttribute("download", fileName);
        //             document.body.appendChild(link);
        //             link.click();
        //             link.remove();
        //         }, 1000);
        //     })
        //     .catch((error) => {
        //         setSubmit(false);
        //         globalError();
        //     });
    };

    const goBack = () => {
        history.push({ pathname: `/reports/yearly-accounting-totals`})
    };
   



    return (
        <Container>
            <TitleBreadCrumbs title="Yearly Accounting Totals">
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="#">Reports</Link>
                    {!year && (
                        <>
                             <span className="current_breadcrumbs"> Yearly Accounting Totals</span>
                        </>
                    )}
                    
                    {year  && (
                        <>
                            <Link onClick={() => goBack()} to="#">
                                Yearly Accounting Totals
                            </Link>
                            <span className="current_breadcrumbs">Result</span>
                        </>
                    )}
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} className="edit-county-wrap">
                                    {!year ? (
                                        <>
                                            <form
                                                className="client-reg-form"
                                                onSubmit={onSubmit}
                                            >
                                                <div className="form__form-group zipcode_wrapper">
        
                                                    <div className="form__form-group-label mt-2">
                                                        Select Tax Year:
                                                    </div>
                                                    <div className="report-fields">
                                                       {/* <YearPicker onChange={handleChange}     /> */}
                                                       <DatePicker
                                                            selected={startDate}
                                                            showYearPicker
                                                            dateFormat="yyyy"
                                                            // yearItemNumber={9}
                                                            onChange={(date) => setStartDate(date)}
                                                            className="date-picker"
                                                            maxDate={new Date()}
                                                        />
                                                    </div>
                                                    <div className="edit-county-btns">
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            onClick={onSubmit}
                                                        >
                                                            Run Report
                                                        </Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    ) : (
                                        <>
                                            <div className="text-center mb-4">
                                               <h3>Yearly Accounting Totals For {yearRef.current}</h3>
                                            </div>
                                            <Row>
                                                <Col xs="3" sm="3" lg="3"/>

                                                <Col xs="6" sm="6" lg="6">

                                                    <table style={{'width': '100%'}}>
                                                    <thead>
                                                        <tr>
                                                            <td>County Name</td>
                                                            <td>Invoiced Amount</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        dataTable.map((i,k) => 
                                                            <tr>
                                                                <td>{i.county_name}</td>
                                                                <td>{i.total_invoice}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                    </table>   

                                                    <div style={{'display':'none'}}>
                                                    <DataTable
                                                        columns={columns}
                                                        data={dataTable}
                                                        progressPending={loading}
                                                        progressComponent={
                                                            <TableLoading />
                                                        }
                                                        paginationTotalRows={totalRows}
                                                        paginationDefaultPage={
                                                            currentPage
                                                        }
                                                        striped={TABLE_SETTINGS.STRIPED}
                                                        highlightOnHover={true}
                                                        onChangeRowsPerPage={
                                                            handlePerRowsChange
                                                        }
                                                        onChangePage={handlePageChange}
                                                        onSelectedRowsChange={({
                                                            selectedRows,
                                                        }) => console.log(selectedRows)}
                                                        paginationPerPage={data.length}
                                                        noDataComponent={<Empty />}
                                                    />
                                                    </div>
                                                </Col>

                                                <Col xs="3" sm="3" lg="3"/>
                                            </Row>
                                            {
                                                Array.isArray(data) && data.length > 0 ?
                                                    <div className="text-center mt-5">
                                                        <Button
                                                            onClick={() =>
                                                                exportReport('excel')
                                                            }
                                                            disabled={isSubmit}
                                                        >
                                                            {isSubmit ? (
                                                                <Spinner
                                                                    size="sm"
                                                                    color="secondary"
                                                                    className="mr-2"
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                            Export to Excel
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                exportReport('pdf')
                                                            }
                                                            disabled={isSubmit}
                                                        >
                                                            {isSubmit ? (
                                                                <Spinner
                                                                    size="sm"
                                                                    color="link"
                                                                    className="btn-success"
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                            Export to PDF
                                                        </Button>
                                                    </div>
                                                :''
                                            }
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default YearlyAccounting;