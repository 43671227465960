/* eslint-disable */
import React, { useState } from "react";
import { useCookies } from 'react-cookie';
import { Field, reduxForm, Form } from "redux-form";
import { connect } from "react-redux";
import EyeIcon from "mdi-react/EyeIcon";
import { NavLink, Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Alert, Button, Spinner } from "reactstrap";
import PinInput from 'react-pin-input';
import Modal from 'react-bootstrap/Modal'
import toast, { Toaster } from "react-hot-toast";
import renderCheckBoxField from "../form/CheckBox";
import ApiServices from "@/utils/ApiServices";
import { COOKIEMAXAGE, USERLEVELS } from "@/utils/constant";
import { successMsg, errorMsgCustom } from "@/utils/message_herlper"
import CloseIcon from '@/images/closeIcon'



const LogInFormAdmin = ({
    handleSubmit,
    typeFieldUser,
    form,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [cookies, setCookie] = useCookies(['name']);
    const history = useHistory();
    const [isSubmit, setSubmit] = useState(false)
    const [isSubmit2, setSubmit2] = useState(false)
    const [openCode, setOpenCode] = useState(false)
    const [datas, setDatas] = useState(null)
    const [code, setCode] = useState('')
    const [state, setState] = useState({
        username: "",
        password: "",
    });
    const [error, setError] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const signInProcess = (e) => {
        e.preventDefault();

        if (state.username === "" || state.password === "") {
            errorMsgCustom("Please input email and password.")
        } else {
            setError(false);
            setSubmit(true)
            let params = {
                email: state.username,
                password: state.password,
            };
            ApiServices.login(params)
                .then((result) => {
                    setTimeout(() =>{
                        let data = result.data;
                        setSubmit(false)
                        if ( data.user.id && data.user.userlevel === USERLEVELS.ADMIN || data.user.userlevel === USERLEVELS.STAFF_ROLE ) {
                            if(data.user.user_2FA) {
                                setOpenCode(true);
                                setDatas(data);
                                //uncomment 2 lines above to enable 2FA then comment lines below
                            } else {
                                localStorage.setItem('userData', JSON.stringify(data.user));
                                setCookie('userDetails', JSON.stringify(data?.user), COOKIEMAXAGE);
                                setCookie('userID', data?.user?.id, COOKIEMAXAGE);
                                setCookie('token', data.access_token, COOKIEMAXAGE);
                                localStorage.setItem("loggedin_id", data.user.id);
                                localStorage.setItem("userlevel", data.user.userlevel);
                                localStorage.setItem(
                                    "loggedin_token",
                                    data.access_token
                                );
                                localStorage.setItem(
                                    "first_name",
                                    data.user.client_first_name
                                );
                                localStorage.setItem(
                                    "last_name",
                                    data.user.client_last_name
                                );
                                successMsg('LOGIN_SUCCESS');
                
                                window.location = '/dashboard'; 
                                setError(false);
    
                            }


                        }else{
                            setSubmit(false)
                            // setError(true);
                            errorMsgCustom("Your credentials are incorrect.")
                    }
                    },1000)
                })
                .catch(function (error) {
                    setSubmit(false)
                    // setError(true);
                    errorMsgCustom("Your credentials are incorrect.")
                });
        }
    };

    const showPasswordToggle = () => {
        setShowPassword(!showPassword);
    };


    const submitCode = () => {
        let data = datas;
        setSubmit2(true)
        let params = {
            email: data?.user?.email,
            code_2FA: code,
        };
        ApiServices.confirmationCheck(params)
        .then((result) => {
            setTimeout(() =>{
                localStorage.setItem('userData', JSON.stringify(data.user));
                setCookie('userDetails', JSON.stringify(data?.user), COOKIEMAXAGE);
                setCookie('userID', data?.user?.id, COOKIEMAXAGE);
                setCookie('token', data.access_token, COOKIEMAXAGE);
                localStorage.setItem("loggedin_id", data.user.id);
                localStorage.setItem(
                    "loggedin_token",
                    data.access_token
                );
                localStorage.setItem(
                    "first_name",
                    data.user.client_first_name
                );
                localStorage.setItem(
                    "last_name",
                    data.user.client_last_name
                );
                successMsg('LOGIN_SUCCESS');

                window.location = '/dashboard'; 
                setError(false);
            },1000)
        })
        .catch(function (error) {
            setSubmit2(false)
            // setError(true);
            errorMsgCustom("Entered code not found.")
        });
    };
    




    return (
        <Form className="form login-form" onSubmit={handleSubmit}>
            <Modal  show={openCode} onHide={() => {  } } centered size="lg">
                <Button onClick={() => setOpenCode(false) } className="modalconfirm-closebtn modal-closebtn"><CloseIcon color="#383938" /></Button>
                <Modal.Body className="text-center">
                    <h3 className="mt-3 mb-3"><b>Confirmation Code!</b></h3>
                    <PinInput 
                        length={6} 
                        initialValue=""
                         onChange={(value, index) => setCode(value)} 
                        type="numeric" 
                        inputMode="number"
                        style={{padding: '10px'}}  
                        inputStyle={{borderColor: '#f2f4f7'}}
                        inputFocusStyle={{borderColor: '#FFA647'}}
                        onComplete={(value, index) => setCode(value)}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        />
                        <div className="pt-4">
                        <Button color="primary" className="mb-0"   disabled={code.length  > 5 ? false : true} onClick={submitCode}>
                        {isSubmit2 ? (
                                <Spinner size="sm" color="secondary" className="mr-2" />
                            ) : (
                                ""
                            )}
                            <span>Submit</span>
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: "",
                    duration: 5000,
                    style: {
                        background: "#363636",
                        color: "#fff",
                    },
                    // Default options for specific types
                    success: {
                        duration: 3000,
                        theme: {
                            primary: "green",
                            secondary: "black",
                        },
                    },
                }}
            />
            {error ? (
                <Alert color="danger" isOpen={true}>
                    Your credentials are incorrect.
                </Alert>
            ) : (
                ""
            )}

            <div className="form__form-group">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                    {/* <div className="form__form-group-icon">
                        <AccountOutlineIcon />
                    </div> */}
                    <Field
                        name="username"
                        component="input"
                        type={typeFieldUser}
                        placeholder="Email"
                        className="input-without-border-radius"
                        value={state.username}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">Password</span>
                <div className="form__form-group-field">
                    {/* <div className="form__form-group-icon">
                        <KeyVariantIcon />
                    </div> */}
                    <Field
                        name="password"
                        component="input"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        className="input-without-border-radius"
                        value={state.password}
                        onChange={handleChange}
                    />
                    <button
                        type="button"
                        className={`form__form-group-button${
                            showPassword ? " active" : ""
                        }`}
                        onClick={showPasswordToggle}
                    >
                        <EyeIcon />
                    </button>
                    <div className="account__forgot-password d-none">
                        <NavLink to="/reset_password">
                            Forgot a password?
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label remember-me-label"></span>
                <div className="form__form-group form__form-group-field">
                    <Field
                        name={`remember_me-${form}`}
                        component={renderCheckBoxField}
                        label="Remember me"
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label"></span>
                <div className="form__form-group form__form-group-field">
                    <div className="account__btns">
                        <Button
                            className="account__btn mb-0 button-with-spinner"
                            type="submit"
                            color="primary"
                            onClick={signInProcess}
                            disabled={isSubmit}
                        >
                            {isSubmit ? (
                                <Spinner size="sm" color="secondary" className="mr-2" />
                            ) : (
                                ""
                            )}
                            <span>Login</span>
                        </Button>
                        <p className="admin-create-account">Don't have an account?<br />Please reach out to the manager.</p>
                    </div>
                </div>
            </div>
        </Form>
    );
};

LogInFormAdmin.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    errorMsg: PropTypes.string,
    fieldUser: PropTypes.string,
    typeFieldUser: PropTypes.string,
    form: PropTypes.string.isRequired,
};

LogInFormAdmin.defaultProps = {
    errorMessage: "",
    errorMsg: "",
    fieldUser: "Username",
    typeFieldUser: "text",
};

export default connect((state) => ({
    errorMsg: state.user.error,
}))(reduxForm()(LogInFormAdmin));
