/* eslint-disable */

const PencilIcon = ({ color="#FFA647", custom }) => {
    return (
        <svg className={`${custom ? custom : ''} custom`} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3335 1.50004C11.5086 1.32494 11.7165 1.18605 11.9452 1.09129C12.174 0.996527 12.4192 0.947754 12.6668 0.947754C12.9145 0.947754 13.1597 0.996527 13.3884 1.09129C13.6172 1.18605 13.8251 1.32494 14.0002 1.50004C14.1753 1.67513 14.3142 1.883 14.4089 2.11178C14.5037 2.34055 14.5524 2.58575 14.5524 2.83337C14.5524 3.08099 14.5037 3.32619 14.4089 3.55497C14.3142 3.78374 14.1753 3.99161 14.0002 4.16671L5.00016 13.1667L1.3335 14.1667L2.3335 10.5L11.3335 1.50004Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default PencilIcon;