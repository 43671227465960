/* eslint-disable */
import React, {useEffect, useState, useRef, useMemo} from 'react';
import { 
    Card, CardBody, Col, Container, Row
  } from 'reactstrap';
import queryString from 'query-string';
import {  reduxForm } from 'redux-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import Select from 'react-select'
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import ApiServices from "@/utils/ApiServices";
import { TABLE_SETTINGS, SELECT_STYLES } from "@/utils/constant";
import DataTable from "react-data-table-component";
import TableLoading from "@/components/TableLoading";
import Empty from "@/components/Empty";
import { globalError } from "@/utils/message_herlper";
import AngleArrowLeft from '@/images/angleArrowLeft';

const AccountSpecificReport = (props) => {
    const history = useHistory();
    const location = useLocation()
    const currentYear = (new Date()).getFullYear()
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step))
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [show, setShow] = useState(false);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [years, setYears] = useState(range(currentYear, currentYear - 8, -1)?.reverse())
    const [resultList, setResultList] = useState([])
    const [agentList, setAgentList] = useState([])
    const [name, setName] = useState([])
    const [propertyType, sePropertyType] = useState([]);
    const [yearSeledted, setYearSeledted] = useState([])
    const [porpertySeledted, setPropertySeledted] = useState([])
    const [billingSeledted, setBillingSeledted] = useState([])
    const [resultSeledted, setRsultSeledted] = useState([])
    const yearRef = useRef(null)
    const propertyRef = useRef(null)
    const billingRef = useRef(null)
    const resultRef = useRef(null)
    const  keyRef = useRef(null)
    const dataTable = [];
   


    useEffect(() => {  
        let { key, name } =  props?.match?.params
        keyRef.current = key
        setName(name)
        fetchData(1);
    }, [location]);

    const fetchData = async (
        page, 
        size = perPage,
        id = keyRef.current,
    ) => {
        setData([]);
        ApiServices.accountSpecificReport({
            page: page,
            per_page: size,
            id: parseInt(id)
        }).then((result) => {  
            setData(result.data);
            setLoading(false);
        })
        .catch((error) => {
            globalError();
        });
    };

    const handleInputChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name] : e.target.value.trim()
        })
    }


    const handlView  =  data  => {    
        let params = btoa(`?year=${data.year}&id=${keyRef.current}&name=${name}`)
        history.push({ pathname: `/account-specific-report/details/${params}` })
    }

    const handlViewYear  =  data  => {   
        let params = btoa(`?year=${data.year}&id=${keyRef.current}&name=${name}`)
        history.push({ pathname: `/account-specific-report/billing-details/${params}` })
    }

    

    const goBack = () => {
        setYearSeledted([])
        setPropertySeledted([])
        setBillingSeledted([])
        setRsultSeledted([])
        history.push({ pathname: `/reports/account-specific-report`})
    };

    const handlePageChange = (page) => {
        fetchData(page);
    };



    const columns = useMemo(() => [
        {
            name: "Entity Name ",
            selector: "entity_name",
            sortable: false,
            center: true,
            cell: (row) => (
                <div>{row.entity_name}</div>
            ),
        },
        {
            name: "Property Type	",
            selector: "property_type",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.property_type}</div>
            ),
        },
        {
            name: "Account No.",
            selector: "account_number",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.account_number}</div>
            ),
        },
        {
            name: "Address",
            selector: "address",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.address}</div>
            ),
        },
        {
            name: "City, State Zip",
            selector: "city_state_zip",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.city_state_zip}</div>
            ),
        },
        {
            name: "County",
            selector: "county",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.county}</div>
            ),
        },
        {
            name: "Notice Value",
            selector: "notice_value",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.notice_value}</div>
            ),
        },
        {
            name: "Settlement Value",
            selector: "settlement_value",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.settlement_value}</div>
            ),
        },
        {
            name: "Value Reduction",
            selector: "value_reduction",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.value_reduction}</div>
            ),
        },
        {
            name: "Tax Rate",
            selector: "tax_rate",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.tax_rate}</div>
            ),
        },
        {
            name: "Tax Savings",
            selector: "tax_savings",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.tax_savings}</div>
            ),
        },
        {
            name: "Invoice Amt (30%)",
            selector: "invoice_amount",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.invoice_amount}</div>
            ),
        },
        {
            name: "Date Entered",
            selector: "date_entered",
            sortable: false,
            right: true,
            cell: (row) => (
                <div>{row.date_entered}</div>
            ),
        },
        /*{
            name: "Details",
            center: true,
            cell: (row) => (
                <Button  className="my-0 primary-2 white-nowrap" color="primary"  onClick={()=> handlView(row)}>View Properties</Button>
            ),
        },*/
    ]);
    const list = data;

    if(Array.isArray(list)){  console.table(list)
        for (let index = 0; index < list.length; index++) {
            dataTable.push({
                year: list[index].year,
              //year_data: <a href="javascript:void(0)" onClick={()=> handlView(list[index])} >{list[index].year}</a>,
              entity_name: `${list[index].owner_name}`,
              property_type: `${list[index].type}`,
              account_number: `${list[index].account_number || ''}`,
              address: `${list[index].property_address}`,
              city_state_zip: `${list[index].property_city + ', ' + list[index].property_state + ' ' + list[index].property_zip}`,
              county: `${list[index].name}`,
              notice_value: `$${list[index].noticedValue?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
              settlement_value: `$${list[index].settledValue?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
              value_reduction: `$${list[index].reduction?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
              tax_rate: `${(list[index].taxrate * 100).toFixed(2)}`,
              tax_savings: `${list[index].taxsavings?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
              invoice_amount: `${list[index].invoiceamount?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
              date_entered: `${list[index].created_at}`

            //    total_reduction: `$${list[index].total_reduction?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            //     total_savings: `$${list[index].total_savings?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            //    total_invoice: `$${list[index].total_invoice?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            });
        }
    }

    
    return (
        <Container>
            <TitleBreadCrumbs title='Account Specific Report' >
                <div className="page-breadcrumbs">
                <Link to="/dashboard">Dashboard</Link>
                <Link to="#">Reports</Link>
                <Link to="#">Account Specific Report</Link>
                <span className="current_breadcrumbs">{name} </span>
                </div>
                <div className="mb-4">
                    <Link className="backto-link" to={`/client/details/${keyRef.current}`}><AngleArrowLeft color="#FFA647" />Back to  client details</Link>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody className="filter-invoice-report">
                            <Row>
                                <Col md={12} className="invoiced-report-result">

                                    <table style={{'width': '100%'}}>
                                    <thead>
                                        <tr>
                                            <td>Entity Name</td>
                                            <td>Property Type</td>
                                            <td>Account No.</td>
                                            <td>Address</td>
                                            <td>City, State Zip</td>
                                            <td>County</td>
                                            <td>Notice Value</td>
                                            <td>Settlement Value</td>
                                            <td>Value Reduction</td>
                                            <td>Tax Rate</td>
                                            <td>Tax Savings</td>
                                            <td>Invoice Amt (30%)</td>
                                            <td>Year</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dataTable.map((i,k) => 
                                            <tr>
                                                <td>{i.entity_name}</td>
                                                <td>{i.property_type}</td>
                                                <td>{i.account_number}</td>
                                                <td>{i.address}</td>
                                                <td>{i.city_state_zip}</td>
                                                <td>{i.county}</td>
                                                <td>{i.notice_value}</td>
                                                <td>{i.settlement_value}</td>
                                                <td>{i.value_reduction}</td>
                                                <td>{i.tax_rate}</td>
                                                <td>{i.tax_savings}</td>
                                                <td>{i.invoice_amount}</td>
                                                <td>{i.year}</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                    </table>

                                    <div style={{'display':'none'}}>
                                    <DataTable
                                        columns={columns}
                                        data={dataTable}
                                        progressPending={loading}
                                        progressComponent={
                                            <TableLoading />
                                        }
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={
                                            currentPage
                                        }
                                        striped={TABLE_SETTINGS.STRIPED}
                                        highlightOnHover={true}
                                        paginationPerPage={data.length}
                                        noDataComponent={<Empty />}
                                    />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default reduxForm({
    form: 'filter_specific_report',
})(AccountSpecificReport);