/* eslint-disable */
import React, {useState} from "react";
import { Link, NavLink , useHistory} from "react-router-dom";
import ResetPasswordForm from "@/shared/components/reset_password/ResetPasswordForm";
import _ from "lodash";
import { successMsg, errorMsgCustom, successMsgCustom } from "@/utils/message_herlper"
import { HOME_API } from '@/utils/constant'
import ApiServices from "@/utils/ApiServices"; 


const ResetPassword = (props) => {
    const [isSubmit, setSubmit] = useState(false);
    const history = useHistory();

    const onSubmit = (item) => {
        if(_.isEmpty(item)){
            errorMsgCustom("Please input email.")
            return
        }
        setSubmit(true)
        ApiServices.resetPassword({email: item?.email, url: HOME_API+'password-reset-form/'}).then((result) => {
            successMsgCustom('Password reset email sent. Please check your email.');
            history.push({pathname: `/login`});
        }).catch(function (error) {
            setSubmit(false)
            errorMsgCustom("Email not exist.")
        });
    };

    return (
        <div className="account account--not-photo">
            <div className="account__wrapper">
                <div className="account__card">
                    <Link className="topbar__logo" to="/" height="150px" />
                    <ResetPasswordForm
                        {...props}
                        onSubmit={onSubmit}
                        form="reset_password_form"
                        isSubmit={isSubmit}
                    />
                    <div className="account__have-account">
                        <p>
                            Remember your password?{" "}
                            <NavLink to="/login">Login</NavLink>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
