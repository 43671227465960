/* eslint-disable */
import React, { useEffect, useState } from "react";
import queryString from 'query-string';
import { 
    Card, CardBody, Col, Container, Row
  } from 'reactstrap';
import { Button, Spinner } from 'reactstrap';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { reduxForm } from "redux-form";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import ApiServices from "@/utils/ApiServices";
import { successMsg, globalError, formError } from "../../utils/message_herlper"
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import {  zipPattern } from '@/shared/helpers';

const CountyCreate = (props) => {
    const history = useHistory()
    let search = queryString.parse(props.location.search)  
    const { handleSubmit, reset, control, errors, register, setValue, clearErrors, formState } = useForm();
    const [isSubmit, setSubmit] = useState(false);
    const [type, setType] = useState(false);  
    const [noSubmit, setNoSubmit] = useState(0);  
    const [editData, setEditData] = useState(false);

    useEffect(() => {    
        setNoSubmit(formState?.submitCount || 0)
        if (formState.isSubmitted && Object.keys(formState?.errors).length > 0 && formState?.submitCount != noSubmit ) {
            formError()
            return
        }
    }, [formState])

    useEffect(() => {  
        let {type, id} =  props?.match?.params
        setType(type)
        if(type, id){  
            countryDetails(parseInt(id))
        }
    }, [location]);
    

     const countryDetails = (id) => {
        ApiServices.countyDetails(id).then((result) => {   
            let details = result?.data
            setEditData(details)
            setValue('name', details?.name)
            setValue('district_name', details?.district_name)
            setValue('address', details?.address)
            setValue('city', details?.city)
            setValue('state', details?.state)
            setValue('zipcode', details?.zipcode)
            setValue('agent_code', details?.agent_code)
        })
        .catch((error) => {
            formError();
        })
    }



    const onSubmit  = (form) => {
        setSubmit(true)
        if(type === 'create'){
            ApiServices.createCounty(form)
            .then((result) => { 
                successMsg('COUNTY_SUCCESS') 
                setTimeout(() => {
                    history.push({pathname: `/county-list`});
                },1000)
            })
            .catch((error) => {
                setSubmit(false)
                if (error.response) {
                    console.log(error.response.data.errors)
                    // seErrors(error?.response?.data?.errors)
                    formError(error?.response?.data?.errors)
                return
                }
                globalError()
            });
        }
        if(type === 'update'){
            form.id = editData?.id 
            ApiServices.updateCounty(form)
            .then((result) => {   console.log('result -->', result.data)
                successMsg('COUNTY_UPDATE_SUCCESS') 
                setTimeout(() => {
                    history.push({pathname: `/county-list`});
                },1000)
            })
            .catch((error) => {
                setSubmit(false)
                if (error.response) {
                    console.log(error.response.data.errors)
                    // seErrors(error?.response?.data?.errors)
                    formError(error?.response?.data?.errors)
                return
                }
                globalError()
            });
        }
    }
    
    return (
        <Container>
            <TitleBreadCrumbs title={type === 'create' ? 'Create New County' : 'Edit County'} >
                <div className="page-breadcrumbs">
                    <Link to="/">Home</Link>
                    <Link to="/settings">Settings</Link>
                    <Link to="/county-list">County List</Link>
                    <span className="current_breadcrumbs"> {type === 'create' ? 'Create' : 'Edit'}</span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card className="card-controlled">
                        <CardBody className="cardbody-controlled">
                            <Row>
                                <Col md={12} className="edit-county-wrap">
                                    <form
                                        className="client-reg-form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                County Name
                                            </span>
                                            <div className="form__form-group-field">
                                                <FormField
                                                    name="name"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    rules={{
                                                        required:
                                                            "This is required field",
                                                    }}
                                                    placeholder="County Name"
                                                />
                                            </div>
                                            {errors?.name && (
                                                <span className="form__form-group-error">
                                                    {errors?.name?.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                District Name
                                            </span>
                                            <div className="form__form-group-field">
                                                <FormField
                                                    name="district_name"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    rules={{
                                                        required:
                                                            "This is required field",
                                                    }}
                                                    placeholder="District Name"
                                                />
                                            </div>
                                            {errors?.district_name && (
                                                <span className="form__form-group-error">
                                                    {errors?.district_name?.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Address
                                            </span>
                                            <div className="form__form-group-field">
                                                <FormField
                                                    name="address"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    rules={{
                                                        required:
                                                            "This is required field",
                                                    }}
                                                    placeholder="Address"
                                                />
                                            </div>
                                            {errors?.address && (
                                                <span className="form__form-group-error">
                                                    {errors?.address?.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                City
                                            </span>
                                            <div className="form__form-group-field">
                                                <FormField
                                                    name="city"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="City"
                                                    rules={{
                                                        required:
                                                            "This is required field",
                                                    }}
                                                />
                                            </div>
                                            {errors?.city && (
                                                <span className="form__form-group-error">
                                                    {errors?.city?.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                State
                                            </span>
                                            <div className="form__form-group-field">
                                                <FormField
                                                    name="state"
                                                    component="input"
                                                    control={control}
                                                    placeholder="State"
                                                    rules={{
                                                        required:
                                                            "This is required field",
                                                    }}
                                                />
                                            </div>
                                            {errors?.state && (
                                                <span className="form__form-group-error">
                                                    {errors?.state?.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                               Zip Code
                                            </span>
                                            <div className="form__form-group-field">
                                                <FormField
                                                    name="zipcode"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    rules={{
                                                        pattern: {
                                                            value: zipPattern,
                                                            message:
                                                                "Entered value does not match zip code format",
                                                        },
                                                    }}
                                                    placeholder="Zip Code"
                                                />
                                            </div>
                                            {errors?.zipcode && (
                                                <span className="form__form-group-error">
                                                    {errors?.zipcode?.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                               Agent Code
                                            </span>
                                            <div className="form__form-group-field">
                                                <FormField
                                                    name="agent_code"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="Agent Code"
                                                    rules={{
                                                        required:
                                                            "This is required field",
                                                    }}
                                                />
                                            </div>
                                            {errors?.agent_code && (
                                                <span className="form__form-group-error">
                                                    {errors?.agent_code?.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="edit-county-btns">
                                            <Link className="btn btn-secondary" to="/county-list">Back to List</Link>
                                            <Button
                                            className="float-right"
                                                type="submit"
                                                color="primary"
                                                disabled={isSubmit}
                                            > {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}
                                                {type === 'create' ? 'Submit' : ''}
                                                {type === 'update' ? 'Save Changes' : ''}
                                            </Button>
                                        </div>

                                    </form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default reduxForm({
    form: 'create_county',
})(CountyCreate);