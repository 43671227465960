/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PaperIcon from '@/images/paperIcon'
import TrashIcon from '@/images/trashIcon'

const PropertyDescriptionDocs = ({ index, docuType, id, file_type, name, status, onDelete , onStatus, is_view, agentFile, hideDelete, obj}) => {  
    const userData = JSON.parse(localStorage.getItem('userData'));
    const onChangeStatus = (status) => {
        onStatus({id: id, status: status ? 1 : 0, type: parseInt(docuType) })
    }
    return(
       
        <>
       
           <div className="prop-descriptions-docs" onClick={() => is_view ? agentFile(obj) : () => false }  style={{ cursor: is_view? 'pointer' : 'default'  }} >
                <div>
                    <div className="fileicon">
                        <div><PaperIcon color="#FFA647" /></div>
                    </div>
                    <span className="filename">{name}</span>
                    {
                        !hideDelete?
                            <span className="cursor-pointer docu-delete" onClick={()=> onDelete(id)}>
                                <TrashIcon color="#A8B5BF" />
                            </span>
                        :''
                    }
                   
                </div>
            </div>
        </>
    )
}

export default PropertyDescriptionDocs;