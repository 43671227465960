/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import DataTable from "react-data-table-component";
import ModalConfirm from "@/components/ModalConfirm";
import ApiServices from "@/utils/ApiServices";
import CheckIcon from "@/images/checkIcon";
import { TABLE_SETTINGS } from "@/utils/constant";
import { successMsg, globalError } from "@/utils/message_herlper";
import moment from "moment";
import Empty from "@/components/Empty";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Container, Card, CardBody } from "reactstrap";
import TableLoading from "@/components/TableLoading";
import CustomPagination from "@/components/CustomPagination";

const PropertyTable = ({type}) => {
    let title = "Title";
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const page_ref = useRef(1);
    const id_ref = useRef(0);

    useEffect(() => {
        if(type === 'active-property'){
            fetchActiveProperty(1);
        }else{
            fetchNewProperty(1);
        }
        
    }, [type]);

    const fetchActiveProperty = async (page, size = perPage) => {
        setLoading(true);
        page_ref.current = page;
        ApiServices.dashboardActiveProperty({
            page: page,
            per_page: size,
        })
            .then((result) => {
                setData(result.data.data);
                setTotalRows(result.data.total);
                setRowCount(result.data.data.length);
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
            });
    };

    const fetchNewProperty = async (page, size = perPage) => {
        setLoading(true);
        page_ref.current = page;
        ApiServices.dashboardNewProperty({
            page: page,
            per_page: size,
        })
            .then((result) => {
                setData(result.data.data);
                setTotalRows(result.data.total);
                setRowCount(result.data.data.length);
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
            });
    };

    const columns = useMemo(() => [
        {
            name: "Date added",
            selector: "created_at",
            sortable: false,
            width: "150px",
        },
        {
            name: "Owner's Name",
            selector: "owner_name",
            sortable: false,
            width: "150px",
        },
        {
            name: "Address, City, State Zip Code",
            selector: "property_address",
            sortable: false,
            width: "400px",
        },
        {
            name: "County",
            selector: "county",
            sortable: false,
            width: "150px",
        },
        {
            cell: (row) => (
                <>
                    <Button
                        className="my-1 white-nowrap"
                        onClick={() => handleViewDetails(row.id)}
                        color="secondary"
                    >
                        Details
                    </Button>
                    
                </>
            ),
            right: true,
            width: "auto",
        },
    ]);


    const handleViewDetails = (propID) => {
        history.push({
            pathname: `/property/details/${propID}`,
        });
    };




    const handlePageChange = (page) => {
        if(type === 'active-property'){
            fetchActiveProperty(page);
        }else{
            fetchNewProperty(page);
        }
        setCurrentPage(page);
    };

  

    const handlePerRowsChange = async (newPerPage, page) => {
        if(type === 'active-property'){
            fetchActiveProperty(page, newPerPage);
        }else{
            fetchNewProperty(page, newPerPage);
        }
        setPerPage(newPerPage);
    };





    const dataTable = [];
    const list = data;
    for (let index = 0; index < list.length; index++) { 
        dataTable.push({
            id: list[index].id,
            created_at: moment
                .utc(list[index].created_at)
                .local()
                .format("MMM DD, hh:mm A"),
            owner_name: list[index].owner_name,
            property_address: `${list[index].property_address}, ${
                list[index]?.property_city || ""
            }, ${list[index]?.property_state || ""} ${
                list[index]?.property_zip || ""
            }`,
            county: list[index].country_name
        });
    }

    return (
        <>
            <DataTable
                columns={columns}
                data={dataTable}
                progressPending={loading}
                progressComponent={<TableLoading />}
                pagination={false}
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                striped={TABLE_SETTINGS.STRIPED}
                highlightOnHover={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSelectedRowsChange={({ selectedRows }) =>
                    console.log(selectedRows)
                }
                paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                paginationRowsPerPageOptions={TABLE_SETTINGS.ROW_PER_PAGE}
                noDataComponent={<Empty />}
            />
            <CustomPagination
                rowCount={rowCount}
                currentPage={currentPage}
                totalRows={totalRows}
                perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                handlePageChange={handlePageChange}
            />
        </>
    );
};

export default React.memo(PropertyTable);
