/* eslint-disable */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import NotFound404 from '../../DefaultPage/404/index';
import LockScreen from '../../Account/LockScreen/index';
import LogIn from '../../Account/LogIn/index';
import AdminLogin from '../../Account/LogIn/admin-login';
import Register from '../../Account/Register/index';
import ResetPassword from '../../Account/ResetPassword/index';
import ResetForm from '../../Account/ResetPassword/ResetForm';
import WrappedRoutes from './WrappedRoutes';

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        {/* <Route exact path="/" component={Landing} /> */}
        <Route exact path="/" render={() => <Redirect to="/login"/> } />
        <Route path="/404" component={NotFound404} />
        <Route path="/lock_screen" component={LockScreen} />
        <Route path="/login" component={LogIn} />
        <Route path="/admin-login" component={AdminLogin} />
        {/* <Route path="/log_in_photo" component={LogInPhoto} /> */}
        <Route path="/register" component={Register} />
        {/* <Route path="/register_photo" component={RegisterPhoto} /> */}
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/password-reset-form/:key" component={ResetForm} />
        {/* <Route path="/reset_password_photo" component={ResetPasswordPhoto} /> */}
        <Route path="/" component={WrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
