/* eslint-disable */
import React, { useEffect, useState } from "react";
import queryString from 'query-string';
import { 
    Card, CardBody, Col, Container, Row
  } from 'reactstrap';
import { Button, Spinner } from 'reactstrap';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { reduxForm } from "redux-form";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import ApiServices from "@/utils/ApiServices";
import { successMsg, globalError, formError } from "../../utils/message_herlper"
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import {  zipPattern } from '@/shared/helpers';

const CountyCreate = (props) => {
    const history = useHistory()
    const location = useLocation();
    const { handleSubmit, reset, control, errors, register, setValue, clearErrors, formState } = useForm();
    const [isSubmit, setSubmit] = useState(false);
    const [type, setType] = useState(null);  
    const [noSubmit, setNoSubmit] = useState(0);  
    const [editData, setEditData] = useState(false);

    useEffect(() => {    
        setNoSubmit(formState?.submitCount || 0)
        if (formState.isSubmitted && Object.keys(formState?.errors).length > 0 && formState?.submitCount != noSubmit ) {
            formError()
            return
        }
    }, [formState])

    useEffect(() => {  
        let {type, id} =  props?.match?.params
        setType(type)
        if(type, id){  
           
            propertyTypeDetails(parseInt(id))
        }
    }, [location]);
    

     const propertyTypeDetails = (id) => {
        ApiServices.propertyTypeDetails(id).then((result) => {   
            let details = result?.data
            setEditData(details)
            setValue('type', details?.type)
            setValue('value', details?.value)
        })
        .catch((error) => {
            formError();
        })
    }

    const onSubmit  = (form) => {
        setSubmit(true)
        if(type === 'create'){
            ApiServices.createPropertyType(form)
            .then((result) => {   
                successMsg('PT_SUCCESS') 
                setTimeout(() => {
                    history.push({pathname: `/property-type`});
                },1000)
            })
            .catch((error) => {
                setSubmit(false)
                if (error.response) {
                    console.log(error.response.data.errors)
                    // seErrors(error?.response?.data?.errors)
                    formError(error?.response?.data?.errors)
                return
                }
                globalError()
            });
        }
        if(type === 'update'){
            form.id = editData?.id 
            ApiServices.updatePropertyType(form)
            .then((result) => {  
                successMsg('PT_UPDATE_SUCCESS') 
                setTimeout(() => {
                    history.push({pathname: `/property-type`});
                },1000)
            })
            .catch((error) => {
                setSubmit(false)
                if (error.response) {
                    console.log(error.response.data.errors)
                    // seErrors(error?.response?.data?.errors)
                    formError(error?.response?.data?.errors)
                return
                }
                globalError()
            });
        }
    }

    return (
        <Container>
            {/* <TitleBreadCrumbs title="Create Property Type" >
                <div className="page-breadcrumbs">
                    <Link to="/">Home</Link>
                    <Link to="/county-list">County</Link>
                    <span className="current_breadcrumbs">
                       Create
                    </span>
                </div>
            </TitleBreadCrumbs> */}
            <TitleBreadCrumbs  title={type === 'create' ? 'Create New Property Type' : 'Edit Property Type'}  link={`property-type-form/create`} btn="Create" >
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="/settings">Settings</Link>
                    <Link to="/property-type">Property Type List</Link>
                    <span className="current_breadcrumbs"> {type === 'create' ? 'Create' : 'Edit'}</span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card className="card-controlled">
                        <CardBody className="cardbody-controlled">
                            <Row>
                                <Col md={12} className="edit-county-wrap">
                                    <form
                                        className="client-reg-form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                               Property Type Name
                                            </span>
                                            <div className="form__form-group-field">
                                                <FormField
                                                    name="type"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    rules={{
                                                        required:
                                                            "This is required field",
                                                    }}
                                                    placeholder="Property Type Name"
                                                />
                                            </div>
                                            {errors?.type && (
                                                <span className="form__form-group-error">
                                                    {errors?.type?.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Value - (2 letters only)
                                            </span>
                                            <div className="form__form-group-field">
                                                <FormField
                                                    name="value"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    rules={{
                                                        required:
                                                            "This is required field",
                                                        maxLength: {
                                                            value: 2,
                                                            message: "2 letters only"
                                                        }
                                                    }}
                                                    placeholder=" Value - (2 letters only)"
                                                />
                                            </div>
                                            {errors?.value && (
                                                <span className="form__form-group-error">
                                                    {errors?.value?.message}
                                                </span>
                                            )}
                                        </div>
                                        
                                        <div className="edit-county-btns">
                                            <Link className="btn btn-secondary" to="/property-type">Back to List</Link>
                                            <Button
                                            className="float-right"
                                                type="submit"
                                                color="primary"
                                                disabled={isSubmit}
                                            > {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}
                                                {type === 'create' ? 'Submit' : ''}
                                                {type === 'update' ? 'Save Changes' : ''}
                                            </Button>
                                        </div>

                                    </form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default reduxForm({
    form: 'create_county',
})(CountyCreate);