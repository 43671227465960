/* eslint-disable */
import React, { useState } from 'react';
import { Row, Col, Container, Card, CardBody, Button } from 'reactstrap';
import ClientTable from '@/components/ClientTable'
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

const Clients = () => {
    const [countAll,setCountAll] = useState(0)
    const [countAll2,setCountAll2] = useState(0)
    const [isReloaded,setIsReload] = useState(false)


    const isReload = () => {
        setIsReload(!isReloaded)
    }

    return (
        <Container>
            <Row>
                <Col md={12} >
                    <TitleBreadCrumbs title="Clients"  link="/client-create" btn="Create Client" linkSearch="client-search"  linkMultiple="/multi-upload-documents/clients"></TitleBreadCrumbs>
                    <Card className="card-controlled">
                        <CardBody className="no-view-all">
                           <ClientTable  title="New Clients" status="A" is_new={true}   isReload={()=> isReload()} />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={12}>
                    <Card className="card-controlled">
                        <CardBody>
                           <Tabs defaultActiveKey="active" className="mb-3 datatable-tab-container">
                              <Tab eventKey="active" title={<>Active {countAll !== 0 && <span className={`tab-counter tab-length-${countAll?.toString().length}`}>{`${countAll}`}</span> }</>}>
                                  <ClientTable  title="Active Clients" status="A" is_new={false} isTotal={(value)=> setCountAll(value)} isReloaded={isReloaded}/>
                              </Tab>
                              <Tab eventKey="inactive" title={<>Inactive {countAll2 !== 0 && <span className={`tab-counter tab-length-${countAll2?.toString().length}`}>{`${countAll2}`}</span> }</>}>
                                 <ClientTable  title="Inactive Clients" status="I" is_new={false}  isTotal={(value)=> setCountAll2(value)}  isReloaded={isReloaded} />
                              </Tab>
                            </Tabs>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
export default Clients;