/* eslint-disable */
import React, { useEffect, useState } from "react";
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import { 
    Card, CardBody, Col, Container, Row, Spinner
  } from 'reactstrap';
import { Button } from 'reactstrap';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { reduxForm } from "redux-form";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import { emailPatter, zipPattern } from '@/shared/helpers';
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import ApiServices from "../../utils/ApiServices"
import { successMsg, globalError, formError } from "../../utils/message_herlper"
import { USERLEVEL, USERSTATUS } from "@/utils/constant";


const AccountDetails = (props) => {
    const location = useLocation();
    const history = useHistory()
    let search = queryString.parse(props.location.search)   
    const { handleSubmit, reset, control, errors, register, setValue, clearErrors, formState } = useForm();
    const [cookies, setCookie] = useCookies(['name']);
    const [county, setCounty] = useState([]);
    const [isSubmit, setSubmit] = useState(false);
    const [type, setType] = useState(false);  
    const [editData, setEditData] = useState(false);
    const [noSubmit, setNoSubmit] = useState(0);  

    useEffect(() => {    
        setNoSubmit(formState?.submitCount || 0)
        if (formState.isSubmitted && Object.keys(formState?.errors).length > 0 && formState?.submitCount != noSubmit ) {
            formError()
            return
        }
    }, [formState])

    useEffect(() => {  
        getCounty();
        setType(search?.type)
        if(search?.type === 'update'){
            if(search?.id){
                clientDetails(parseInt(search?.id))
            }
        }

        
     },[props]);

    const clientDetails = (id) => {
        ApiServices.clientsDetails(id).then((result) => {   
            let details = result?.data
            setEditData(details)
            setValue('email', details?.email)
            setValue('client_first_name', details?.client_first_name)
            setValue('client_last_name', details?.client_last_name)
            setValue('company_name', details?.company_name)
            setValue('homeaddress', details?.homeaddress)
            setValue('homephone1', details?.homephone1)
            setValue('homephone2', details?.homephone2)
            setValue('homestate', details?.homestate)
            setValue('homezip', details?.homezip)
            setValue('homecity', details?.homecity)
            setValue('homecounty', details?.county_id)
            setValue('userlevel', details?.userlevel)
            setValue('status', details?.status)
            // setDetails(result?.data);
            // setOutstading(result?.data?.outstanding_balance === 'Y' ? true : false) 
            // setActive(result?.data?.status === 'A' ? true : false);
        })
        .catch((error) => {
            formError();
        })
    }

     

    const getCounty = () =>{
        ApiServices.county()
        .then((result) => {   
            setCounty(result.data);
        })
        .catch(function (error) {
        });
    }

    const onSubmit  = (form) => {
        setSubmit(true)
        if(type === 'create'){
            ApiServices.createStaff(form)
            .then((result) => {
                successMsg('PROPERTY_BILLING_SUCCESS') 
                setTimeout(() => {
                    history.push({pathname: `/stafflist`});
                },1000)
            })
            .catch((error) => {
                setSubmit(false)
                if (error.response) {
                    console.log(error.response.data.errors)
                    // seErrors(error?.response?.data?.errors)
                    formError(error?.response?.data?.errors)
                return
                }
                globalError()
            });
        }

        if(type === 'update'){
            form.id = editData?.id 
            ApiServices.editProfile(form)
            .then((result) => {
                setSubmit(false)
                if(parseInt(cookies.userID)  === editData?.id ){
                    setTimeout(() => {
                        setCookie('userDetails', JSON.stringify(result.data), {  maxAge: 3600 }); 
                        successMsg('PROFILE_SUCCESS');
                    },1000)
                }else{
                    successMsg('STAFF_EDIT_SUCCESS') 
                    setTimeout(() => {
                        history.push({pathname: `/stafflist`});
                    },1000)
                }
                
            })
            .catch((error) => {
                setSubmit(false)
                if (error.response) {
                    console.log(error.response.data.errors)
                    // seErrors(error?.response?.data?.errors)
                    formError(error?.response?.data?.errors)
                return
                }
                globalError()
            });
        }

    }

    const onchangeCounty = (value) => {
        setValue("homecounty", value);
        clearErrors("homecounty");
    };

    const onchangeLevel = (value) => {  
        setValue("userlevel", value);
        clearErrors("userlevel");
    };

    const onchangeStatus = (value) => {
        setValue("status", value);
        clearErrors("status");
    };

    const onBack = () => {
        history.push({pathname: `/stafflist`});
    }
    
  
 
    return (
        <Container>
            <TitleBreadCrumbs title="Your Account Info" >
                <div className="page-breadcrumbs">
                    <Link to="/">Staff</Link>
                    <span className="current_breadcrumbs">
                        Account 
                    </span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card class="card-controlled">
                        <CardBody class="cardbody-controlled">
                            <Row>
                                <Col md={12} className="edit-county-wrap">
                                <form
                                    className="client-reg-form"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                        {type === 'create' ? 'Create New Staff' : ''}
                                        {type === 'update' ? 'Edit Staff Information' : ''}
                                        <hr />
                                        <div className="form_two_columns">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    First Name
                                                </span> 
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="client_first_name"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        rules={{
                                                            required:
                                                                "This is required field",
                                                        }}
                                                        placeholder="First Name"
                                                        // defaultValue={state.firstname}
                                                        // onChange={handleChange}
                                                    />
                                                </div>
                                                {errors?.client_first_name && (
                                                    <span className="form__form-group-error">
                                                        {errors?.client_first_name?.message}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Last Name
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="client_last_name"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        rules={{
                                                            required:
                                                                "This is required field",
                                                        }}
                                                        defaultValue=""
                                                        placeholder="Last Name"
                                                    />
                                                </div>
                                                {errors?.client_last_name && (
                                                    <span className="form__form-group-error">
                                                        {errors?.client_last_name?.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form_two_columns">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">User Level:</span>
                                                <div className="form__form-group-field">
                                                    <FormSelect
                                                        name="userlevel"
                                                        placeholder="Select User Level"
                                                        rules={{
                                                            required: "This is required field",
                                                        }}
                                                        control={control}
                                                        options={USERLEVEL}
                                                        isOject={true}
                                                        objectName="name"
                                                       // disabled={cookies?.userDetails?.userlevel === 1 ? true: false}
                                                        disabled={editData  && editData?.userlevel && editData?.userlevel === USERLEVELS.ADMIN ?  true: false }
                                                        defaultValue={editData  && editData?.userlevel && editData?.userlevel === USERLEVELS.ADMIN ?  editData?.userlevel : editData?.userlevel ? editData?.userlevel : 'DEFAULT' }
                                                        {...register("userlevel", { required: "This is required field" })} 
                                                        onChange={(value)=> onchangeLevel(value)}
                                                    />
                                                </div>
                                                {errors?.userlevel && <span className="form__form-group-error">{errors?.userlevel?.message}</span>}
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">isActive:</span>
                                                <div className="form__form-group-field">
                                                    <FormSelect
                                                        name="status"
                                                        placeholder="Select User Status"
                                                        rules={{
                                                            required: "This is required field",
                                                        }}
                                                        control={control}
                                                        options={USERSTATUS}
                                                        isOject={true}
                                                        objectName="name"
                                                        disabled={editData  && editData?.userlevel && editData?.userlevel === USERLEVELS.ADMIN ?  true: false }
                                                        defaultValue={editData  && editData?.status && editData?.status === 'A' ?  editData?.status : editData?.status ? editData?.status : 'DEFAULT' }
                                                        {...register("status", { required: "This is required field" })} 
                                                        onChange={(value)=> onchangeStatus(value)}
                                                    />
                                                </div>
                                                {errors?.status && <span className="form__form-group-error">{errors?.status?.message}</span>}
                                            </div>
                                        </div>
                                        {
                                            type === 'create' ?
                                            <>
                                            <div className="form_two_columns">
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label">
                                                        Username
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <FormField
                                                            name="username"
                                                            component="input"
                                                            control={control}
                                                            errors={errors}
                                                            rules={{
                                                                required:
                                                                    "This is required field",
                                                            }}
                                                            placeholder="username"
                                                            // defaultValue={state.firstname}
                                                        />
                                                    </div>
                                                    {errors?.username && (
                                                        <span className="form__form-group-error">
                                                            {errors?.username?.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label">
                                                        Password
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <FormField
                                                            name="password"
                                                            component="input"
                                                            control={control}
                                                            errors={errors}
                                                            rules={{
                                                                required: "This is required field",
                                                                minLength: 8,
                                                            }}
                                                            defaultValue=""
                                                            placeholder="Password"
                                                            type="password"
                                                        />
                                                    </div>
                                                    {errors?.password && (
                                                        <span className="form__form-group-error">
                                                            {errors?.password?.message}
                                                        </span>
                                                    )}
                                                    {errors.password && errors.password.type === "minLength" && (
                                                        <span className="form__form-group-error">Please enter at least 8 characters.</span>
                                                    )}
                                                </div>
                                            </div>
                                            </>
                                            :''
                                        }
                                        <div className="form_two_columns">
                                            <div className="form__form-group">
                                                    <span className="form__form-group-label">
                                                        Company Name
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <FormField
                                                            name="company_name"
                                                            component="input"
                                                            control={control}
                                                            errors={errors}
                                                            rules={{
                                                                required: "This is required field",
                                                            }}
                                                            defaultValue=""
                                                            placeholder="Company Name"
                                                        />
                                                    </div>
                                                    {errors?.company_name && (
                                                        <span className="form__form-group-error">
                                                            {errors?.company_name?.message}
                                                        </span>
                                                    )}
                                                </div>
                                            <div className="form__form-group">
                                                    <span className="form__form-group-label">
                                                        Email
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <FormField
                                                            name="email"
                                                            component="input"
                                                            control={control}
                                                            errors={errors}
                                                            rules={{
                                                                required: "This is required field",
                                                                pattern: {
                                                                    value: emailPatter,
                                                                    message:
                                                                        "Entered value does not match email format",
                                                                },
                                                            }}
                                                            defaultValue=""
                                                            placeholder="Email"
                                                        />
                                                    </div>
                                                    {errors?.email && (
                                                        <span className="form__form-group-error">
                                                            {errors?.email?.message}
                                                        </span>
                                                    )}
                                                </div>
                                        </div>
                                        <div className="form_two_columns">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                Home Phone 1:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="homephone1"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        rules={{
                                                            required:
                                                                "This is required field",
                                                        }}
                                                        defaultValue=""
                                                        placeholder="Phone"
                                                    />
                                                </div>
                                                {errors?.homephone1 && (
                                                    <span className="form__form-group-error">
                                                        {errors?.homephone1?.message}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                Home Phone 2:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="homephone2"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        defaultValue=""
                                                        placeholder="Phone 2"
                                                    />
                                                </div>
                                                {errors?.phone2 && (
                                                    <span className="form__form-group-error">
                                                        {errors?.phone2?.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <h3>Client Mailing Information</h3>
                                        <hr />
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Mailing Address
                                            </span>
                                            <div className="form__form-group-field">
                                                <FormField
                                                    name="homeaddress"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    rules={{
                                                        required: "This is required field",
                                                    }}
                                                    defaultValue=""
                                                    placeholder="Mailing Address"
                                                />
                                            </div>
                                            {errors?.homeaddress && (
                                                <span className="form__form-group-error">
                                                    {errors?.homeaddress?.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="form_two_columns">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Mailing City:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="homecity"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        rules={{
                                                            required:
                                                                "This is required field",
                                                        }}
                                                        defaultValue=""
                                                        placeholder="Mailing City"
                                                    />
                                                </div>
                                                {errors?.homecity && (
                                                    <span className="form__form-group-error">
                                                        {errors?.homecity?.message}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Mailing State:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="homestate"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        rules={{
                                                            required:
                                                                "This is required field",
                                                        }}
                                                        defaultValue=""
                                                        placeholder=" Mailing State"
                                                        // value={state.firstname}
                                                        // onChange={handleChange}
                                                    />
                                                </div>
                                                {errors?.homestate && (
                                                    <span className="form__form-group-error">
                                                        {errors?.homestate?.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form_two_columns">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                Mailing County:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormSelect
                                                        name="homecounty"
                                                        control={control}
                                                        options={county}
                                                        isOject={true}
                                                        objectName="name"
                                                        placeholder="Select Country"
                                                        //cookies?.userDetails?.county_id ? cookies?.userDetails?.county_id : 
                                                        defaultValue={'DEFAULT'}
                                                        {...register("homecounty", {
                                                            required:
                                                                "This is required field",
                                                        })}
                                                        onChange={(value) =>
                                                            onchangeCounty(value)
                                                        }
                                                    />
                                                </div>
                                                {errors?.homecounty && (
                                                    <span className="form__form-group-error">
                                                        {errors?.homecounty?.message}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Mailing Zip:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="homezip"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        rules={{
                                                            required:
                                                                "This is required field",
                                                            pattern: {
                                                                value: zipPattern,
                                                                message:
                                                                    "Entered value does not match zip code format",
                                                            },
                                                        }}
                                                        defaultValue=""
                                                        placeholder="Mailing Zip"
                                                    />
                                                </div>
                                                {errors?.homezip && (
                                                    <span className="form__form-group-error">
                                                        {errors?.homezip?.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="edit-county-btns ">
                                            <Button type="button" color="secondary" onClick={()=> onBack()}>Back to List</Button>
                                        <Button
                                           className="float-right"
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmit}
                                        > {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}
                                            {type === 'create' ? 'Submit' : ''}
                                            {type === 'update' ? 'Save Changes' : ''}
                                         </Button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default reduxForm({
    form: 'edit_account_details',
})(AccountDetails);