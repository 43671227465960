/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Tab, Tabs } from 'react-bootstrap';
import ClientTable from '@/components/ClientTable'
import PropertiesTable from '@/components/PropertiesTable'

const NewClientsProperties = () => {
   
    const [count,setCount] = useState(0)
    const [count2,setCount2] = useState(0)
    const isTotal = (value) => {
        setCount(value)
    }

    const isTotal2 = (value) => {  
        setCount2(value)
    }
    return (
    <Row>
        <Col md={12} className="mb-3">
            <div className="dashboard-card-title">
                <p>New Clients/Properties</p>
            </div> 
        </Col>
        <Col md={12}>
            <Tabs defaultActiveKey="client" className="newclientsproperties-wrapper">
                <Tab eventKey="client" title={<>Client {count !== 0 && <span className={`tab-counter tab-length-${count?.toString().length}`}>{`${count}`}</span> }</>}>
                    <ClientTable  title="New Clients" status="A" is_new={true} isTotal={(value)=> isTotal(value)}  />
                </Tab>
                <Tab eventKey="properties" title={<>Properties {count2 !== 0 && <span className={`tab-counter tab-length-${count2?.toString().length}`}>{`${count2}`}</span> }</>}>
                    <PropertiesTable title="New Properties" status="A" is_new={true} isTotal={(value)=> isTotal2(value)} />
                </Tab>
            </Tabs>
        </Col>
    </Row>
    );
}

export default React.memo(NewClientsProperties);