/* eslint-disable */
import React, { useState, useEffect } from 'react';
import moment from 'moment'
import PaperIcon from '@/images/paperIcon'
import TrashIcon from '@/images/trashIcon'
import ToggleSwitch from './ToggleSwitch'
import { USERLEVELS } from "@/utils/constant";

const DocumentCard = ({ index, docuType, id, file_type, name, status, onDelete , onStatus, is_view, agentFile, file, created_at}) => {     
    const userData = JSON.parse(localStorage.getItem('userData'));
    const onChangeStatus = (status) => {
        onStatus({id: id, status: status ? 1 : 0, type: parseInt(docuType) })
    }
    return(
       
        <>
           <div className="general-files-list"  >
                <div>
                    <span className="filetype" onClick={() => is_view ? agentFile({file,name}) : () => false }  style={{ cursor: is_view? 'pointer' : 'default'  }}>{file_type}</span>
                    <span className="filename" onClick={() => is_view ? agentFile({file,name}) : () => false }  style={{ cursor: is_view? 'pointer' : 'default'  }}>{name} <p>{created_at}</p></span>
                    <div className="fileicon">
                       {userData.userlevel !== USERLEVELS.CLIENT && userData?.userlevel !== USERLEVELS.STAFF_ROLE  && (
                           <div className="">
                                <span>Hold</span>
                                <ToggleSwitch
                                    id={`general-switch${docuType}-${index}`}
                                    checked={status === 1 ? true : false}
                                    disabled={false}
                                    onChange={onChangeStatus}
                                />
                            </div>
                       )}
                       {userData.userlevel === USERLEVELS.CLIENT && (
                           <div><PaperIcon color="#98a6ad" /></div>
                       )}
                    </div>
                </div>
                <div className="docu-delete-wrapper pt-4">
                    <div style={{display: 'none'}}>{ status !== USERLEVELS.ADMIN ? 'The file will be deleted after 1 month 28 days' : ''}</div>
                    {userData?.userlevel !== USERLEVELS.CLIENT  && userData?.userlevel !== USERLEVELS.STAFF  && userData?.userlevel !== USERLEVELS.STAFF_ROLE && (
                    <div className="text-right">
                        <span className={`cursor-pointer docu-delete ${id}`} onClick={()=> onDelete(id)}>
                            <TrashIcon color="#FFA647" /> Delete
                        </span>
                    </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default DocumentCard;