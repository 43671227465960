/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import { USERLEVELS } from "@/utils/constant";

const TitleBreadCrumbs = ({
    children,
    title,
    link,
    btn,
    linkSearch,
    linkMultiple,
}) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    
    return (
        <div>
            <Row>
                <Col md={12}>{children}</Col>
                <Col md={12}>
                    <h2 className="page-heading">{title}</h2>

                    <div className="breacrum-button">
                        {}
                        {linkSearch && (
                            <Link to={linkSearch} className="mr-2">
                                <Button color="secondary">Custom Search</Button>
                            </Link>
                        )}

                        {userData.userlevel === USERLEVELS.ADMIN && btn ? (
                            <>
                                <Link to={link}>
                                    <Button color="primary">{btn}</Button>
                                </Link>
                            </>
                        ) : (
                            ""
                        )}
                        {linkMultiple ? (
                            <>
                                <Link to={linkMultiple} className="ml-2">
                                    <Button color="secondary">
                                        Multi-upload Documents
                                    </Button>
                                </Link>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default TitleBreadCrumbs;
