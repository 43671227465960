/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {  useHistory } from 'react-router-dom';
import {  reduxForm } from 'redux-form';
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import { Button, Alert, Row, Col, Spinner } from 'reactstrap';
import { useCookies } from 'react-cookie';
import ApiServices from "@/utils/ApiServices"
import { successMsg, globalError, formError, errorMsgCustom } from "@/utils/message_herlper"
import {  US_STATES } from "@/utils/constant";
import { TABLE_SETTINGS, USERLEVELS } from "@/utils/constant";
import { emailPatter, zipPattern } from '@/shared/helpers';
import LockIcon from '@/images/lockIcon'
import InfoIcon from '@/images/infoIcon'
import ModalModeration from '@/components/ModalModeration'
import ChangePassword from '@/components/ChangePassword';
import { HOME_API } from '@/utils/constant'
import { formatPhoneNumber } from "@/utils/helpers"


const EditFormClient = ({details}) => {
    const history = useHistory();
    const [county, setCounty] = useState([]);
    const { handleSubmit, reset, control, errors, register, setValue, clearErrors, formState } = useForm();
    const [cookies, setCookie] = useCookies(['name']);
    const [editData, setEditData] = useState(false);
    const [noSubmit, setNoSubmit] = useState(0);  
    const [isSubmit, setSubmit] = useState(false);
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');
    const [onRequest, setOnRequest] = useState(false)
    const userData = JSON.parse(localStorage.getItem('userData'));

    useEffect(() => {  
        getCounty();
        ApiServices.viewEditPending(userData.id).then((result) => {
            if( Array.isArray(result.data) && result.data.length ){
                if( result.data.status === 0 ){
                    setOnRequest(true)
                }
            }
        })
        .catch((error) => {
            //
        })
        
        if(details){ 
            setValue('username', details?.username)
            setValue('email', details?.email)
            setValue('client_first_name', details?.client_first_name)
            setValue('client_last_name', details?.client_last_name)
            setValue('company_name', details?.company_name)
            setValue('homeaddress', details?.homeaddress)
            setValue('homephone1', details?.homephone1)
            setPhone1(details?.homephone1);
            setValue('homephone2', details?.homephone2)
            setValue('homestate', details?.homestate)
            setValue('homezip', details?.homezip)
            setValue('homecity', details?.homecity)
            setValue('homecounty', details?.homecounty)
        }

     },[details]);

     useEffect(() => {
        setNoSubmit(formState?.submitCount || 0);
        if (
            formState.isSubmitted &&
            Object.keys(formState?.errors).length > 0 &&
            formState?.submitCount != noSubmit
        ) {
            formError();
            return;
        }
    }, [formState]);
    
    const getCounty = () => {
        ApiServices.county()
        .then((result) => {   
            setCounty(result.data);
        })
        .catch(function (error) {
        });
    }

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(!open);
    }

    const onChangePhone1 = (value) =>{
        let formatted_number = formatPhoneNumber(value)
        setPhone1(formatted_number)
//        setValue("homephone1", formatted_number);
//        clearErrors("homephone1");
    }

    const onChangePhone2 = (value) =>{
        let formatted_number = formatPhoneNumber(value)
        setPhone2(formatted_number)
//        setValue("homephone1", formatted_number);
//        clearErrors("homephone1");
    }

    
    const onChangePassword  = (form) => {
        setPassSubmit(true)
        form.id = userData.id
        ApiServices.updatePassword(form).then((result) => {  
           setTimeout(() =>{
               setChangePass(false)
               setPassSubmit(false)
               successMsg('PASSWORD_SUCCESS');
           },1000)
        })
        .catch((error) => {
            setPassSubmit(false)
            globalError()
        })
    }

    const onSubmit = (form) => { 
        setSubmit(true)
        form.id = userData.id
        form.homephone1 = phone1
        form.homephone2 = phone2
        form.url= HOME_API+'client/details/'+userData.id
        ApiServices.editProfileClient(form)
        .then((result) => {
           setTimeout(()=> {
                setOpen(true);
                setSubmit(false) 
                setCookie('userDetails', JSON.stringify(result.data), {  maxAge: 3600 }); 
                // successMsg('PROFILE_SUCCESS');
           },1000)
        })
        .catch((error) => {
            setSubmit(false)
            if(error?.response?.status === 403){
                errorMsgCustom('Unable to submit form. Please try again after 1 hour.');
                return
            }
            if (error?.response) {
                formError(error?.response?.data?.errors)
               return
            }
            globalError()
        });
    }

    const onchangeState = (value) => {
        setValue('homestate', value)
        clearErrors("homestate")
    }

    const onchangeCounty = (value) => {
        setValue('homecounty', value)
        clearErrors("homecounty")
    }

    const [changePass, setChangePass] = useState(false);
    const [isPassSubmit, setPassSubmit] = useState(false);

    const handleChangePass = () => {
        setChangePass(!changePass)
    }

    const cancelEdit = () => {
        history.push({ pathname: `/dashboard` })
    }
  
    return (
        <>
        <ChangePassword isSubmit={isPassSubmit}   open={changePass}  onSubmit={(form) => onChangePassword(form)}  close={() => setChangePass(false)}  />
        <ModalModeration open={open} close={handleClose} />
        <form className="userdetails-edit-form" onSubmit={handleSubmit(onSubmit)}>
            {userData.userlevel !== USERLEVELS.ADMIN  && (
                <Alert color="primary" className="account-alert mb-5">
                    <Row>
                        <Col md={12} className="alert-with-icon">
                        <span className="mr-2"><InfoIcon color="#ffffff" /></span>
                        { onRequest ? <><p>Data change request is under review.</p></> : <><p>Your request for data change is first send for our moderators to review.<br />You'll receive an email notifcation once it's approved.</p></>}
                        
                        </Col>
                        {/* <Col md={5} className="text-right">
                            <Link to="" className="btn btn-secondary secondary-inverted mb-0">Contact Us</Link>
                        </Col> */}
                    </Row>
                </Alert>
            )}
            <h4>Personal Information</h4>
            <div className="form__form-group">
                <span className="form__form-group-label">First Name:</span>
                <div className="flex-input">
                    <div className="input-1">
                    <div className="form__form-group-field">
                    <FormField
                        name="client_first_name"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{
                            required:
                                "This is required field",
                        }}
                        placeholder="First Name"
                        defaultValue=""
                        // onChange={handleChange}
                    />
                </div>
                    </div>
                    <div>
                    {errors?.client_first_name && (
                        <span className="form__form-group-error">
                            {errors?.client_first_name?.message}
                        </span>
                    )}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">Last Name:</span>
                <div className="flex-input">
                    <div className="input-1">
                    <div className="form__form-group-field">
                    <FormField
                        name="client_last_name"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{
                            required:
                                "This is required field",
                        }}
                        placeholder="Last Name"
                        defaultValue=""
                        // onChange={handleChange}
                    />
                </div>
                    </div>
                    <div>
                    {errors?.client_last_name && (
                        <span className="form__form-group-error">
                            {errors?.client_last_name?.message}
                        </span>
                    )}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">Company Name:</span>
                <div className="flex-input">
                    <div className="input-1">
                    <div className="form__form-group-field">
                    <FormField
                        name="company_name"
                        component="input"
                        control={control}
                        errors={errors}
                        placeholder="Company Name"
                        defaultValue=""
                        // onChange={handleChange}
                    />
                </div>
                    </div>
                    <div>
                    {errors?.company_name && (
                        <span className="form__form-group-error">
                            {errors?.company_name?.message}
                        </span>
                    )}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">Email:</span>
                <div className="flex-input">
                    <div className="input-1">
                    <div className="form__form-group-field">
                    <FormField
                        name="email"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{
                            required:
                                "This is required field",
                            pattern: {
                                value: emailPatter,
                                message:
                                    "Entered value does not match email format",
                            },
                        }}
                        placeholder="Email"
                        defaultValue=""
                        disabled={true}
                        // onChange={handleChange}
                    />
                </div>
                    </div>
                    <div>
                    {errors?.email && (
                        <span className="form__form-group-error">
                            {errors?.email?.message}
                        </span>
                    )}
                    </div>
                </div>
            </div>
          
            <hr />
            <h4>Account Settings</h4>
            <div className="form__form-group">
                <span className="form__form-group-label">Username</span>
                <div className="form__form-group-field flex-wrap">
                    <FormField
                        name="username"
                        component="input"
                        control={control}
                        errors={errors}
                        placeholder="Username"
                        defaultValue=""
                        disabled={true}
                    />
                    <Button onClick={handleChangePass} color="secondary" className="secondary-2 mb-0 mt-3"><LockIcon /> &nbsp;&nbsp;&nbsp;Change Password</Button>
                </div>
            </div>
            {/* <div className="form__form-group">
                <span className="form__form-group-label">Password</span>
                <div className="form__form-group-field flex-wrap">
                    <FormField
                        name="password"
                        component="input"
                        type="password"
                        control={control}
                        errors={errors}
                        disabled
                        defaultValue=""
                    />
                    <Button onClick={handleChangePass} color="secondary" className="secondary-2 mb-0 mt-3"><LockIcon /> Change Password</Button>
                </div>
            </div> */}
            
            <hr />
            <h4>Contact Information</h4>
            {/* <div className="form__form-group">
                <span className="form__form-group-label">State</span>
                <div className="form__form-group-field">
                    <FormSelect
                        name="homestate"
                        control={control}
                        options={US_STATES}
                        isOject={true}
                        objectName="name"
                        placeholder="Select State"
                        defaultValue={details?.homestate ? details?.homestate : 'DEFAULT'}
                        onChange={(value) => onchangeState(value)}
                    />
                </div>
            </div> */}

            <div className="form__form-group">
                <span className="form__form-group-label">State:</span>
                <div className="flex-input">
                    <div className="input-1">
                    <div className="form__form-group-field">
                    <FormSelect
                        name="homestate"
                        control={control}
                        options={US_STATES}
                        isOject={true}
                        objectName="name"
                        placeholder="Select State"
                        defaultValue={details?.homestate ? details?.homestate : 'DEFAULT'}
                        {...register("homestate", {
                            required: "This is required field",
                        })}
                        onChange={(value) => onchangeState(value)}
                    />
                </div>
                    </div>
                    <div>
                    {errors?.homestate && (
                        <span className="form__form-group-error">
                            {errors?.homestate?.message}
                        </span>
                    )}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">County:</span>
                <div className="flex-input">
                    <div className="input-1">
                    <div className="form__form-group-field">
                    <FormField
                        name="homecounty"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{
                            required:
                                "This is required field",
                        }}
                        defaultValue=""
                        placeholder="Mailing County"
                    />
                </div>
                    </div>
                    <div>
                    {errors?.homecounty && (
                        <span className="form__form-group-error">
                            {errors?.homecounty?.message}
                        </span>
                    )}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">City:</span>
                <div className="flex-input">
                    <div className="input-1">
                    <div className="form__form-group-field">
                    <FormField
                        name="homecity"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{
                            required:
                                "This is required field",
                        }}
                        defaultValue=""
                        placeholder="Mailing City"
                    />
                </div>
                    </div>
                    <div>
                    {errors?.homecity && (
                        <span className="form__form-group-error">
                            {errors?.homecity?.message}
                        </span>
                    )}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">Address:</span>
                <div className="flex-input">
                    <div className="input-1">
                    <div className="form__form-group-field">
                    <FormField
                        name="homeaddress"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{
                            required:
                                "This is required field",
                        }}
                        defaultValue=""
                        placeholder="Address"
                    />
                </div>
                    </div>
                    <div>
                    {errors?.homeaddress && (
                        <span className="form__form-group-error">
                            {errors?.homeaddress?.message}
                        </span>
                    )}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">Zip:</span>
                <div className="flex-input">
                    <div className="input-1">
                    <div className="form__form-group-field">
                    <FormField
                        name="homezip"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{
                            pattern: {
                                value: zipPattern,
                                message: 'Entered value does not match zip code format',
                            },
                        }}
                        placeholder="Zip"
                        defaultValue=""
                    />
                </div>
                    </div>
                    <div>
                    {errors?.homezip && (
                        <span className="form__form-group-error">
                            {errors?.homezip?.message}
                        </span>
                    )}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">Phone:</span>
                <div className="flex-input">
                    <div className="input-1">
                    <div className="form__form-group-field">
                    {/*
                    <FormField
                        name="homephone1"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{
                            required:
                                "This is required field",
                        }}
                        placeholder="Phone"
                        defaultValue=""
                    />*/}
                    <input
                        value={phone1}
                        name="homephone1"
                        onChange={(value) => onChangePhone1(value?.target?.value)}
                    />                    
                </div>
                    </div>
                    <div>
                    {errors?.homephone1 && (
                        <span className="form__form-group-error">
                            {errors?.homephone1?.message}
                        </span>
                    )}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">Phone 2 (optional)</span>
                <div className="form__form-group-field">
                        {/*
                    <FormField
                        name="homephone2"
                        component="input"
                        type="number"
                        control={control}
                        errors={errors}
                        defaultValue=""
                    /> */}
                    <input
                        value={phone2}
                        name="homephone2"
                        onChange={(value) => onChangePhone2(value?.target?.value)}
                    />                           
                </div>
            </div>
            
            <div className="pt-3">
                <Button color="primary" type="submit" disabled={isSubmit}>{isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''} Send for moderation</Button>
                <Button color="cancel" type="button" onClick={()=> cancelEdit()}>Cancel</Button>
            </div>
        </form>
        </>
    )
}

export default reduxForm({
    form: 'editformclient',
})(EditFormClient);