/* eslint-disable */

const StopIcon = ({ color="#383938", custom }) => {
    return (
    <svg className={`custom ${custom ? custom : '' }`} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.00016 15.1666C11.6821 15.1666 14.6668 12.1818 14.6668 8.49992C14.6668 4.81802 11.6821 1.83325 8.00016 1.83325C4.31826 1.83325 1.3335 4.81802 1.3335 8.49992C1.3335 12.1818 4.31826 15.1666 8.00016 15.1666Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.28662 3.78662L12.7133 13.2133" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

    )
}
export default StopIcon;