/* eslint-disable */
import React from 'react'
import moment  from 'moment'
import Empty from "@/components/Empty";
import { Col, Row, Button } from 'reactstrap';
import {  USERLEVELS } from "@/utils/constant";

const ListNotes = ({ notes, isClient, deleteNotes, editNotes }) => {   
    
    const userData = JSON.parse( localStorage.getItem('userData') )

    return (
        <>
            {notes.length > 0 && <p className="tab-content-title mb-4">Recent notes</p>}
            <div className="client-activities-list-wrapper">
                { notes.map((obj,i) => (
                    <Row className="note-list-item mb-3" key={i}>
                        <Col md={6}>
                            <p className="activity_user">
                                <b>
                                   {isClient ? <>Admin: <span>{obj.client_first_name} {obj.client_last_name}</span></>: 
                                   <>Client: <span>{obj.username}</span></>
                                   } 
                                   {
                                       obj.paddress ? ' | Property: ' + obj.paddress : ''
                                   }
                                </b>
                            </p>
                        </Col>
                        <Col md={6} className="text-right">
                            <p>{moment(obj.created_at).format('YYYY MMM DD, h:mm A')}</p>
                        </Col>
                        <Col md={12} className="mt-3">
                            {obj.notes}
                            {userData?.userlevel !== USERLEVELS.CLIENT  && userData?.userlevel !== USERLEVELS.STAFF  && userData?.userlevel !== USERLEVELS.STAFF_ROLE && (
                                <>
                                <Button color="secondary" className="float-right ml-1" size="sm"  onClick={()=> deleteNotes(obj.id)}>Delete</Button>
                                <Button color="primary" className="float-right "  size="sm" onClick={()=> editNotes(obj)}>Edit</Button>
                                </>
                            )}
                        </Col>
                    </Row>
                ))}
                {notes.length < 1 ? <Empty /> :''}
            </div>
        </>
    );
}

export default ListNotes;