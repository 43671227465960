/* eslint-disable */
import React from 'react';
import { 
    Card, CardBody, Col, Container, Row
  } from 'reactstrap';
import { Button } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

const CountyEdit = () => {
    const location = useLocation();
    localStorage.setItem('account', 'true');

    return (
        <Container>
            <Row>
                <Col md={6}>
                    <h2 className="page-heading">Edit County</h2>
                </Col>
                <Col md={6}>
                    <div className="page-breadcrumbs">
                        <Link to="/">Home</Link>
                        <Link to="/county-list">County</Link>
                        <span className="current_breadcrumbs">Edit</span>
                    </div>
                </Col>
                <Col md={12}>
                    <Card className="card-controlled">
                        <CardBody className="cardbody-controlled">
                            <Row>
                                <Col md={12} className="edit-county-wrap">
                                    <form>
                                        <h3>Edit HARRIS Information</h3>
                                        <hr />
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">County Name</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="countyname"
                                                    component="input"
                                                    type="text"
                                                    placeholder="County Name"
                                                    className="input-without-border-radius"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">District Name</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="districtname"
                                                    component="input"
                                                    type="text"
                                                    placeholder="District Name"
                                                    className="input-without-border-radius"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Address</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="address"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Address"
                                                    className="input-without-border-radius"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">City</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="city"
                                                    component="input"
                                                    type="text"
                                                    placeholder="City"
                                                    className="input-without-border-radius"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">State</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="state"
                                                    component="input"
                                                    type="text"
                                                    placeholder="State"
                                                    className="input-without-border-radius"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Zip Code</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="zipcode"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Zip Code"
                                                    className="input-without-border-radius"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Agent Code</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="agentcode"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Agent Code"
                                                    className="input-without-border-radius"
                                                />
                                            </div>
                                        </div>
                                        <div className="edit-county-btns">
                                            <Link className="btn btn-secondary" to="/county-list">Back to List</Link>
                                            <Button color="primary">Save</Button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default reduxForm({
    form: 'edit_county',
})(CountyEdit);