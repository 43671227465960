/* eslint-disable */
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { Card, CardBody, Col, Container, Row, Spinner, Input } from "reactstrap";
import { reduxForm } from "redux-form";
import { useForm, Controller } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import {
    successMsg,
    globalError,
    formError,
} from "../../utils/message_herlper";
import ApiServices from "@/utils/ApiServices";
import Modal from "react-bootstrap/Modal";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";

const AddBilling = (props) => {
    let search = queryString.parse(props.location.search);
    let { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
        Array.from(
            { length: (stop - start) / step + 1 },
            (_, i) => start + i * step
        );
    const [details, setDetails] = useState(null);
    const [pdetails, setpDetails] = useState(null);
    const [years, setYears] = useState(range(currentYear, currentYear - 4, -1));
    const [billingID, setBillingID] = useState();
    const [resultList, setResultList] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [agents, setAgents] = useState([]);
    const [isSubmit, setSubmit] = useState(false);
    const [noSubmit, setNoSubmit] = useState(0);
    const [newAgent, setNewAgent] = useState(null);
    const [isedit, setEdit] = useState(false);

    const {
        handleSubmit,
        reset,
        control,
        errors,
        register,
        setValue,
        clearErrors,
        watch,
        getValues,
        formState,
    } = useForm();

    useEffect(() => {
        setNoSubmit(formState?.submitCount || 0);
        if (
            formState.isSubmitted &&
            Object.keys(formState?.errors).length > 0 &&
            formState?.submitCount != noSubmit
        ) {
            formError();
            return;
        }
    }, [formState]);

    useEffect(() => {
        let { id, billing_id } = props?.match?.params;
        propertyDetails();
        setBillingID(id);
        if (billing_id) {
            setYears(range(currentYear, currentYear - 11, -1));
            ApiServices.propertyBillingDetails(billing_id)
                .then((result) => {
                    console.log(result);
                    setpDetails(result.data);
                    let billing = result.data;
                    setValue("year", billing?.year);
                    setValue("agent", billing?.agent_id);
                    setValue("result", billing?.pr_id);
                    setValue("billingPercentage", billing?.billingPercentage);
                    setValue("noticedValue", billing?.noticedValue);
                    setValue("settledValue", billing?.settledValue);
                    //setValue('taxrate', (billing?.taxrate * 100) )
                    setValue("taxrate", billing?.taxrate);
                    setValue("reduction", billing?.reduction);
                    setValue("taxsavings", billing?.taxsavings);
                    setValue("invoiceamount", billing?.invoiceamount);
                    setValue("assessed_value", billing?.assessed_value);
                    setValue("is_marketcut", Boolean(billing?.is_marketcut));
                    setValue("is_no_change", Boolean(billing?.is_no_change));
                })
                .catch((error) => {
                    formError();
                });
        } else {
            setValue("year", currentYear);
        }
    }, [location]);

    useEffect(() => {
        resultsList();
        getagentList();
        allAgents();
    }, [props]);

    useEffect(() => {
        if (pdetails) {
            const agent_index = agents.findIndex(
                (item) => item.id === pdetails?.agent_id
            );
            if (agent_index !== -1) {
                setValue(
                    "resultagent2",
                    agents[agent_index]?.name || "DEFAULT"
                );
            }
        }
    }, [agents, pdetails]);

    const resultsList = () => {
        ApiServices.resultsList().then((result) => {
            //var a = [{name:'tc_001'}, {name:'tc_002'}, {name:'tc_003'}];
            var a = result?.data;
            a.splice(
                a.findIndex((e) => e.id == 10),
                1
            );
            a.splice(
                a.findIndex((e) => e.id == 11),
                1
            );
            a.splice(
                a.findIndex((e) => e.id == 12),
                1
            );
            a.splice(
                a.findIndex((e) => e.id == 13),
                1
            );
            a.splice(
                a.findIndex((e) => e.id == 15),
                1
            );
            //console.log(a);

            //setResultList(result?.data)
            setResultList(a);
        });
    };

    const allAgents = () => {
        ApiServices.agentList().then((result) => {
            setAgents(result?.data);
        });
    };

    const getagentList = () => {
        ApiServices.agentListActive().then((result) => {
            setAgentList(result?.data);
        });
    };

    const propertyDetails = () => {
        let { billing_id } = props?.match?.params;
        ApiServices.propertyDetails(id)
            .then((result) => {
                if (!billing_id) {
                    setValue(
                        "billingPercentage",
                        result?.data?.accnt_percent_of_savings
                    );
                }
                setDetails(result?.data);
            })
            .catch((error) => {
                formError();
            });
    };

    const handleFormSubmit = (form) => {
        setSubmit(true);

        if (!pdetails) {
            form.id = id;
            ApiServices.propertyBilling(form)
                .then((result) => {
                    setTimeout(() => {
                        successMsg("PROPERTY_BILLING_SUCCESS");
                        history.push({ pathname: `/property/details/${id}` });
                    }, 1000);
                })
                .catch((error) => {
                    setSubmit(false);
                    globalError();
                });
        } else {
            form.id = pdetails?.id;
            ApiServices.updatePropertyBilling(form)
                .then((result) => {
                    setTimeout(() => {
                        successMsg("PROPERTY_BILLING_EDIT_SUCCESS");
                        history.push({ pathname: `/property/details/${id}` });
                    }, 1000);
                })
                .catch((error) => {
                    setSubmit(false);
                    globalError();
                });
        }
    };
    const onchangeYear = (value) => {
        setValue("year", value);
        clearErrors("year");
    };

    const onchangeResult = (value) => {
        setValue("result", value);
        clearErrors("result");
    };

    const onchangeAgent = (value) => {
        setValue("agent", value);
        clearErrors("agent");
    };

    function formatToCurrency(amount) {
        if (!amount) amount = 0;

        return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }

    function formatToCurrencyNoFixed(amount) {
        if (!amount) amount = 0;

        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const formChange = (form) => {
        console.log(form);
        var n_value = form.noticedValue ? parseFloat(form.noticedValue) : 0;
        var s_value = form.settledValue ? parseFloat(form.settledValue) : 0;

        var nValue = parseFloat(form?.noticedValue || 0);
        var sValue = parseFloat(form?.settledValue || 0);

        //var nValue = parseFloat(n_value.toFixed(2).replace(",",""))
        //var sValue = parseFloat(s_value.toFixed(2).replace(",",""))

        var tRate = parseFloat(form?.taxrate || 0);
        var bRate = parseFloat(form?.billingPercentage || 0);
        bRate = bRate / 100;

        var aReduction = nValue - sValue;
        var tSaving = aReduction * tRate;
        var iAmount = tSaving * bRate;

        // setValue('noticedValue', formatToCurrencyNoFixed(parseFloat(n_value) ) )
        // setValue('settledValue', formatToCurrencyNoFixed(parseFloat(s_value)) )

        // setValue('reduction', aReduction?.toFixed(2))
        // setValue('taxsavings', tSaving?.toFixed(2))
        // setValue('invoiceamount', iAmount?.toFixed(2))

        setValue("reduction", formatToCurrency(aReduction));
        setValue("taxsavings", formatToCurrency(tSaving));
        setValue("invoiceamount", formatToCurrency(iAmount));
    };

    const onCancel = () => {
        history.push({ pathname: `/property/details/${id}` });
    };

    const editAgent = () => {
        setEdit(true);
    };

    const onchangeAgent2 = (value) => {
        setNewAgent(value);
    };

    const onSubmit = (data) => {
        const agent_index = agents.findIndex(
            (item) => item.id === parseInt(newAgent)
        );

        if(agent_index !== -1){
            setValue("resultagent2", agents[agent_index]?.name);
        }
        setValue("agent", parseInt(newAgent));
        setEdit(false);
    };

    const setAgentValue = () => {
        if (pdetails) {
            const index = agentList.findIndex(
                (item) => item.id === pdetails?.agent_id
            );

            if (index === -1) {
                return "DEFAULT";
            }
            return pdetails?.agent_id;
        }
    };

    let agents_selections = agentList; // pdetails  ? agents  : agentList

    return (
        <Container>
            <TitleBreadCrumbs title="Property Billing">
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <span className="current_breadcrumbs">Billing</span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card className="card-controlled">
                        <CardBody className="cardbody-controlled client-registration-form-container">
                            <Row>
                                <Col md={12}>
                                    <form
                                        className="client-reg-form"
                                        onSubmit={handleSubmit(
                                            handleFormSubmit
                                        )}
                                        onChange={(form) =>
                                            formChange(getValues())
                                        }
                                    >
                                        <h4>
                                            {" "}
                                            {billingID ? "Edit" : "Create"}{" "}
                                            Billing Information for Property:{" "}
                                            {details?.property_address}{" "}
                                            {details?.property_city},{" "}
                                            {details?.property_state}{" "}
                                            {details?.property_zip}
                                        </h4>
                                        <hr />
                                        <div className="form__form-group-two">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Current Tax Year:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormSelect
                                                        name="year"
                                                        placeholder="Select  Year"
                                                        rules={{
                                                            required:
                                                                "This is required field",
                                                        }}
                                                        control={control}
                                                        options={years}
                                                        defaultValue={
                                                            pdetails?.year ||
                                                            currentYear
                                                        }
                                                        {...register("year", {
                                                            required:
                                                                "This is required field",
                                                        })}
                                                        onChange={(value) =>
                                                            onchangeYear(value)
                                                        }
                                                    />
                                                </div>
                                                {errors?.year && (
                                                    <span className="form__form-group-error">
                                                        {errors?.year?.message}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <span className="form__form-group-label">
                                                    Billing Percentage:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="billingPercentage"
                                                        component="input"
                                                        type="text"
                                                        control={control}
                                                        errors={errors}
                                                        rules={{
                                                            required:
                                                                "This is required field",
                                                            min: {
                                                                value: 0,
                                                                message:
                                                                    "Please enter valid value.",
                                                            },
                                                            max: {
                                                                value: 100,
                                                                message:
                                                                    "Please enter valid value.",
                                                            },
                                                        }}
                                                        placeholder=""
                                                    />
                                                </div>
                                                {errors?.billingPercentage && (
                                                    <span className="form__form-group-error">
                                                        {
                                                            errors
                                                                ?.billingPercentage
                                                                ?.message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div
                                            className={`${
                                                pdetails &&
                                                pdetails.is_marketcut === 1
                                                    ? "form__form-group-four"
                                                    : "form__form-group-three"
                                            } `}
                                        >
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Noticed Value:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="noticedValue"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        placeholder=""
                                                    />
                                                </div>
                                                {errors?.noticedValue && (
                                                    <span className="form__form-group-error">
                                                        {
                                                            errors?.noticedValue
                                                                ?.message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                            {/* {pdetails &&
                                                pdetails.is_marketcut === 1 && ( */}
                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label">
                                                            Assessed Value:
                                                        </span>
                                                        <div className="form__form-group-field">
                                                            <FormField
                                                                name="assessed_value"
                                                                component="input"
                                                                control={
                                                                    control
                                                                }
                                                                errors={errors}
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        {errors?.settledValue && (
                                                            <span className="form__form-group-error">
                                                                {
                                                                    errors
                                                                        ?.settledValue
                                                                        ?.message
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                {/* )} */}
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Final Value:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="settledValue"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        placeholder=""
                                                    />
                                                </div>
                                                {errors?.settledValue && (
                                                    <span className="form__form-group-error">
                                                        {
                                                            errors?.settledValue
                                                                ?.message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Tax Rate:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="taxrate"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        placeholder=""
                                                    />
                                                </div>
                                                {errors?.taxrate && (
                                                    <span className="form__form-group-error">
                                                        {
                                                            errors?.taxrate
                                                                ?.message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form__form-group-two">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Result:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormSelect
                                                        name="result"
                                                        placeholder="Select Result"
                                                        rules={{
                                                            required:
                                                                "This is required field",
                                                        }}
                                                        control={control}
                                                        options={resultList}
                                                        isOject={true}
                                                        objectName="name"
                                                        defaultValue={
                                                            parseInt(
                                                                pdetails?.pr_id
                                                            ) || "DEFAULT"
                                                        }
                                                        {...register("result")}
                                                        onChange={(value) =>
                                                            onchangeResult(
                                                                value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                {errors?.result && (
                                                    <span className="form__form-group-error">
                                                        {
                                                            errors?.result
                                                                ?.message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Resulting Agent:
                                                </span>
                                                <div className="form__form-group-field">
                                                    {pdetails ? (
                                                        <>
                                                            <FormField
                                                                name="resultagent2"
                                                                component="input"
                                                                control={
                                                                    control
                                                                }
                                                                errors={errors}
                                                                placeholder=""
                                                                disabled
                                                            />
                                                            <div
                                                                className="edit-button"
                                                                onClick={() =>
                                                                    editAgent()
                                                                }
                                                            >
                                                                Edit
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FormSelect
                                                                name="agent"
                                                                placeholder="Select Agent"
                                                                rules={{
                                                                    required:
                                                                        "This is required field",
                                                                }}
                                                                control={
                                                                    control
                                                                }
                                                                options={
                                                                    agents_selections
                                                                }
                                                                isOject={true}
                                                                objectName="name"
                                                                defaultValue={
                                                                    parseInt(
                                                                        pdetails?.agent_id
                                                                    ) ||
                                                                    "DEFAULT"
                                                                }
                                                                {...register(
                                                                    "agent"
                                                                )}
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    onchangeAgent(
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                                {errors?.agent && (
                                                    <span className="form__form-group-error">
                                                        {errors?.agent?.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="form__form-group-three">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Amount of Reduction:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="reduction"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        placeholder=""
                                                        disabled
                                                    />
                                                </div>
                                                {errors?.reduction && (
                                                    <span className="form__form-group-error">
                                                        {
                                                            errors?.reduction
                                                                ?.message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Tax Savings:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="taxsavings"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        placeholder=""
                                                        disabled
                                                    />
                                                </div>
                                                {errors?.taxsavings && (
                                                    <span className="form__form-group-error">
                                                        {
                                                            errors?.taxsavings
                                                                ?.message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Invoice Amount:
                                                </span>
                                                <div className="form__form-group-field">
                                                    <FormField
                                                        name="invoiceamount"
                                                        component="input"
                                                        control={control}
                                                        errors={errors}
                                                        placeholder=""
                                                        disabled
                                                    />
                                                </div>
                                                {errors?.invoiceamount && (
                                                    <span className="form__form-group-error">
                                                        {
                                                            errors
                                                                ?.invoiceamount
                                                                ?.message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <hr />
                                        <div className="form__form-group-three" style={{ justifyContent: 'flex-start'}}>
                                            <div className="form__form-group">
                                                {/* <span className="form__form-group-label">
                                                    Market Cut:
                                                </span> */}
                                                <div className="form__form-group-field">
                                                    <div className="flex-al-cen">
                                                        <input 
                                                            type="checkbox" 
                                                            id="market-cut" 
                                                            defaultChecked={false}
                                                            {...register('is_marketcut')} 
                                                            onChange={(e) => {
                                                                setValue('is_marketcut', e.target.checked);
                                                            }} />
                                                        <label for="market-cut">Market Cut</label>
                                                    </div>
                                                </div>
                                                {errors?.invoiceamount && (
                                                    <span className="form__form-group-error">
                                                        {
                                                            errors
                                                                ?.invoiceamount
                                                                ?.message
                                                        }
                                                    </span>
                                                )}
                                            </div>

                                            <div className="form__form-group">
                                                {/* <span className="form__form-group-label">
                                                    Market Cut:
                                                </span> */}
                                                <div className="form__form-group-field">
                                                    <div className="flex-al-cen">
                                                        <input 
                                                            type="checkbox" 
                                                            id="no-change" 
                                                            defaultChecked={false}
                                                            {...register('is_no_change')} 
                                                            onChange={(e) => {
                                                                setValue('is_no_change', e.target.checked);
                                                            }} />
                                                        <label for="no-change">No Change</label>
                                                    </div>
                                                </div>
                                                {errors?.invoiceamount && (
                                                    <span className="form__form-group-error">
                                                        {
                                                            errors
                                                                ?.invoiceamount
                                                                ?.message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="client-create-btns float-right">
                                            <Button
                                                color="secondary"
                                                className="cancel__btn"
                                                type="button"
                                                onClick={() => onCancel()}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                disabled={isSubmit}
                                            >
                                                {isSubmit ? (
                                                    <Spinner
                                                        size="sm"
                                                        color="secondary"
                                                        className="mr-2"
                                                    />
                                                ) : (
                                                    ""
                                                )}{" "}
                                                Submit
                                            </Button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal
                className="editBillingModal"
                show={isedit}
                onHide={() => setEdit(false)}
                size="md"
                centered
            >
                <Modal.Body className="text-center">
                    <DotsHorizontalIcon />
                    <h3 className="mt-3 mb-3">
                        <b>Edit Resulting Agent</b>
                    </h3>
                    <div className="client-registration-form-container">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormSelect
                                name="agent"
                                placeholder="Select Agent"
                                rules={{
                                    required: "This is required field",
                                }}
                                control={control}
                                options={agents_selections}
                                isOject={true}
                                objectName="name"
                                defaultValue={setAgentValue()}
                                {...register("agent")}
                                onChange={(value) => onchangeAgent2(value)}
                            />
                            <div className="client-create-btns justify-center mt-4">
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => onSubmit()}
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </form>
                    </div>
                    {/* <Button className="deleteClient-btn" onClick={()=> action(value)} >Change Outstanding Balance</Button> */}
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default reduxForm({
    form: "billing_create",
})(AddBilling);
