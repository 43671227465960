/* eslint-disable */
import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Field, reduxForm, reset } from "redux-form";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import EmailSendIcon from "mdi-react/EmailSendIcon";
import LoadingIcon from "mdi-react/LoadingIcon";
import UndoIcon from "mdi-react/UndoIcon";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import ApiServices from "@/utils/ApiServices";
import Select from "react-select";
import { SELECT_STYLES } from "@/utils/constant";
import { errorMsgCustom, successMsgCustom } from '@/utils/message_herlper';

import "./contact-style.css";
import { useEffect } from "react";

const SendEmail = (props) => {
    localStorage.setItem("account", "false");
    const [type, setType] = useState('live');

    const [countyList, setCountyList] = useState([]);
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState("custom");
    const [selectedOptionsPT, setSelectedOptionsPT] = useState([]);
    const [selectedOptionsPC, setSelectedOptionsPC] = useState([]);
    const [marketCut, setMarketCut] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getCounty();
        getPropertyTypes();
    }, []);

    const getCounty = () => {
        ApiServices.county()
            .then((result) => {
                setCountyList(result.data);
            })
            .catch(function (error) {
                console.log(error);
                alert(error.message);
            });
    };

    const getTemplate = () => {
        ApiServices.getTemplate()
            .then((result) => {
                console.log('template result --> ', result);
                setMarketCut(result.data.email_content);
            })
            .catch(function (error) {
                console.log(error);
                alert(error.message);
            });
    }

    const getPropertyTypes = () => {
        ApiServices.PropertyTypes()
            .then((result) => {
                setPropertyTypeList(result.data);
            })
            .catch(function (error) {
                console.log(error);
                alert(error.message);
            });
    };

    // Dynamic data soon
    // const countyList = ["Harris", "Fort bend", "Brazoria", "Galveston", "Montgomery", "Travis", "Waller", "Austin", "Hays", "Williamson", "Guadalupe", "Fayette", "Calhoun", "Bexar", "Rockwall", "Chambers", "Polk", "Walker", "Liberty", "Matagorda", "Grimes", "Erath", "Wharton", "Tarrant", "Brazos", "Comal", "Ector", "Collin", "Victoria county", "Hidalgo", "Wise", "Burleson", "Bell", "Dallas", "San jacinto", "Aransas", "Nueces", "Jackson", "Washington"];

    // const PropertyTypeList = ['Residential', 'Apartment/Duplex', 'Vacant Lot', 'Commercial Vacant Lots', 'Agricultural Lands', 'Commercial', 'BPP - RPT', 'BPP - Client'];

    const PropertyCities = [
        "NORTH RICHLAND HILLS",
        "CONROE",
        "DRIPPING SPRINGS",
        "Katy",
        "Missouri City",
        "MONTGOMERY",
        "PEARLAND",
        "Richmond",
        "Rosenberg",
        "Stafford",
        "Sugar Land",
        "Weston Lakes",
        "13922 KINGSRIDE LN",
        "77018",
        "ALAMO",
        "ALGOA",
        "ALVIN",
        "ANGELTON",
        "ANGLETON",
        "Austin",
        "BACLIFF",
        "BAYCLIFF",
        "BAYOU VISTA",
        "BAYTOWN",
        "BELLAIRE",
        "BELLVILLE",
        "Benbrook",
        "BLUEWATER",
        "BOLING",
        "BRAZORIA",
        "BRENHAM",
        "BROOKSHIRE",
        "BROOKSIDE",
        "CANYON LAKE",
        "CAT SPRING",
        "CHANNELVIE",
        "CHANNELVIEW",
        "CLEAR LAKE SHORES",
        "CLEVALAND",
        "CLEVELAND",
        "CLUTE",
        "COLDSPRING",
        "COLLEGE STATION",
        "CONROE",
        "CORPUS CHRISTI",
        "CROSBY",
        "CROSBY TX",
        "CRYSTAL BE",
        "CRYSTAL BEACH",
        "CYPRESS",
        "DALLAS",
        "DAMON",
        "DANBURY",
        "DAYTON",
        "DEER PARK",
        "DEVERS",
        "DICKINSON",
        "DRIPPING S",
        "DRIPPING SPRINGS",
        "EAST BERNARD",
        "FISCHER",
        "FREEPORT",
        "FRESNO",
        "FRIENDSWOD",
        "FRIENDSWOO",
        "FRIENDSWOOD",
        "FRIENDWOOD",
        "FULSHEAR",
        "GALENA PARK",
        "GALENA PK",
        "GALENAPARK",
        "GALVESTON",
        "GANADO",
        "GILCHRIST",
        "GOODRICH",
        "GREENVILL",
        "HARRIS",
        "HEMPSTEAD",
        "HIGHLANDS",
        "HITCCHCOCK",
        "HITCHCOCK",
        "HOCKLEY",
        "HOSTON",
        "HOUSOTN",
        "HOUSTN",
        "HOUSTON",
        "HOUSTON T",
        "HOUSTON,TX",
        "HUFFMAN",
        "HUMBLE",
        "HUNTESVILLE",
        "HUNTSVILLE",
        "IOWA COLONY",
        "JAMAICA BEACH",
        "JERSEY VILLAGE",
        "JONES CREEK",
        "KATY",
        "KEMAH",
        "KINGWOOD",
        "KYLE",
        "LA MARQUE",
        "LA PORTE",
        "LAKE JACKS",
        "LAKE JACKSON",
        "LAKEWAY",
        "LAPORTE",
        "LEAGUE CITY",
        "LEAGUE CIT",
        "LEAGUE CITY",
        "LEANDER",
        "LIBERTY",
        "LIVERPOOL",
        "LIVINGSTON",
        "M0NTGOMERY",
        "MAGNOLIA",
        "MANVEL",
        "MARBLE FALLS",
        "MATAGORDA",
        "Meadows Place",
        "Mesquite",
        "MISSOURI",
        "MISSOURI C",
        "MISSOURI CITY",
        "MONTGOMERY",
        "MURPHY",
        "NAVASOTA",
        "NEEDVILLE",
        "NEEDVILLE,",
        "NEW BRAUNFELS",
        "NEW CANEY",
        "New Ulm",
        "NEW YORK",
        "NONE",
        "ORCHARD",
        "OUTSIDE CITY LIMITS",
        "PANORAMA VILLAGE",
        "PASADENA",
        "PEARLAN",
        "PEARLAND",
        "PINEHURST",
        "PLANTERSVI",
        "PLANTERSVILLE",
        "POINT BLANK",
        "PORT BOLIVAR",
        "PORT O CONNOR",
        "PORTER",
        "PRAIRIE VIEW",
        "RAYWOOD",
        "RICHARDS",
        "RICHMOND",
        "RICHWOOD",
        "ROSENBERG",
        "ROSHARON",
        "SAN ANTONIO",
        "SAN LEON",
        "SANTA FE",
        "SARGENT",
        "SEABROOK",
        "SEALY",
        "SHENANDOAH",
        "SHEPHERD",
        "SHOREACRES",
        "SOMERVILLE",
        "SOUTH HOUS",
        "SOUTH HOUSTON",
        "SPICEWOOD",
        "SPLENDORA",
        "SPRING",
        "STAFFORD",
        "SUGAR LAND",
        "SUGAR LAND,",
        "SUGAR LAND, TX",
        "SUGARLAND",
        "SURFSIDE",
        "SURFSIDE BEACH",
        "SWEENEY",
        "SWEENY",
        "TAYLOR LAKE VILLAGE",
        "TEXAS CITY",
        "THE WOODLA",
        "THE WOODLANDS",
        "TIKI",
        "TIKI ISLAN",
        "TIKI ISLAND",
        "TOMBALL",
        "TX",
        "UNINCORP",
        "VAN VLECK",
        "VICTORIA",
        "WALLER",
        "WEBSTER",
        "WEST COLUM",
        "WHARTON",
        "WIILLIS",
        "WILLIS",
        "WIMBERLEY",
        "WOODLANDS",
    ];

    const PropertyStates = ["CA", "NY", "SC", "TA", "TE", "TX"];

    const emailTemplates = [
        {
            value: "custom",
            label: "Custom",
        },
        // {
        //     value: "arbitration",
        //     label: "Arbitration",
        // },
        {
            value: "market-cut",
            label: "Market Cut",
        },
        {
            value: "no-change",
            label: "No Change",
        },
        // {
        //     value: "litigation-contract",
        //     label: "Litigation Contract",
        // },
    ];

    let PTList = [];
    PTList.push({ value: "all", label: "Select All" });
    PTList.push({ value: "none", label: "Select None" });
    for (let index = 0; index < propertyTypeList.length; index++) {
        PTList.push({
            value: propertyTypeList[index].id,
            label: propertyTypeList[index].type,
        });
    }

    let CountyList = [];
    CountyList.push({ value: "all", label: "Select All" });
    CountyList.push({ value: "none", label: "Select None" });
    for (let index = 0; index < countyList.length; index++) {
        CountyList.push({
            value: countyList[index].id,
            label: countyList[index].name,
        });
    }

    const setPTSeledtedAction = (value) => {
        const selectedValue =
            Array.isArray(selectedOptionsPT) &&
            selectedOptionsPT.length != 0 &&
            value
                ? value[value.length - 1]
                : value
                ? value[0]
                : [];
        let newDAta = [];
        if (selectedValue?.value == "none") {
            newDAta = [];
        } else if (selectedValue?.value == "all") {
            newDAta = PTList.slice(2);
        } else {
            newDAta = value;
        }
        setSelectedOptionsPT(newDAta);
    };

    const setPCSeledtedAction = (value) => {
        const selectedValue =
            Array.isArray(selectedOptionsPC) &&
            selectedOptionsPC.length != 0 &&
            value
                ? value[value.length - 1]
                : value
                ? value[0]
                : [];
        let newDAta = [];
        if (selectedValue?.value == "none") {
            newDAta = [];
        } else if (selectedValue?.value == "all") {
            newDAta = CountyList.slice(2);
        } else {
            newDAta = value;
        }
        setSelectedOptionsPC(newDAta);
    };

    const handleSubmitAction = (e) => {
        e.preventDefault();
        setLoading(true);
       
        const form = e.target;
        const formData = new FormData(form);
        // Convert FormData to an object
        const formValues = Object.fromEntries(formData.entries());

        let PRResult =
            Array.isArray(selectedOptionsPT) &&
            selectedOptionsPT.map(({ value }) => value);
        let PCResult =
            Array.isArray(selectedOptionsPC) &&
            selectedOptionsPC.map(({ value }) => value);
        formValues.property_type = PRResult;
        formValues.property_county = PCResult;
        formValues.type = type;
        ApiServices.sendBatchEmail(formValues)
            .then((response) => {
                successMsgCustom("Email Sent.");
                setSelectedOptionsPT([])
                reset();
            })
            .catch((err) => {
                errorMsgCustom(
                    err.response?.data?.message ||
                        err.message ||
                        "Something went wrong"
                );
            })
            .finally(() => {
                setLoading(false);
            });
        // handleSubmit(formValues);
    };

    return (
        <Container>
            <TitleBreadCrumbs title="Batch Email">
                <div className="page-breadcrumbs">
                    <Link to="/">Home</Link>
                    <span className="current_breadcrumbs">Batch Email</span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <form
                                        className="send-email-form"
                                        onSubmit={handleSubmitAction}
                                    >
                                        <h3>Filter for Sending Email</h3>
                                        <hr />
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Property Type:
                                            </span>
                                            <div>
                                                <Select
                                                    options={PTList}
                                                    value={selectedOptionsPT}
                                                    isMulti="true"
                                                    styles={SELECT_STYLES}
                                                    onChange={(value) =>
                                                        setPTSeledtedAction(
                                                            value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="form__form-group">
                                            <span className="form__form-group-label">Property Cities:</span>
                                            <div>
                                                <Field
                                                    name="cities"
                                                    component="select"
                                                    defaultValue="none"
                                                    className="select-light-border"
                                                >
                                                    <option value="none">Nothing selected</option>
                                                    {PropertyCities.map((city) => (
                                                        <option key={city} value={city}>{city}</option>
                                                    ))}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Property States:</span>
                                            <div>
                                                <Field
                                                    name="states"
                                                    component="select"
                                                    defaultValue="none"
                                                    className="select-light-border"
                                                >
                                                    <option value="none">Nothing selected</option>
                                                    {PropertyStates.map((state) => (
                                                        <option key={state} value={state}>{state}</option>
                                                    ))}
                                                </Field>
                                            </div>
                                        </div> */}
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Property County:
                                            </span>
                                            <div>
                                                {/*
                                                <Field
                                                    name="county"
                                                    component="select"
                                                    defaultValue=""
                                                    className="select-light-border"
                                                >
                                                    <option value="">
                                                        Nothing selected
                                                    </option>
                                                    {countyList.map(
                                                        (county) => (
                                                            <option
                                                                key={county.id}
                                                                value={
                                                                    county.id
                                                                }
                                                            >
                                                                {county.name}
                                                            </option>
                                                        )
                                                    )}
                                                </Field>
                                                */}
                                                <Select
                                                    options={CountyList}
                                                    value={selectedOptionsPC}
                                                    isMulti="true"
                                                    styles={SELECT_STYLES}
                                                    onChange={(value) =>
                                                        setPCSeledtedAction(
                                                            value
                                                        )
                                                    }
                                                />                                                
                                            </div>
                                        </div>
                                        <div className="form__form-group" style={{display: 'none'}}>
                                            <span className="form__form-group-label">
                                                ZipCode:
                                            </span>
                                            <div className="form__form-group-field">
                                                {/* <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div> */}
                                                <Field
                                                    name="zipcode"
                                                    component="input"
                                                    defaultValue="text"
                                                    placeholder="ZipCode"
                                                />
                                            </div>
                                        </div>

                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Tax Savings:
                                            </span>
                                            <div
                                                className="form__form-group-field"
                                                style={{ gap: "1rem" }}
                                            >
                                                {/* <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div> */}

                                                <Field
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                    }}
                                                    name="percent_sav"
                                                    component="input"
                                                    type="range"
                                                    max={100}
                                                    defaultValue={10}
                                                    placeholder="ZipCode"
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: "1rem",
                                                        maxWidth: "6rem",
                                                    }}
                                                >
                                                    <Field
                                                        name="percent_sav"
                                                        component="input"
                                                        type="number"
                                                        defaultValue={10}
                                                    />
                                                    %
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Account Numbers:
                                            </span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="account_nums"
                                                    component="textarea"
                                                    defaultValue=""
                                                    placeholder="List of account numbers"
                                                />
                                            </div>
                                        </div>                                        

                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Template:
                                            </span>
                                            <div>
                                                <Field
                                                    name="template"
                                                    component="select"
                                                    defaultValue={
                                                        selectedTemplate
                                                    }
                                                    className="select-light-border"
                                                    onChange={(e) => {
                                                        getTemplate();
                                                        setSelectedTemplate(
                                                            e.target.options[
                                                                e.target
                                                                    .selectedIndex
                                                            ].value
                                                        );
                                                    }}
                                                >
                                                    {/* <option value="">Nothing selected</option> */}
                                                    {emailTemplates.map(
                                                        (template) => (
                                                            <option
                                                                key={
                                                                    template.value
                                                                }
                                                                value={
                                                                    template.value
                                                                }
                                                            >
                                                                {template.label}
                                                            </option>
                                                        )
                                                    )}
                                                </Field>
                                            </div>
                                        </div>

                                        <div className="form__form-group" style={{display: 'none'}}>
                                            <span className="form__form-group-label">
                                                Template:
                                            </span>
                                            <div className="form__form-group-field">
                                                <textarea 
                                                    value={marketCut}
                                                />
                                            </div>
                                        </div>                                        

                                        {selectedTemplate == 'market-cut' && (
                                        <div className="preview-cont">
                                            <h4>--- MARKET CUT PREVIEW ---</h4>
                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thank you for allowing
                                            Republic Property Tax to represent you on your _____ property tax
                                            protest. We appreciate your business and continued patronage.
                                            </p>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unfortunately, due to
                                            the exemptions on the property, we were only able to reduce the market
                                            value of the property for the _____ tax year. Since we were unable to
                                            reduce the appraised value of the property, you will not be billed for
                                            our services this year.
                                            </p>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When protesting
                                            property taxes, the county requires that all protests begin at the
                                            market value of the property. The market value does not include any
                                            exemptions on the property. It is what the county believes you could
                                            sell the property for.
                                            </p>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The appraised value,
                                            also known as the taxable value, is the market value including any
                                            exemptions the property may have. This is the value you are taxed on
                                            each year.
                                            </p>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Although we did not
                                            save you money on your tax bill this year, we did several things on
                                            your behalf in an attempt to reduce the value of the property.
                                            </p>

                                            <ol>
                                            <li>Republic Property Tax timely filed a Notice of Protest.</li>
                                            <li>
                                                Republic Property Tax conducted research on your property and
                                                created specific evidence on your behalf.
                                            </li>
                                            <li>
                                                We presented the evidence to the county in an attempt to secure a
                                                reduction in the value of your property.
                                            </li>
                                            </ol>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;While this letter may
                                            come with a bit of disappointment, we do look forward to representing
                                            you in the future. Oftentimes, when we are unable to secure a taxable
                                            reduction in one year, we are able to reduce the property's taxable
                                            value in future years.
                                            </p>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please remember that
                                            our services are recurring, and we look forward to fighting for you
                                            next year.
                                            </p>

                                            <p>
                                            Also, please notify our office 45 days from receiving this notice if
                                            you would like to discuss taking further action.
                                            </p>

                                            <p>**Note not all properties qualify.</p>                                            
                                        </div>
                                        )}

                                        {selectedTemplate == 'litigation-contract' && (
                                        <div className="preview-cont">
                                            <h4>--- LITIGATION CONTRACT PREVIEW ---</h4>
                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thank you for allowing
                                            Republic Property Tax to represent you on your _____ Property Tax
                                            Protest. We appreciate your business and continued patronage.
                                            </p>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unfortunately, we were
                                            unable to reduce the value of the property for the ______ tax year.
                                            Since we were unable to reduce the value of the property, you will not
                                            be billed for our services this year.
                                            </p>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Although we did not
                                            save you money on your tax bill this year, we did several things on
                                            your behalf in an attempt to reduce the value of the property.
                                            </p>

                                            <ol>
                                            <li>Republic Property Tax timely filed a Notice of Protest.</li>
                                            <li>
                                                Republic Property Tax conducted research on your property and
                                                created specific evidence on your behalf.
                                            </li>
                                            <li>
                                                We presented the evidence to the county in an attempt to secure a
                                                reduction on the value of your property.
                                            </li>
                                            </ol>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;While this letter may
                                            come with a bit of disappointment, we do look forward to representing
                                            you in the future. Oftentimes, when we are unable to secure a
                                            reduction in one year, we are able to reduce the property value in
                                            future years.
                                            </p>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please remember that
                                            our services are recurring, and we look forward to fighting for you
                                            next year.
                                            </p>

                                            <p>
                                            Also, please notify our office 45 days from receiving this notice if
                                            you would like to discuss taking further action.
                                            </p>

                                            <p>**Note not all properties qualify.</p>             
                                        </div>
                                        )}                                        

                                        {selectedTemplate == 'no-change' && (
                                        <div className="preview-cont">
                                            <h4>--- NO CHANGE PREVIEW ---</h4>
                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thank you for allowing
                                            Republic Property Tax to represent you on your _____ Property Tax
                                            Protest. We appreciate your business and continued patronage.
                                            </p>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unfortunately, we were
                                            unable to reduce the value of the property for the ______ tax year.
                                            Since we were unable to reduce the value of the property, you will not
                                            be billed for our services this year.
                                            </p>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Although we did not
                                            save you money on your tax bill this year, we did several things on
                                            your behalf in an attempt to reduce the value of the property.
                                            </p>

                                            <ol>
                                            <li>Republic Property Tax timely filed a Notice of Protest.</li>
                                            <li>
                                                Republic Property Tax conducted research on your property and
                                                created specific evidence on your behalf.
                                            </li>
                                            <li>
                                                We presented the evidence to the county in an attempt to secure a
                                                reduction on the value of your property.
                                            </li>
                                            </ol>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;While this letter may
                                            come with a bit of disappointment, we do look forward to representing
                                            you in the future. Oftentimes, when we are unable to secure a
                                            reduction in one year, we are able to reduce the property value in
                                            future years.
                                            </p>

                                            <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please remember that
                                            our services are recurring, and we look forward to fighting for you
                                            next year.
                                            </p>

                                            <p>
                                            Also, please notify our office 45 days from receiving this notice if
                                            you would like to discuss taking further action.
                                            </p>

                                            <p>**Note not all properties qualify.</p>             
                                        </div>
                                        )}

                                        {selectedTemplate === "custom" && (
                                            <>
                                                <h3 style={{ marginTop: 56 }}>
                                                    Email Content
                                                </h3>
                                                <hr />
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label">
                                                        Email Title:
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <Field
                                                            name="email_title"
                                                            component="input"
                                                            defaultValue="text"
                                                            placeholder="Title"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label">
                                                        Email Subject:
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <Field
                                                            name="email_subject"
                                                            component="input"
                                                            defaultValue="text"
                                                            placeholder="Subject"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label">
                                                        Email Body:
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <Field
                                                            name="email_body"
                                                            component="textarea"
                                                            defaultValue=""
                                                            placeholder="Body"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <Button
                                            onClick={() => setType("test")}
                                            color="primary"
                                            type="submit"
                                            className="search-btn"
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <LoadingIcon className="send-email-loading" />
                                            ) : (
                                                <EmailSendIcon />
                                            )}
                                            Test Send Email
                                        </Button>
                                        <div className="send-email-btns text-right">
                                            <Button
                                                className="cancel__btn"
                                                onClick={reset}
                                            >
                                                <UndoIcon /> Reset
                                            </Button>
                                            <Button
                                                onClick={() => setType("live")}
                                                color="primary"
                                                type="submit"
                                                className="search-btn"
                                                disabled={loading}
                                            >
                                                {loading ? (
                                                    <LoadingIcon className="send-email-loading" />
                                                ) : (
                                                    <EmailSendIcon />
                                                )}
                                                Send Email
                                            </Button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default reduxForm({
    form: "send_email",
    initialValues: {
        percent_sav: 0,
    },
})(SendEmail);