/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Col,
    Container,
    Card,
    CardBody,
    Button,
    Spinner,
} from "reactstrap";
import classNames from 'classnames';
import { useTranslation } from "react-i18next";
import {reduxForm } from "redux-form";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import { emailPatter, zipPattern } from '@/shared/helpers';
import AccountOutlineIcon from "mdi-react/AccountIcon";
import AlternateEmailIcon from "mdi-react/EnvelopeIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import PhoneIcon from "mdi-react/MobilePhoneIcon";
import CancelIcon from "mdi-react/CancelIcon";
import { formError } from "@/utils/message_herlper"
import { formatPhoneNumber } from "@/utils/helpers"

const EditForm = ({ onSubmit, Oncancel, isSubmit, errorList, details, county }) => {

    const { t } = useTranslation("common");
    const { handleSubmit, reset, control, errors, register, setValue, clearErrors, formState } = useForm();
    const[emailError, setEmailError] = useState([]);
    const[usernameError, setUsernameError] = useState([]);
    const[countyName, setcountyName] = useState('FORT BEND')
    const [noSubmit, setNoSubmit] = useState(0);  
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');

    useEffect(() => {
        setEmailError(errorList?.email ? errorList?.email : [])
        setUsernameError(errorList?.username ? errorList?.username  : [] )
    },[errorList]);

    useEffect(() => {
        setValue('client_first_name', details?.client_first_name)
        setValue('client_last_name', details?.client_last_name)
        setValue('client_email', details?.email)
        setValue('company_name', details?.company_name)
        setValue('homeaddress', details?.homeaddress)
        setPhone1(details?.homephone1);
        setValue('phone1', details?.homephone1)
        setValue('phone2', details?.homephone2)
        setPhone2(details?.homephone2);
        setValue('homestate', details?.homestate)
        setValue('homezip', details?.homezip)
        setValue('homecity', details?.homecity)
        setValue('homecounty', details?.homecounty)
        setValue('additionalcontactnumber', details?.additionalcontactnumber)
        setValue('additionalcontactname', details?.additionalcontactname)
        setValue('additionalcontactemail', details?.additionalcontactemail)
        setValue('accnt_percent_of_savings', details?.accnt_percent_of_savings)
    },[details])

    useEffect(() => {    
        setNoSubmit(formState?.submitCount || 0)
        if (formState.isSubmitted && Object.keys(formState?.errors).length > 0 && formState?.submitCount != noSubmit ) {
            formError()
            return
        }
    }, [formState])

    const expandClass = classNames({
        icon: true,
        expand: true,
        'expand--load': isSubmit,
    });


    const onChangePhone1 = (value) =>{
        let formatted_number = formatPhoneNumber(value)
        setPhone1(formatted_number)
    }

    const onChangePhone2 = (value) =>{
        let formatted_number = formatPhoneNumber(value)
        setPhone2(formatted_number)
    }

    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
        // const { name, value } = e.target;
        // setState((prevState) => ({
        //     ...prevState,
        //     [name]: value,
        // }));
    };

    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const onchangeCounty = (value) => {
        setValue('homecounty', value)
        clearErrors('homecounty')
    }

    function onSubmitForm(form) {
        // display form data on success
        form.homephone1 = phone1
        form.homephone2 = phone2
        onSubmit(form)
    }

 

    return (
        <Container className="client-registration-container">
            <Col md={12}>
                        <form
                            className="client-reg-form"
                            onSubmit={handleSubmit(onSubmitForm)} 
                        >
          
                            
                            <div className="mt-3 mb-3" >
                                <h4> Client Profile Information</h4>
                            </div>
                         
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                   <span className="form__form-group-label">
                                        First Name
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <AccountOutlineIcon />
                                        </div>
                                        <FormField
                                            name="client_first_name"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required: "This is required field",
                                            }}
                                            placeholder="First Name"
                                            // defaultValue={state.firstname}
                                            // onChange={handleChange}
                                        />
                                    </div>
                                    {errors?.client_first_name && <span className="form__form-group-error">{errors?.client_first_name?.message}</span>}
                                </div>
                                <div className="form__form-group">
                                   <span className="form__form-group-label">
                                        Last Name
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <AccountOutlineIcon />
                                        </div>
                                        <FormField
                                            name="client_last_name"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required: "This is required field",
                                            }}
                                            defaultValue=""
                                            placeholder="Last Name"
                                        />
                                    </div>
                                    {errors?.client_last_name && <span className="form__form-group-error">{errors?.client_last_name?.message}</span>}
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Email
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AccountOutlineIcon />
                                    </div>
                                    <FormField
                                        name="client_email"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            required: "This is required field",
                                        }}
                                        defaultValue=""
                                        placeholder="Email"
                                    />
                                </div>
                                {errors?.company_name && <span className="form__form-group-error">{errors?.company_name?.message}</span>}
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Company Name
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AccountOutlineIcon />
                                    </div>
                                    <FormField
                                        name="company_name"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        defaultValue=""
                                        placeholder="Company Name"
                                    />
                                </div>
                                {errors?.company_name && <span className="form__form-group-error">{errors?.company_name?.message}</span>}
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                   Mailing Address
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AccountOutlineIcon />
                                    </div>
                                    <FormField
                                        name="homeaddress"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            required: "This is required field",
                                        }}
                                        defaultValue=""
                                        placeholder="Mailing Address"
                                    />
                                </div>
                                {errors?.homeaddress && <span className="form__form-group-error">{errors?.homeaddress?.message}</span>}
                            </div>
                            <div className="form__form-group hide d-none">
                                <span className="form__form-group-label">
                                   Mailing Address 2
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AccountOutlineIcon />
                                    </div>
                                    <FormField
                                        name="homeaddress2"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        defaultValue=""
                                        placeholder="Mailing Address 2"
                                    />
                                </div>
                                {errors?.homeaddress2 && <span className="form__form-group-error">{errors?.homeaddress2?.message}</span>}
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                   <span className="form__form-group-label">
                                      Mailing City:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                           <MapMarkerIcon />
                                        </div>
                                        <FormField
                                            name="homecity"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required: "This is required field",
                                            }}
                                            defaultValue=""
                                            placeholder="Mailing City"
                                        />
                                    </div>
                                    {errors?.homecity && <span className="form__form-group-error">{errors?.homecity?.message}</span>}
                                </div>
                                <div className="form__form-group">
                                   <span className="form__form-group-label">
                                       Mailing State:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                           <MapMarkerIcon />
                                        </div>
                                        <FormField
                                            name="homestate"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required: "This is required field",
                                            }}
                                            defaultValue=""
                                            placeholder=" Mailing State"
                                            // value={state.firstname}
                                            // onChange={handleChange}
                                        />
                                    </div>
                                    {errors?.firstname && <span className="form__form-group-error">{errors?.firstname?.message}</span>}
                                </div>
                            </div>
                            <div className="form_two_columns">
                               <div className="form__form-group">
                                   <span className="form__form-group-label">
                                       County:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <MapMarkerIcon />
                                        </div>
                                        <FormField
                                            name="homecounty"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required: "This is required field",
                                            }}
                                            defaultValue=""
                                            placeholder="County"
                                            // value={state.firstname}
                                            // onChange={handleChange}
                                        />
                                        {/* <FormSelect
                                            name="homecounty"
                                            control={control}
                                            options={county}
                                            isOject={true}
                                            objectName="name"
                                            defaultValue={details?.county_id ? details?.county_id : 'DEFAULT'}
                                            {...register("homecounty", { required: "This is required field"})} 
                                            onChange={(value)=> onchangeCounty(value)}
                                        /> */}
                                    </div>
                                    {errors?.homecounty && <span className="form__form-group-error">{errors?.homecounty?.message}</span>}
                                </div>
                                <div className="form__form-group">
                                   <span className="form__form-group-label">
                                      Mailing Zip:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                           <MapMarkerIcon />
                                        </div>
                                        <FormField
                                            name="homezip"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                pattern: {
                                                    value: zipPattern,
                                                    message: 'Entered value does not match zip code format',
                                                },
                                            }}
                                            defaultValue=""
                                            placeholder="Mailing Zip"
                                        />
                                    </div>
                                    {errors?.homezip && <span className="form__form-group-error">{errors?.homezip?.message}</span>}
                                </div>
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                   <span className="form__form-group-label">
                                     Home Phone 1:
                                    </span>
                                    <div className="form__form-group-field">
                                    {/*
                                        <FormField
                                            name="homephone1"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required: "This is required field",
                                            }}
                                            defaultValue=""
                                            placeholder="Phone"
                                        />
                                        */}
                                        <input
                                            value={phone1}
                                            name="homephone1"
                                            onChange={(value) => onChangePhone1(value?.target?.value)}
                                        />                                                      
                                    </div>
                                    {errors?.homephone1 && <span className="form__form-group-error">{errors?.homephone1?.message}</span>}
                                </div>
                                <div className="form__form-group">
                                   <span className="form__form-group-label">
                                     Home Phone 2
                                    </span>
                                    <div className="form__form-group-field">
                                    {/*
                                        <FormField
                                            name="phone2"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            defaultValue=""
                                            placeholder="Phone 2"
                                        />
                                        */}
                                        <input
                                            value={phone2}
                                            name="homephone2"
                                            onChange={(value) => onChangePhone2(value?.target?.value)}
                                        />                                              
                                    </div>
                                    {errors?.phone2 && <span className="form__form-group-error">{errors?.phone2?.message}</span>}
                                </div>
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                    Blling Percentage
                                        </span>
                                        <div className="form__form-group-field">
                                            <FormField
                                                name="accnt_percent_of_savings"
                                                component="input"
                                                control={control}
                                                errors={errors}
                                                defaultValue=""
                                                placeholder="Blling Percentage"
                                                rules={{
                                                    required: "This is required field",
                                                }}
                                            />
                                        </div>
                                        {errors?.accnt_percent_of_savings && <span className="form__form-group-error">{errors?.accnt_percent_of_savings?.message}</span>}
                                    </div>
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                   <span className="form__form-group-label">
                                       Additional Contact Name:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <AccountOutlineIcon />
                                        </div>
                                        <FormField
                                            name="additionalcontactname"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            defaultValue=""
                                            placeholder="Additional Contact Name:"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {errors?.additionalcontact && <span className="form__form-group-error">{errors?.additionalcontact?.message}</span>}
                                </div>
                                <div className="form__form-group">
                                   <span className="form__form-group-label">
                                      Additional Contact Number:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                           <PhoneIcon />
                                        </div>
                                        <FormField
                                            name="additionalcontactnumber"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            defaultValue=""
                                            placeholder="Phone 2"
                                        />
                                    </div>
                                    {errors?.additionalcontact && <span className="form__form-group-error">{errors?.additionalcontact?.message}</span>}
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                   Additional Contact Email:
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                       <AlternateEmailIcon />
                                    </div>
                                    <FormField
                                        name="additionalcontactemail"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            pattern: {
                                               value: emailPatter,
                                               message: 'Entered value does not match email format',
                                            }
                                        }}
                                        defaultValue=""
                                        placeholder="Additional Contact Email"
                                    />
                                </div>
                                {errors?.additionalcontactemail && <span className="form__form-group-error">{errors?.additionalcontactemail?.message}</span>}
                            </div>
                            
                            <div className="client-create-btns">
                                <Button
                                    color="secondary"
                                    className="cancel__btn"
                                    onClick={()=> Oncancel()}
                                >
                                     Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className={isSubmit ? expandClass : ''}
                                    disabled={isSubmit}
                                >
                                    {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}
                                    Save Changes
                                </Button>
                            </div>
                        </form>
            </Col>
        </Container>
    );
};

EditForm.propTypes = {
    isHorizontal: PropTypes.bool,
};

EditForm.defaultProps = {
    isHorizontal: false,
};



export default reduxForm({
    form: "client_registration",
  })(EditForm);