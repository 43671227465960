/* eslint-disable */
import React, { useEffect, useMemo, useState, useRef } from "react";
import queryString from "query-string";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Spinner,
} from "reactstrap";
import moment from "moment"
import DatePicker from "react-datepicker";
import { reduxForm } from "redux-form";
import { Link,  useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import TableLoading from "@/components/TableLoading";
import ApiServices from "@/utils/ApiServices"
import { TABLE_SETTINGS, API } from "@/utils/constant";
import { globalError } from "@/utils/message_herlper";
import Empty from "@/components/Empty";
import "react-datepicker/dist/react-datepicker.css";

const NotInvoice = (props) => {
    const history = useHistory()
    const location = useLocation()
    const [isSubmit, setSubmit] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [year, setYear] = useState(null);
    const yearRef = useRef(null);
    const [startDate, setStartDate] = useState(new Date());
    const dataTable = [];
    const list = data;
    let not_invoice  = 0;
    let all_properties  = 0;

    useEffect(() => {  
        if(props?.match?.params?.year){
            setYear(props?.match?.params?.year)
            yearRef.current = parseInt(props?.match?.params?.year);
            fetchData(1);
        }else{
            setYear(null)
            yearRef.current = null
        }
    }, [location]);

  
    const fetchData = async (
        page,
        size = perPage,
        year = yearRef.current
    ) => {
        setData([]);
        ApiServices.notInvoiceRerport({
            page: page,
            per_page: size,
            year: year,
        })
            .then((result) => {  
                let details = result?.data;
                setData(result.data.data);
                setTotalRows(result.data.total);
                setLoading(false);
            })
            .catch((error) => {
                globalError();
            });
    };

    const handlView  =  data  => {
        history.push({ pathname: `/reports/not-invoiced-properties/${data?.county_id}/${year}/${data?.name}`})
    }

    const onSubmit = (event) => {
        event.preventDefault()
        let year = parseInt(moment(startDate).format('YYYY'));
        history.push({ pathname: `/reports/not-invoiced/${year}`})
    };

    const handlePageChange = (page) => {
        fetchListing(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchListing(page, newPerPage);
        setPerPage(newPerPage);
    };

    const columns = useMemo(() => [
        {
            name: "County Name ",
            selector: "name",
            sortable: false,
            center: true,
        },
        {
            name: "Total Properties	",
            selector: "all_properties",
            sortable: false,
            center: true,
        },
        {
            name: "Total Properties Not Invoiced",
            selector: "tpni",
            sortable: false,
            center: true,
        },
        {
            name: "Properties Not Invoiced",
            selector: "pni",
            sortable: false,
            center: true,
        },
        {
            center: true,
            cell: (row) => (
                <Button  className="my-0 primary-2 white-nowrap" color="primary"  onClick={()=> handlView(row)}>View Properties</Button>
            ),
        },
    ]);

    if(Array.isArray(list)){
        for (let index = 0; index < list.length; index++) {  
            dataTable.push({
                county_id: list[index].county_id,
                name: list[index].name,
                all_properties: list[index].all_properties,
                tpni: list[index].not_invoice,
                pni: ((list[index].not_invoice/list[index].all_properties) * 100).toFixed(2) + ' %' ,
            });
            not_invoice+=list[index].not_invoice
            all_properties+=list[index].all_properties
        }
    }

    const exportReport = (type) => {
        window.open(`${API}report/not-invoice-export/${yearRef.current}/${type}`, '_blank');
        // setSubmit(true);
        // ApiServices.notInvoiceExport({ year: yearRef.current })
        //     .then((result) => { 
        //         setTimeout(() => {
        //             setSubmit(false);
        //             const url = window.URL.createObjectURL(
        //                 new Blob([result.data])
        //             );
        //             const link = document.createElement("a");
        //             link.href = url;
        //             const fileName = `Not Invoiced Report for Year ${yearRef.current}.csv`;
        //             link.setAttribute("download", fileName);
        //             document.body.appendChild(link);
        //             link.click();
        //             link.remove();
        //         }, 1000);
        //     })
        //     .catch((error) => {
        //         setSubmit(false);
        //         globalError();
        //     });
    };

    const goBack = () => {
        history.push({ pathname: `/reports/not-invoiced`})
    };

    return (
        <Container>
            <TitleBreadCrumbs title="Properties Not Invoiced">
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="#">Reports</Link>
                    {!year && (
                        <>
                             <span className="current_breadcrumbs"> Not Invoiced</span>
                        </>
                    )}
                    
                    {year  && (
                        <>
                            <Link onClick={() => goBack()} to="#">
                               Not Invoiced
                            </Link>
                            <span className="current_breadcrumbs">Result</span>
                        </>
                    )}
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} className="edit-county-wrap">
                                    {!year ? (
                                        <>
                                            <form
                                                className="client-reg-form"
                                                onSubmit={onSubmit}
                                            >
                                                <div className="form__form-group zipcode_wrapper">
        
                                                    <div className="form__form-group-label mt-2">
                                                        Select Tax Year:
                                                    </div>
                                                    <div className="report-fields">
                                                       {/* <YearPicker onChange={handleChange}     /> */}
                                                       <DatePicker
                                                            selected={startDate}
                                                            showYearPicker
                                                            dateFormat="yyyy"
                                                            // yearItemNumber={9}
                                                            onChange={(date) => setStartDate(date)}
                                                            className="date-picker"
                                                            maxDate={new Date()}
                                                        />
                                                    </div>
                                                    <div className="edit-county-btns">
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            onClick={onSubmit}
                                                        >
                                                            Run Report
                                                        </Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    ) : (
                                        <>
                                            <div className="text-center mb-4">
                                               <h3>Properties Not Invoiced For {yearRef.current}</h3>
                                               <b style={{ fontSize: 15, fontWeight: 900 }}>Total Percentage of Properties Not Invoiced for The Year {yearRef.current}:</b> {((not_invoice/all_properties) * 100 || 0).toFixed(2)} %
                                            </div>

                                            <table style={{'width': '100%'}}>
                                            <thead>
                                                <tr>
                                                    <td>County Name</td>
                                                    <td>Total Properties</td>
                                                    <td>Total Properties Not Invoiced</td>
                                                    <td>Properties Not Invoiced</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                dataTable.map((i,k) => 
                                                    <tr>
                                                        <td>{i.name}</td>
                                                        <td>{i.all_properties}</td>
                                                        <td>{i.tpni}</td>
                                                        <td>{i.pni}</td>
                                                        <td><Button  className="my-0 primary-2 white-nowrap" color="primary"  onClick={()=> handlView(i)}>View Properties</Button></td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                            </table>  

                                            <div style={{'display': 'none'}}>
                                            <DataTable
                                                columns={columns}
                                                data={dataTable}
                                                progressPending={loading}
                                                progressComponent={
                                                    <TableLoading />
                                                }
                                                paginationTotalRows={totalRows}
                                                paginationDefaultPage={
                                                    currentPage
                                                }
                                                striped={TABLE_SETTINGS.STRIPED}
                                                highlightOnHover={true}
                                                onChangeRowsPerPage={
                                                    handlePerRowsChange
                                                }
                                                onChangePage={handlePageChange}
                                                onSelectedRowsChange={({
                                                    selectedRows,
                                                }) => console.log(selectedRows)}
                                                paginationPerPage={data.length}
                                                noDataComponent={<Empty />}
                                            />
                                            </div>
                                          
                                            {
                                                Array.isArray(data) && data.length > 0 ?
                                                    <div className="text-center mt-5">
                                                         <Button
                                                            onClick={() =>
                                                                exportReport('excel')
                                                            }
                                                            disabled={isSubmit}
                                                        >
                                                            {isSubmit ? (
                                                                <Spinner
                                                                    size="sm"
                                                                    color="secondary"
                                                                    className="mr-2"
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                            Export to Excel
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                exportReport('pdf')
                                                            }
                                                            disabled={isSubmit}
                                                        >
                                                            {isSubmit ? (
                                                                <Spinner
                                                                    size="sm"
                                                                    color="link"
                                                                    className="btn-success"
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                            Export to PDF
                                                        </Button>
                                                    </div>
                                                :''
                                            }
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default NotInvoice;