/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Spinner, Tooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import { zipPattern } from "@/shared/helpers";
import AccountOutlineIcon from "mdi-react/AccountIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import CancelIcon from "mdi-react/CancelIcon";
import classNames from "classnames";
import Alert from "@/shared/components/Alert";
import { formError } from "@/utils/message_herlper";
import { USERLEVELS } from "@/utils/constant";
import PaperClip from "@/images/paperClip";
import ModalUpload from "@/components/ModalUploadHomepage";
import PropertyDescriptionDocs from "@/components/propertyDescriptionDoc";
import CameraIcon from "@/images/cameraIcon";
import InfoIcon from "@/images/infoIcon";
import APIService from "@/utils/ApiServices";

const CreateForm = ({
  onSubmit,
  isSubmit,
  errorList,
  propertyType,
  county,
  onCancel,
  owner_name,
  setPurchasedDocs2,
  setMajorDMDdocs2,
  setRemodelDocs2,
  exemptions,
}) => {
  const { t } = useTranslation("common");
  const {
    handleSubmit,
    reset,
    control,
    errors,
    register,
    setValue,
    clearErrors,
    formState,
    setError,
  } = useForm();
  const [emailError, setEmailError] = useState([]);
  const [usernameError, setUsernameError] = useState([]);
  const [noSubmit, setNoSubmit] = useState(0);
  const [docType, setDocType] = useState("1");
  const [type, setType] = useState(1);
  const [show, setShow] = useState(false);
  const [purchasedDocs, setPurchasedDocs] = useState([]);
  const [majorDMGdocs, setMajorDMDdocs] = useState([]);
  const [remodelDocs, setRemodelDocs] = useState([]);
  const [questionDoc, setQuestionDoc] = useState(1);
  const [currCounty, setCurrCounty] = useState(null);
  const [document1, set] = useState(null);
  const [majorChanges, setMajorChanges] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [submitDocument, setSubmitDocument] = useState(false);
  const [remodelUpgrades, setRemodelUpgrades] = useState(false);
  const [addLabel, setAddLabel] = useState("files");
  const [purchasedTwoYears, setPurchasedTwoYears] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [accountnumner, setAccountNumber] = useState('');
  const [isCheckingAccNum, setCheckingAccNum] = useState(false);
  const accountNumInputTimer = useRef(null);

  useEffect(() => {
    setEmailError(errorList?.email ? errorList?.email : []);
    setUsernameError(errorList?.username ? errorList?.username : []);
  }, [errorList]);

  useEffect(() => {
    if(isCheckingAccNum) {
      setError(
        'account_number_checking', 
        { 
          type: "server side",
          message: "Validating account number" 
        }
      );
    } else {
      clearErrors("account_number_checking");
    }
  }, [isCheckingAccNum]);

  useEffect(() => {
    if (owner_name) {
      setValue("owner_name", owner_name);
    }
    setValue("property_state", "TX");
  }, [owner_name]);

  const expandClass = classNames({
    icon: true,
    expand: true,
    "expand--load": isSubmit,
  });

  const [state, setState] = useState({
    owner_name: "",
    property_address: "",
    property_address_2: "",
    property_type: "",
    property_city: "",
    property_state: "",
    county: "",
    property_zip: "",
    account_number: "",
    legal_desc: "",
    txtdescription: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const onchangeCounty = (value) => {
    setValue("county", value);
    setCurrCounty(value);
    clearErrors("county");
  };

  const onchangeType = (value) => {
    setValue("property_type", value);
    clearErrors("property_type");
  };

  const onchangeExemption = (value) => {
    setValue("exemption_type", value);
    clearErrors("exemption_type");
  };

  useEffect(() => {
    setNoSubmit(formState?.submitCount || 0);
    if (
      formState.isSubmitted &&
      Object.keys(formState?.errors).length > 0 &&
      formState?.submitCount != noSubmit
    ) {
      formError();
      return;
    }
  }, [formState]);

  const handlePutfiles = (file) => {
    if (questionDoc === 1) {
      const newpurchasedDocs = [...purchasedDocs];
      for (let index = 0; index < file.length; index++) {
        newpurchasedDocs.push(file[index]);
      }
      setPurchasedDocs(newpurchasedDocs);
      setPurchasedDocs2(newpurchasedDocs);
    } else if (questionDoc === 2) {
      const newMajorDocs = [...majorDMGdocs];
      for (let index = 0; index < file.length; index++) {
        newMajorDocs.push(file[index]);
      }
      setMajorDMDdocs(newMajorDocs);
      setMajorDMDdocs2(newMajorDocs);
    } else {
      const newRemodelDocs = [...remodelDocs];
      for (let index = 0; index < file.length; index++) {
        newRemodelDocs.push(file[index]);
      }
      setRemodelDocs(newRemodelDocs);
      setRemodelDocs2(newRemodelDocs);
    }
  };

  const onSubmitDocument = (file) => {
    setSubmitDocument(true);
    const data = new FormData();
    data.append("id", id);
    data.append("file", file.file);
    data.append("type", file.type);
    if (file.type === 1) {
      setSubmitGeneral(true);
    }

    ApiServices.uploadDocument(data)
      .then((result) => {
        setSubmitGeneral(false);
        if (file.type === 1) {
          propertyDocuments(1);
        }

        setTimeout(() => {
          setSubmitDocument(false);
        }, 1500);
      })
      .catch((error) => {
        setSubmitGeneral(false);
        setSubmitDocument(false);
        globalError();
      });
  };

  const deleteDocument = (id, doc) => {
    if (doc === 1) {
      let temp = purchasedDocs.filter((file, index) => index !== id);
      setPurchasedDocs(temp);
    } else if (doc === 2) {
      let temp = majorDMGdocs.filter((file, index) => index !== id);
      setMajorDMDdocs(temp);
    } else {
      const temp = remodelDocs.filter((file, index) => index !== id);
      setRemodelDocs(temp);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  function submitForm(form){
    form.purchase_two_years = purchasedTwoYears ? 1 : 0
    form.property_major_changes = majorChanges ? 1 : 0
    form.remodels_three_years = remodelUpgrades ? 1 : 0
    onSubmit(form)
  }

  function numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
}

const onChangeAccount = (value) =>{  
  // var formatted = value.replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3');
  //https://www.titanwolf.org/Network/q/ec40b666-95d2-46a7-bfde-fd59b0941455/y
   if(currCounty == 1 && value.length == 13) { //for harris only
       let formatted = value.match(/\d{3}(?=\d{2,3})|\d+/g).join("-")
       setAccountNumber(formatted);
       setValue("account_number", formatted);
   } else {
       setAccountNumber(value);
       setValue("account_number", value);
   }

   
   clearErrors("account_number");   

   handleAccountNumberCheking(value);
}

const handleAccountNumberCheking = (value) => {
  if(accountNumInputTimer.current) clearInterval(accountNumInputTimer.current);
  if(!value) return clearErrors("account_number_checking");;
  
  accountNumInputTimer.current = setTimeout(async () => {
    try {
      setCheckingAccNum(true);
      const result = await APIService.propertyAccountNumberExists({
        account_number: value,
      })
      setCheckingAccNum(false);
      clearErrors("account_number_checking");

      if(result.data.isExist) {
        setError(
          'account_number_checking', 
          { 
            type: "server side",
            message: "Property with the same account number already exists." 
          }
        );
      } else {
        clearErrors("account_number_checking");
      }
      
    } catch (err) {
      setError(
        'account_number_checking', 
        { 
          type: "server side",
          message: 'Error on checking account number: ' + err.message,
        }
      );
    }
  }, 600);
};
  

  return (
    <>
      <ModalUpload
        open={show}
        close={handleClose}
        type={type}
        uploadFiles={(files) => handlePutfiles(files)}
        is_submitDocument={submitDocument}
        docuType={docType}
        addLabel={addLabel}
      />
      <form
        className="client-reg-form add-property-form"
        onSubmit={handleSubmit(submitForm)}
      >
        {emailError.length > 0 &&
          emailError.map((obj, i) => {
            return (
              <Alert color="danger">
                <p>{obj}</p>
              </Alert>
            );
          })}
        {usernameError.length > 0 &&
          usernameError.map((obj, i) => {
            return (
              <Alert color="danger">
                <p>{obj}</p>
              </Alert>
            );
          })}
        <h4>Property Information</h4>
        <div
          className={`${
            userData.userlevel !== USERLEVELS.ADMIN ? "d-none" : ""
          } form__form-group`}
        >
          <span className="form__form-group-label">Owner Name</span>
          <div className="flex-input">
            <div className="input-1">
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <FormField
                  name="owner_name"
                  component="input"
                  control={control}
                  errors={errors}
                  rules={{
                    required: "This is required field",
                  }}
                  placeholder="Owner Name"
                  onChange={handleChange}
                  defaultValue={`${
                    userData.userlevel !== USERLEVELS.ADMIN ? userData.name : ""
                  }`}
                />
              </div>
            </div>
            <div>
              {errors?.owner_name && (
                <span className="form__form-group-error">
                  {errors?.owner_name?.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">County:</span>
          <div className="flex-input">
            <div className="input-1">
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <MapMarkerIcon />
                </div>
                <FormSelect
                  name="county"
                  control={control}
                  options={county}
                  isOject={true}
                  objectName="name"
                  placeholder="Select Country"
                  defaultValue={"DEFAULT"}
                  {...register("county", {
                    required: "This is required field",
                  })}
                  onChange={(value) => onchangeCounty(value)}
                />
              </div>
            </div>
            <div>
              {errors?.county && (
                <span className="form__form-group-error">
                  {errors?.county?.message}
                </span>
              )}
            </div>
          </div>
        </div>
        {userData.userlevel === USERLEVELS.ADMIN ? (
          <>
            <div className="form__form-group">
              <span className="form__form-group-label">Property Type:</span>
              <div className="flex-input">
                <div className="input-1">
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <MapMarkerIcon />
                    </div>
                    <FormSelect
                      name="property_type"
                      placeholder="Select Property Type"
                      defaultValue={"DEFAULT"}
                      rules={{
                        required: "This is required field",
                      }}
                      control={control}
                      options={propertyType}
                      isOject={true}
                      objectName="type"
                      {...register("property_type", {
                        required: "This is required field",
                      })}
                      onChange={(value) => onchangeType(value)}
                    />
                  </div>
                </div>
                <div>
                  {errors?.property_type && (
                    <span className="form__form-group-error">
                      {errors?.property_type?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="form__form-group">
          <span className="form__form-group-label">Exemption Type:</span>
          <div className="flex-input">
            <div className="input-1">
              <div className="form__form-group-field">
                {/* <div className="form__form-group-icon">
                  <MapMarkerIcon />
                </div> */}
                <FormSelect
                  name="exemption_type"
                  placeholder="Select Exemption Type"
                  defaultValue={"DEFAULT"}
                  // rules={{
                  //   required: "This is required field",
                  // }}
                  control={control}
                  options={exemptions}
                  isOject={true}
                  objectName="title"
                  {...register("exemption_type")}
                  onChange={(value) => onchangeExemption(value)}
                  className={errors?.exemption_type ? "error-field" : ""}
                />
              </div>
            </div>
            <div>
              {errors?.exemption_type && (
                <span className="form__form-group-error">
                  {errors?.exemption_type?.message}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">Property Address:</span>
          <div className="flex-input">
            <div className="input-1">
              <div className="form__form-group-field">
                <FormField
                  name="property_address"
                  component="input"
                  control={control}
                  errors={errors}
                  placeholder="Property Address"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              {errors?.property_address && (
                <span className="form__form-group-error">
                  {errors?.property_address?.message}
                </span>
              )}
            </div>
          </div>
        </div>
        {userData.userlevel === USERLEVELS.ADMIN ? (
          <>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Property Address 2:
              </span>
              <div className="flex-input">
                <div className="input-1">
                  <div className="form__form-group-field">
                    <FormField
                      name="property_address_2"
                      component="input"
                      control={control}
                      errors={errors}
                      placeholder="Property Address"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  {errors?.property_address_2 && (
                    <span className="form__form-group-error">
                      {errors?.property_address_2?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="form__form-group">
          <span className="form__form-group-label">Property City:</span>
          <div className="flex-input">
            <div className="input-1">
              <div className="form__form-group-field">
                <FormField
                  name="property_city"
                  component="input"
                  control={control}
                  errors={errors}
                  placeholder="Property City"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              {errors?.property_city && (
                <span className="form__form-group-error">
                  {errors?.property_city?.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Property State:</span>
          <div className="flex-input">
            <div className="input-1">
              <div className="form__form-group-field">
                <FormField
                  name="property_state"
                  component="input"
                  control={control}
                  errors={errors}
                  placeholder="Property State"
                  onChange={handleChange}
                  disabled={true}
                />
              </div>
            </div>
            <div>
              {errors?.property_state && (
                <span className="form__form-group-error">
                  {errors?.property_state?.message}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">Property Zip:</span>
          <div className="flex-input">
            <div className="input-1">
              <div className="form__form-group-field">
                <FormField
                  name="property_zip"
                  component="input"
                  control={control}
                  errors={errors}
                  rules={{
                    pattern: {
                      value: zipPattern,
                      message: "Entered value does not match zip code format",
                    },
                  }}
                  placeholder="Zip"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              {errors?.property_zip && (
                <span className="form__form-group-error">
                  {errors?.property_zip?.message}
                </span>
              )}
            </div>
          </div>
        </div>
        {userData.userlevel === USERLEVELS.ADMIN ? (
          <>
            <div className="form__form-group">
              <span className="form__form-group-label">Account Number:</span>
              <div className="flex-input">
                <div className="input-1">
                  <div className="form__form-group-field">
                      <input
                        value={accountnumner}
                        {...register("account_number")}
                        onChange={(value) => onChangeAccount(value?.target?.value)}
                    />
                    {/* <FormField
                      name="account_number"
                      component="input"
                      control={control}
                      errors={errors}
                      placeholder="Account Number"
                      onChange={handleChange}
                    /> */}
                  </div>
                </div>
                <div>
                  {errors?.account_number && (
                    <span className="form__form-group-error">
                      {errors?.account_number?.message}
                    </span>
                  )}
                  {errors?.account_number_checking && (
                    <span className="form__form-group-error blink">
                      {errors?.account_number_checking?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="form__form-group"></div>
        {userData.userlevel === USERLEVELS.ADMIN && 
        <div className="form__form-group">
          <span className="form__form-group-label">
            Property Legal Description
          </span>
          <div className="form__form-group-field">
            <FormField
              name="legal_desc"
              component="textarea"
              control={control}
              errors={errors}
              placeholder="Property Legal Description"
              onChange={handleChange}
              rows={4}
            />
          </div>
          {errors?.legal_desc && (
            <span className="form__form-group-error">
              {errors?.legal_desc?.message}
            </span>
          )}
        </div>
        }
        {/* {userData.userlevel === USERLEVELS.ADMIN &&
          <> */}
            <div className="form__numbered_options">
              <span className="form__number_label">1</span>
              <div className="form__options_container">
                <div className="form__options_label">
                  Have you purchased this property within the last two years?
                </div>
                <div className="form__options_wrapper">
                  <label>
                    <FormField
                      name="purchase_two_years"
                      component="input"
                      type="radio"
                      control={control}
                      errors={errors}
                      defaultValue="1"
                      value="1"
                      defaultChecked={purchasedTwoYears ? true : null}
                      onClick={(e) => {
                        setPurchasedTwoYears(true);
                        e.target.value = "1";
                      }}
                    />
                    Yes
                  </label>
                  <label>
                    <FormField
                      name="purchase_two_years"
                      component="input"
                      type="radio"
                      control={control}
                      errors={errors}
                      defaultValue="0"
                      value="0"
                      defaultChecked={!purchasedTwoYears ? true : null}
                      //defaultChecked={ details?.purchased_within_two_years ? null : true }
                      onClick={(e) => {
                        setPurchasedTwoYears(false);
                        e.target.value = "0";
                      }}
                    />
                    No
                  </label>
                </div>
                {purchasedTwoYears && (
                  <div className="form__optional_upload_container">
                    <label>
                      <Button
                        onClick={() => {
                          setShow(true);
                          setType(1);
                          setDocType("1");
                          setAddLabel("documents");
                          setQuestionDoc(1);
                        }}
                        color="secondary"
                        className="secondary-2"
                      >
                        <PaperClip /> Add documents
                      </Button>
                    </label>
                    {/* <span onHover={toggle} className="form__custom_tooltip">
                      <a id="diclosure_tooltip">i</a>
                    </span>

                    <Tooltip
                      placement="top-start"
                      isOpen={tooltipOpen}
                      popperClassName="custom-tooltip-popper"
                      className="custom-tooltip"
                      target="diclosure_tooltip"
                      arrowClassName="custom-tooltip-arrow"
                      toggle={toggle}
                    >
                      The Closing Disclosure is a five-page form that a lender
                      provides to a home buyer at least 3 business days before
                      their loan closes. It outlines the final terms and costs
                      of the mortgage.
                    </Tooltip> */}
                  </div>
                )}
              </div>
            </div>
            <div className="property-desc-home mb-3">
              {purchasedDocs.map((obj, i) => {
                return (
                  <React.Fragment key={i}>
                    {" "}
                    <PropertyDescriptionDocs
                      name={obj.name}
                      onDelete={() => deleteDocument(i, 1)}
                    />
                  </React.Fragment>
                );
              })}
            </div>
            <div className="form__numbered_options mt-3">
              <span className="form__number_label">2</span>
              <div className="form__options_container">
                <div className="form__options_label">
                  Does the property have any major damage?
                  <br />{" "}
                  <i>
                    (i.e. foundation issues, roof damage, cracks in the exterior
                    or interior walls, etc.)
                  </i>
                </div>
                <div className="form__options_wrapper">
                  <label>
                    <FormField
                      name="property_major_changes"
                      component="input"
                      type="radio"
                      control={control}
                      errors={errors}
                      defaultValue="1"
                      value="1"
                      defaultChecked={majorChanges ? true : null}
                      onClick={(e) => {
                        setMajorChanges(true);
                        e.target.value = "1";
                      }}
                    />
                    Yes
                  </label>
                  <label>
                    <FormField
                      name="property_major_changes"
                      component="input"
                      type="radio"
                      control={control}
                      errors={errors}
                      defaultValue="0"
                      value="0"
                      defaultChecked={!majorChanges ? true : null}
                      onClick={(e) => {
                        setMajorChanges(false);
                        e.target.value = "0";
                      }}
                    />
                    No
                  </label>
                </div>
                {majorChanges && (
                <div className="form__conditional_field_container">
                  <div className="form__conditional_field-label">
                    Description
                  </div>
                  <div className="form__conditional_field-wrapper">
                    <FormField
                      name="major_dmg_description"
                      component="textarea"
                      control={control}
                      errors={errors}
                      placeholder="Description"
                    />
                    {majorDMGdocs.map((obj, i) => {
                      return (
                        <>
                          <React.Fragment key={i}>
                            {" "}
                            <PropertyDescriptionDocs
                              index={i}
                              docuType="1"
                              id={i}
                              status={obj?.status}
                              name={obj?.name}
                              file_type={obj?.type}
                              onDelete={() => deleteDocument(i, 2)}
                              onStatus={(data) => onStatus(data)}
                            />
                          </React.Fragment>
                        </>
                      );
                    })}
                    <div className="form__conditional_field-btns pt-2">
                      <Button
                        onClick={() => {
                          setDocType("2");
                          setShow(true);
                          setType(1);
                          setAddLabel("photos");
                          setQuestionDoc(2);
                        }}
                        color="secondary"
                        className="secondary-2"
                      >
                        <CameraIcon /> Add photos
                      </Button>
                      <Button
                        onClick={() => {
                          setDocType("1");
                          setShow(true);
                          setType(1);
                          setAddLabel("documents");
                          setQuestionDoc(2);
                        }}
                        color="secondary"
                        className="secondary-2"
                      >
                        <PaperClip /> Add documents
                      </Button>
                      {/* <span onHover={toggle} className="docu-tooltip">
                        <a id="desc_tooltip">
                          <InfoIcon color="#383938" />
                        </a>
                      </span>
                      <Tooltip
                        placement="top-start"
                        isOpen={tooltipOpen2}
                        target="desc_tooltip"
                        popperClassName="custom-tooltip-popper"
                        className="custom-tooltip"
                        arrowClassName="custom-tooltip-arrow"
                        toggle={toggle2}
                      >
                        The Closing Disclosure is a five-page form that a lender
                        provides to a home buyer at least 3 business days before
                        their loan closes. It outlines the final terms and costs
                        of the mortgage.
                      </Tooltip> */}
                    </div>
                  </div>
                </div>
              )}
              </div>
            </div>
            <div className="form__numbered_options mt-3">
              <span className="form__number_label">3</span>
              <div className="form__options_container">
                <div className="form__options_label">
                  Have you done any remodels or upgrades to the property within
                  the last three years?
                </div>
                <div className="form__options_wrapper">
                  <label>
                    <FormField
                      name="remodels_three_years"
                      component="input"
                      type="radio"
                      control={control}
                      errors={errors}
                      defaultValue="1"
                      value="1"
                      defaultChecked={remodelUpgrades ? true : null}
                      onClick={(e) => {
                        setRemodelUpgrades(true);
                        e.target.value = "1";
                      }}
                    />
                    Yes
                  </label>
                  <label>
                    <FormField
                      name="remodels_three_years"
                      component="input"
                      type="radio"
                      control={control}
                      errors={errors}
                      defaultValue="0"
                      value="0"
                      defaultChecked={!remodelUpgrades ? true : null}
                      onClick={(e) => {
                        setRemodelUpgrades(false);
                        e.target.value = "0";
                      }}
                    />
                    No
                  </label>
                </div>
                {remodelUpgrades && (
                  <div className="form__conditional_field_container">
                    <div className="form__conditional_field-label">
                      Description of the remodel or upgrades
                    </div>
                    <div className="form__conditional_field-wrapper">
                      <FormField
                        name="remodel_description"
                        component="textarea"
                        control={control}
                        errors={errors}
                        placeholder="Description of the remodel or upgrades"
                      />
                      {remodelDocs.map((obj, i) => {
                        return (
                          <React.Fragment key={i}>
                            {" "}
                            <PropertyDescriptionDocs
                              index={i}
                              docuType="1"
                              id={i}
                              status={obj?.status}
                              name={obj?.name}
                              file_type={obj?.type}
                              onDelete={() => deleteDocument(i, 3)}
                              onStatus={(data) => onStatus(data)}
                            />
                          </React.Fragment>
                        );
                      })}
                      <div className="form__conditional_field-btns pt-2">
                        <Button
                          onClick={() => {
                            setShow(true);
                            setType(1);
                            setDocType("1");
                            setAddLabel("documents");
                            setQuestionDoc(3);
                          }}
                          color="secondary"
                          className="secondary-2 mb-0"
                        >
                          <PaperClip /> Add documents
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          {/* </>
        } */}
        <div className="client-create-btns mt-3">
          <Button
            type="button"
            color="secondary"
            className="cancel__btn"
            onClick={onCancel}
          >
            <CancelIcon /> Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            className={isSubmit ? expandClass : ""}
            disabled={isSubmit}
          >
            {isSubmit ? (
              <Spinner size="sm" color="secondary" className="mr-2" />
            ) : (
              ""
            )}
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

CreateForm.propTypes = {
  isHorizontal: PropTypes.bool,
};

CreateForm.defaultProps = {
  isHorizontal: false,
};

export default reduxForm({
  form: "client_registration",
})(CreateForm);
