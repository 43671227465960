/* eslint-disable */
import React from "react";
import { Spinner } from "reactstrap";

const TableLoading = ({onChange, placeholder}) => {
    return (
        <div className="table-loading">
            <Spinner size="lg" color="warning" />
            <p>Loading...</p>
        </div>
    );
};

export default TableLoading;
