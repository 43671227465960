/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { useForm } from "react-hook-form";
import ApiServices from "@/utils/ApiServices"
import { successMsg, globalError, formError } from "@/utils/message_herlper"
import { USERLEVEL, USERSTATUS, list2FA } from "@/utils/constant";
import { FormField, FormSelect } from "@/shared/components/FormField";
import { emailPatter, zipPattern } from '@/shared/helpers';
import { Spinner, Button } from 'reactstrap';

const EditForm = (props) => {
    const location = useLocation();
    const { handleSubmit, reset, control, errors, register, setValue, clearErrors } = useForm();
    const [cookies, setCookie] = useCookies(['name']);
    const [county, setCounty] = useState([]);
    const [isSubmit, setSubmit] = useState(false);

    useEffect(() => {  
        getCounty();
        //parseInt(cookies.userID)
        if(cookies?.userDetails){
            let details = cookies?.userDetails   
            setValue('email', details?.email)
            setValue('client_first_name', details?.client_first_name)
            setValue('client_last_name', details?.client_last_name)
            setValue('company_name', details?.company_name)
            setValue('homeaddress', details?.homeaddress)
            setValue('homephone1', details?.homephone1)
            setValue('homephone2', details?.homephone2)
            setValue('homestate', details?.homestate)
            setValue('homezip', details?.homezip)
            setValue('homecity', details?.homecity)
            setValue('homecounty', details?.county_id)
            setValue('user_2FA', details?.user_2FA)
        }
        
     },[props]);

     

    const getCounty = () =>{
        ApiServices.county()
        .then((result) => {   
            setCounty(result.data);
        })
        .catch(function (error) {
        });
    }

    const onSubmit  = (form) => {
        console.log('on submit', form);
        setSubmit(true)
        form.id = cookies?.userID
        ApiServices.editProfile(form)
        .then((result) => {
           setTimeout(()=> {
                setSubmit(false) 
                setCookie('userDetails', JSON.stringify(result.data), {  maxAge: 3600 }); 
                successMsg('PROFILE_SUCCESS');
           },1000)
        })
        .catch((error) => {
            setSubmit(false)
            if (error.response) {
                console.log(error.response.data.errors)
                seErrors(error?.response?.data?.errors)
                formError(error?.response?.data?.errors)
               return
            }
            globalError()
        });
    }

    const onchangeCounty = (value) => {
        setValue("homecounty", value);
        clearErrors("homecounty");
    };

    const onchangeYear = (value) => {
        setValue("status", value);
        clearErrors("status");
    }

    const onchange2fa = (value) => {
        console.log(value);
        setValue("user_2FA", value);
    }
    

    return (
        <form
            className="client-reg-form"
            onSubmit={handleSubmit(onSubmit)}
        >
                <h3>Edit Your Account Information</h3>
                <hr />
                <div className="form_two_columns">
                    <div className="form__form-group">
                        <span className="form__form-group-label">
                            First Name
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="client_first_name"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required:
                                        "This is required field",
                                }}
                                placeholder="First Name"
                                // defaultValue={state.firstname}
                                // onChange={handleChange}
                            />
                        </div>
                        {errors?.client_first_name && (
                            <span className="form__form-group-error">
                                {errors?.client_first_name?.message}
                            </span>
                        )}
                    </div>
                    <div className="form__form-group">
                        <span className="form__form-group-label">
                            Last Name
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="client_last_name"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required:
                                        "This is required field",
                                }}
                                defaultValue=""
                                placeholder="Last Name"
                            />
                        </div>
                        {errors?.client_last_name && (
                            <span className="form__form-group-error">
                                {errors?.client_last_name?.message}
                            </span>
                        )}
                    </div>
                </div>
                <div className="form_two_columns">
                    <div className="form__form-group">
                        <span className="form__form-group-label">User Level:</span>
                        <div className="form__form-group-field">
                            <FormSelect
                                name="userlevel"
                                placeholder="Select User Level"
                                rules={{
                                    required: "This is required field",
                                }}
                                control={control}
                                options={USERLEVEL}
                                isOject={true}
                                objectName="name"
                                disabled={cookies?.userDetails?.userlevel === USERLEVEL.ADMIN ? true: false}
                                // onChange={(value)=> onchangeYear(value)}
                            />
                        </div>
                        {errors?.userlevel && <span className="form__form-group-error">{errors?.userlevel?.message}</span>}
                    </div>
                    <div className="form__form-group">
                        <span className="form__form-group-label">Current Tax Year:</span>
                        <div className="form__form-group-field">
                            <FormSelect
                                name="status"
                                placeholder="Select User Status"
                                rules={{
                                    required: "This is required field",
                                }}
                                control={control}
                                options={USERSTATUS}
                                isOject={true}
                                objectName="name"
                                disabled={cookies?.userDetails?.userlevel === USERLEVEL.ADMIN ? true: false}
                                defaultValue={cookies?.userDetails?.status ? cookies?.userDetails?.status === 'A' ? 1 : 2 : 'DEFAULT'}
                                {...register("", { required: "This is required field" })} 
                                onChange={(value)=> onchangeYear(value)}
                            />
                        </div>
                        {errors?.status && <span className="form__form-group-error">{errors?.status?.message}</span>}
                    </div>
                </div>
                <div className="form_two_columns">
                <div className="form__form-group">
                        <span className="form__form-group-label">
                            Company Name
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="company_name"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                }}
                                defaultValue=""
                                placeholder="Company Name"
                            />
                        </div>
                        {errors?.company_name && (
                            <span className="form__form-group-error">
                                {errors?.company_name?.message}
                            </span>
                        )}
                    </div>
                <div className="form__form-group">
                        <span className="form__form-group-label">
                            Email
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="email"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                    pattern: {
                                        value: emailPatter,
                                        message:
                                            "Entered value does not match email format",
                                    },
                                }}
                                defaultValue=""
                                placeholder="Email"
                            />
                        </div>
                        {errors?.email && (
                            <span className="form__form-group-error">
                                {errors?.email?.message}
                            </span>
                        )}
                    </div>
                </div>
                <div className="form_two_columns">
                    <div className="form__form-group">
                        <span className="form__form-group-label">
                        Home Phone 1:
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="homephone1"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required:
                                        "This is required field",
                                }}
                                defaultValue=""
                                placeholder="Phone"
                            />
                        </div>
                        {errors?.homephone1 && (
                            <span className="form__form-group-error">
                                {errors?.homephone1?.message}
                            </span>
                        )}
                    </div>
                    <div className="form__form-group">
                        <span className="form__form-group-label">
                        Home Phone 2:
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="homephone2"
                                component="input"
                                control={control}
                                errors={errors}
                                defaultValue=""
                                placeholder="Phone 2"
                            />
                        </div>
                        {errors?.phone2 && (
                            <span className="form__form-group-error">
                                {errors?.phone2?.message}
                            </span>
                        )}
                    </div>
                </div>
                {cookies?.userDetails?.userlevel === 3 &&
                    <div className="form_two_columns">
                        <div className="form__form-group">
                            <span className="form__form-group-label">User 2FA:</span>
                            <div className="form__form-group-field">
                                <FormSelect
                                    name="user_2FA"
                                    placeholder="Select One"
                                    rules={{
                                        required: "This is required field",
                                    }}
                                    options={list2FA}
                                    control={control}
                                    onChange={(value)=> onchange2fa(value)}
                                    isOject={true}
                                    objectName="name" 
                                    defaultValue={cookies?.userDetails?.user_2FA ? cookies?.userDetails?.user_2FA : '0'}
                                    {...register("user_2FA", {
                                        required:
                                            "This is required field",
                                    })}                                    
                                    // onChange={(value)=> onchangeYear(value)}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            &nbsp;
                        </div>
                    </div>  
                }
                <h3>Client Mailing Information</h3>
                <hr />
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Mailing Address
                    </span>
                    <div className="form__form-group-field">
                        <FormField
                            name="homeaddress"
                            component="input"
                            control={control}
                            errors={errors}
                            rules={{
                                required: "This is required field",
                            }}
                            defaultValue=""
                            placeholder="Mailing Address"
                        />
                    </div>
                    {errors?.homeaddress && (
                        <span className="form__form-group-error">
                            {errors?.homeaddress?.message}
                        </span>
                    )}
                </div>
                <div className="form_two_columns">
                    <div className="form__form-group">
                        <span className="form__form-group-label">
                            Mailing City:
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="homecity"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required:
                                        "This is required field",
                                }}
                                defaultValue=""
                                placeholder="Mailing City"
                            />
                        </div>
                        {errors?.homecity && (
                            <span className="form__form-group-error">
                                {errors?.homecity?.message}
                            </span>
                        )}
                    </div>
                    <div className="form__form-group">
                        <span className="form__form-group-label">
                            Mailing State:
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="homestate"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required:
                                        "This is required field",
                                }}
                                defaultValue=""
                                placeholder=" Mailing State"
                                // value={state.firstname}
                                // onChange={handleChange}
                            />
                        </div>
                        {errors?.homestate && (
                            <span className="form__form-group-error">
                                {errors?.homestate?.message}
                            </span>
                        )}
                    </div>
                </div>
                <div className="form_two_columns">
                    <div className="form__form-group">
                        <span className="form__form-group-label">
                        Mailing County:
                        </span>
                        <div className="form__form-group-field">
                            <FormSelect
                                name="homecounty"
                                control={control}
                                options={county}
                                isOject={true}
                                objectName="name"
                                placeholder="Select Country"
                                defaultValue={cookies?.userDetails?.county_id ? cookies?.userDetails?.county_id : 'DEFAULT'}
                                {...register("homecounty", {
                                    required:
                                        "This is required field",
                                })}
                                onChange={(value) =>
                                    onchangeCounty(value)
                                }
                            />
                        </div>
                        {errors?.homecounty && (
                            <span className="form__form-group-error">
                                {errors?.homecounty?.message}
                            </span>
                        )}
                    </div>
                    <div className="form__form-group">
                        <span className="form__form-group-label">
                            Mailing Zip:
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="homezip"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required:
                                        "This is required field",
                                    pattern: {
                                        value: zipPattern,
                                        message:
                                            "Entered value does not match zip code format",
                                    },
                                }}
                                defaultValue=""
                                placeholder="Mailing Zip"
                            />
                        </div>
                        {errors?.homezip && (
                            <span className="form__form-group-error">
                                {errors?.homezip?.message}
                            </span>
                        )}
                    </div>
                </div>
                <div className="edit-county-btns float-right">
                <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmit}
                > {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''} Save Changes</Button>
                </div>
            </form>
    )
}

export default reduxForm({
    form: 'edit_account_details',
})(EditForm);