/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useHistory } from 'react-router-dom';
import DataTable from "react-data-table-component";
import ApiServices from "@/utils/ApiServices";
import CustomPagination from '@/components/CustomPagination'
import Empty from "@/components/Empty";
import { TABLE_SETTINGS } from "@/utils/constant";
import TableLoading from '@/components/TableLoading'
import { Button, Row, Col } from 'reactstrap';



const ClientResult = ({params}) => {  
    const history = useHistory();  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [rowCount, setRowCount] = useState(0)
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const page_ref = useRef(1);
    const saerch_ref = useRef(null);

    useEffect(() =>{
        if(!loading){
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            },1500) 
        } 
      },[params])

    const columns = useMemo(
        () => [
            {
                name: "First Name",
                selector: "client_first_name",
                sortable: false,
            },
            {
                name: "Last Name",
                selector: "client_last_name",
                sortable: false,
            },
            {
                name: "Company",
                selector: "company_name",
                sortable: false,
            },
            {
                name: "Address",
                selector: "homeaddress",
                sortable: false,
            },
            {
                name: "City",
                selector: "homecity",
                sortable: false,
            },
            {
                name: "State",
                selector: "homestate",
                sortable: false,
            },
            {
                name: "ZipCode",
                selector: "homezip",
                sortable: false,
            },
            {
                cell: (row) => (
                    <Button color="secondary" className="my-1" onClick={()=> handleRowClick(row)}>Details</Button>
                ),
            },
        ],
    );


    const handlePropertyDetail = (propID) => {
        history.push({
            pathname: '/property-detail',
            propID: propID
        })
    }

    const handleRowClick = (row) => {
        history.push({pathname: `/client/details/${row?.id}`})
    }    


    const fetchData = async (page, size = perPage, searchData = search) => {  
        if(saerch_ref?.current){
            page_ref.current = page;
            ApiServices.clientsCustomSearch({'page' : page, 'per_page': size, filters: saerch_ref?.current})
            .then((result) => {   
                setData(result?.data?.data);
                setRowCount(result?.data?.data?.length)
                setTotalRows(result?.data?.total);
                setLoading(false);
            })
            .catch(function (error) {
                console.log('error client filter list', error);
            });
        }
    };

    useEffect(() => {
        if(params){
            saerch_ref.current = params;
            fetchData(1);
        }
    }, [params]);
    

   
    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => { 
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };


    return (
       <Row>
            <Col md={12} className="mb-2">
                <div className="dashboard-card-title">
                    <p>Clients</p>
                </div>
            </Col>
            {/* <Seachbar onChange={(value)=> searchData(value)} placeholder="Search Client" /> */}
            <Col md={12}>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    progressComponent={<TableLoading/>}
                    pagination={false}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    striped={TABLE_SETTINGS.STRIPED}
                    highlightOnHover={true}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={({ selectedRows }) =>
                        console.log(selectedRows)
                    }
                    paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                    paginationRowsPerPageOptions={TABLE_SETTINGS.ROW_PER_PAGE}
                    noDataComponent={<Empty />}
                />
                <CustomPagination rowCount={rowCount} currentPage={currentPage} totalRows={totalRows} perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE} handlePageChange={handlePageChange} />
            </Col>
        </Row>
    );
};

export default ClientResult;
