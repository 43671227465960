/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button, Spinner } from 'reactstrap'
import WarningOutlineIcon from 'mdi-react/WarningOutlineIcon'
import CloseIcon from '@/images/closeIcon'


const ModalAoaAdress = ({open, close, Onsubmit, title, description, btnText, submit, aoaAdressList}) => {   
      
     const [aoaAdress,setAoaAdress] = useState(false)
     const [aoaAdressValue,setAoaAdressValue] = useState(null)
     const [city,setCity] = useState(null)
     const [state,setState] = useState(null)
     const [zip,setZip] = useState(null)

     useEffect(() => {
          setAoaAdressValue(aoaAdressList[0])
     },[aoaAdressList])

     const onselect  = (event) =>{
          let value = event.target.value
          if(value === "New Mailing Address"){
               setAoaAdressValue(null)
               setAoaAdress(true)
          }else{
               setAoaAdress(false)
               setAoaAdressValue(value)
          }

          console.log(value)
     }

     const onDataSubmit  = (event) =>{
          if(!aoaAdressValue){
               alert('enter value')
               return
          }
          let new_address =  `${aoaAdressValue}`
          if(aoaAdress){
               new_address =  `${aoaAdressValue}, ${city}, ${state} ${zip}`
          }
          Onsubmit({new_address,aoaAdress,aoaAdressValue,city,state,zip})
     }

    return(
        <>
            <Modal show={open} onHide={close}  size="md"    centered >
                <Button onClick={close} className="modalconfirm-closebtn"><CloseIcon color="#383938" /></Button>
                <Modal.Body className="text-center" >
                <div className={`form__form-group-input-wrap `}>
                     <p className="mb-2">Mailing Address</p>
                     {!aoaAdress &&(
                    <select className="form-control" onChange={onselect}>
                       {aoaAdressList.map((obj,i) => 
                         <option key={i} value={obj} >{obj}</option>
                       )}
                    </select>
                     )}
                    {aoaAdress &&(
                         <>
                            <input className="form-control mt-4" placeholder="Address" onChange={(event) => setAoaAdressValue(event.target.value)} />
                            <input className="form-control mt-4" placeholder="City" onChange={(event) => setCity(event.target.value)} />
                            <input className="form-control mt-4" placeholder="State" onChange={(event) => setState(event.target.value)} />
                            <input className="form-control mt-4" placeholder="Zip" onChange={(event) => setZip(event.target.value)} />
                            <div className="text-right cursor-pointer text-danger">
                                <span onClick={() =>  setAoaAdress(false)}>Cancel</span>
                            </div>
                        </>
                    )}
                    </div>
                    <Button className="mt-5" color="primary" onClick={onDataSubmit} disabled={submit} >
                        {submit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}{btnText}
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalAoaAdress;