import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ loading }) => (
  <div className={`load${loading ? '' : ' loaded'}`}>
    <div className="loading-div">
      <div className="ripple" />
    </div>
  </div>
);

Loading.propTypes = {
  loading: PropTypes.bool,
};

Loading.defaultProps = {
  loading: false,
};

export default Loading;
