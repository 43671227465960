/* eslint-disable */
import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row, Table, Button, Spinner } from 'reactstrap';
import { successMsg, globalError, formError, successMsgCustom } from "@/utils/message_herlper"
import {  USERLEVELS } from "@/utils/constant";
import ApiServices from "@/utils/ApiServices"
import _ from 'lodash';
const userData = JSON.parse( localStorage.getItem('userData') )



const PropertyDeleteRequest = ({ clientData, requestChangesData }) => {
    const [isSubmit, setIsSubmit] = useState(false)
    const [approved, setApproved] = useState(false)
    const [clientDatas, setClientData] = useState([])

    const AcceptChanges = () => {
        setIsSubmit(true)

        ApiServices.approveDeleteProperty(requestChangesData)
        .then((result) => {
            setIsSubmit(false)
            successMsgCustom('Property successfully deleted!')
            setApproved(true)
        })
        .catch((error) => {
            setIsSubmit(false)
            globalError()
        });
    }
    const RejectChanges = () => {

    }
    const clientDetails = (id) => {
        ApiServices.clientsDetails(id).then((result) => {  
            setClientData(result?.data);
        })
        .catch((error) => {
            formError();
        })
    }

    useEffect(() => {
        if( requestChangesData.status === 1 ){
            setApproved(true)
        }

        if( !_.isArray(clientData) ){
            clientDetails(clientData)
        }else{
            setClientData(clientData)
        }

    }, [])

    return (
        <div className="activity-changes-wrapper mt-3">
        <p>Request to <span className="highlight">delete</span> property:</p>
        {
            userData?.userlevel === USERLEVELS.ADMIN && (
                <Row>
                <Col md={5} className="pt-3 text-left action-btn-wrapper">
                    {!approved && <>
                    <Button color="primary" onClick={AcceptChanges} disabled={isSubmit}>{isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''} Approve</Button>
                    <Button color="secondary">Reject</Button>
                    </> }
                    { approved && 
                    <Button color="primary" disabled>Approved</Button>
                    }
                </Col>
            </Row>
            )
        }
       
        </div>
    )
}

export default PropertyDeleteRequest;