/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import "./ccreate-style.css";
import ApiServices from "../../utils/ApiServices"
import { successMsg, globalError, formError } from "../../utils/message_herlper"
import CreateForm from './components/CreateForm'
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'

const ClientCreate = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();
    const [isSubmit, setSubmit] = useState(false);
    const [errors, seErrors] = useState([]);
    const [county, setCounty] = useState([]);

    useEffect(() => { 
       getCounty();
    },[props]);

    const getCounty = () =>{
        ApiServices.county()
        .then((result) => {   
            setCounty(result.data);
        })
        .catch(function (error) {
        });
    }
    
    const onSubmit = (data) => {
        data.paperless_billing = data.paperless_billing ? data.paperless_billing : 'Y'
        setSubmit(true)
        ApiServices.clientStore(data)
        .then((result) => {    console.log('result', result)
            setTimeout(() =>{
                successMsg('CLIENT_SUCCESS');
                history.push({pathname: `/clients`});
               },1000)
        })
        .catch((error) => {
            setSubmit(false)
            if (error.response) {
                console.log(error.response.data.errors)
                seErrors(error?.response?.data?.errors)
                formError(error?.response?.data?.errors)
               return
            }
            globalError()
        });
    }

    const  onBack = () => {
        history.push({pathname: `/clients`});
    }
   
    
    return (
        <Container className="client-registration-container">
            <TitleBreadCrumbs title="Client Registration" >
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="/clients">Client</Link>
                    <span className="current_breadcrumbs">
                        Client Registration
                    </span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody className="client-registration-form-container">
                            <CreateForm  county={county}  onSubmit={(data)=> onSubmit(data)} isSubmit={isSubmit} errorList={errors} onBack={()=> onBack()} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};


export default ClientCreate;

