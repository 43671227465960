/* eslint-disable */
import React, { useEffect, useMemo, useState, useRef } from "react";
import queryString from "query-string";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Spinner,
} from "reactstrap";

import { reduxForm } from "redux-form";
import { Link,  useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { FormField } from "@/shared/components/FormField";
import TableLoading from "@/components/TableLoading";
import ApiServices from "@/utils/ApiServices";
import { TABLE_SETTINGS, API } from "@/utils/constant";
import { globalError } from "@/utils/message_herlper";
import Empty from "@/components/Empty";
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'

const PropertiesByZip = (props) => {
    const history = useHistory()
    const location = useLocation()
    const {
        handleSubmit,
        reset,
        control,
        errors,
        register,
        setValue,
        clearErrors,
        formState,
    } = useForm();
    const [isSubmit, setSubmit] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [zipCode, setZipCode] = useState(null);
    const zipRef = useRef(null);
    const [rowCount, setRowCount] = useState(0)

    useEffect(() => {  
       let { zipcodes } = props?.match?.params
       if(zipcodes){
            setZipCode(zipcodes);
            let slug = zipcodes.split(",");
            let zipcodesList = [];
            for (let index = 0; index < slug.length; index++) {
                zipcodesList.push(parseInt(slug[index]));
            }
            zipRef.current = zipcodesList?.toString();
            fetchData(1);
       }else{
           zipRef.current = null;
           setZipCode(null);
       }
    }, [location]);

    const columns = useMemo(() => [
        {
            name: "Client Name",
            selector: "client_name",
            sortable: false,
        },
        {
            name: "Email",
            selector: "email",
            sortable: false,
        },
        {
            name: "Phone",
            selector: "homephone1",
            sortable: false,
        },
        {
            name: "Owner's Name",
            selector: "owner_name",
            sortable: false,
        },
        {
            name: "Property Address",
            selector: "property_address",
            sortable: false,
        },
        {
            name: "Property  City",
            selector: "property_city",
            sortable: false,
        },
        {
            name: "County",
            selector: "county",
            sortable: false,
        },
        {
            name: "Account #",
            selector: "account_number",
            sortable: false,
        },
        {
            name: "Property Type",
            selector: "property_type",
            sortable: false,
        },
        {
            name: "Date Added",
            selector: "date_added",
            sortable: false,
        },
    ]);

    const fetchData = async (
        page,
        size = perPage,
        zipcodes = zipRef.current
    ) => {
        ApiServices.propertyZipRerport({
            page: page,
            per_page: size,
            zipcodes: zipcodes,
        })
            .then((result) => {
                let details = result?.data;
                setData(result.data.data);
                setTotalRows(result.data.total);
                setLoading(false);
            })
            .catch((error) => {
                globalError();
            });
    };

    const onSubmit = (form) => {
        history.push({ pathname: `/reports/properties-zip/${form.zipcodes}`})
    };

    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };

    const dataTable = [];
    const list = data;
    for (let index = 0; index < list.length; index++) {
        dataTable.push({
            id: list[index].id,
            client_name: (
                <Link
                    to={`/client/details/${list[index]?.user_id}`}
                >{`${list[index].client_first_name} ${list[index].client_last_name}`}</Link>
            ),
            email: list[index].email,
            homephone1: list[index].homephone1,
            owner_name: (
                <Link to={`/property/details/${list[index]?.id}`}>
                    {list[index].owner_name}
                </Link>
            ),
            property_address: list[index].property_address,
            property_city: `${list[index]?.property_city || ""}`,
            county: list[index].country_name,
            account_number: list[index].account_number,
            property_type: list[index].property_type_value,
            date_added: list[index].created_at,
        });
    }

    const exportReport = (type) => {
        window.open(`${API}report/property-byzip-export/${zipRef.current}/${type}`, '_blank');
        // setSubmit(true);
        // ApiServices.propertyZipRerportExport({ zipcodes: zipRef.current })
        //     .then((result) => {
        //         setTimeout(() => {
        //             setSubmit(false);
        //             const url = window.URL.createObjectURL(
        //                 new Blob([result.data])
        //             );
        //             const link = document.createElement("a");
        //             link.href = url;
        //             const fileName = `Properties By Zip Report.csv`;
        //             link.setAttribute("download", fileName);
        //             document.body.appendChild(link);
        //             link.click();
        //             link.remove();
        //         }, 1000);
        //     })
        //     .catch((error) => {
        //         setSubmit(false);
        //         globalError();
        //     });
    };

    const goBack = () => {
        history.push({ pathname: `/reports/properties-zip`})
    };
 
    return (
        <Container>
            <TitleBreadCrumbs title="Properties by Zip">
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="#">Reports</Link>
                    {!zipCode && (
                        <>
                             <span className="current_breadcrumbs"> Properties By Zip</span>
                        </>
                    )}
                    {zipCode  && (
                        <>
                            <Link onClick={() => goBack()} to="#">
                                Properties By Zip
                            </Link>
                            <span className="current_breadcrumbs">Result</span>
                        </>
                    )}
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} className="edit-county-wrap">
                                    {!zipCode ? (
                                        <>
                                            <form
                                                className="client-reg-form"
                                                onSubmit={handleSubmit(
                                                    onSubmit
                                                )}
                                            >
                                                <div className="form__form-group zipcode_wrapper">
                                                    <div className="form__form-group-label mt-2">
                                                        ZipCode:
                                                    </div>
                                                    <div className="zipinput">
                                                        <FormField
                                                            name="zipcodes"
                                                            component="input"
                                                            control={control}
                                                            errors={errors}
                                                            rules={{
                                                                required:
                                                                    "This is required field",
                                                            }}
                                                            placeholder="ZipCode"
                                                        />
                                                        {errors?.zipcodes && (
                                                            <span className="form__form-group-error">
                                                                {
                                                                    errors
                                                                        ?.zipcodes
                                                                        ?.message
                                                                }
                                                            </span>
                                                        )}
                                                        <p>
                                                            You can enter more
                                                            than one zip-code
                                                            using comma (,) as a
                                                            separator like
                                                            12345,67890,24681.
                                                        </p>
                                                    </div>
                                                    <div className="edit-county-btns">
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            disabled={isSubmit}
                                                        >
                                                            Run Report
                                                        </Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    ) : (
                                        <>
                                            <div className="text-center mb-4">
                                                <h3>Zip Search Results</h3>
                                                <h3>
                                                    Properties for {zipCode} Zip
                                                    Codes
                                                </h3>
                                            </div>
                                            <DataTable
                                                columns={columns}
                                                data={dataTable}
                                                progressPending={loading}
                                                progressComponent={
                                                    <TableLoading />
                                                }
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                paginationDefaultPage={
                                                    currentPage
                                                }
                                                striped={TABLE_SETTINGS.STRIPED}
                                                highlightOnHover={true}
                                                onChangeRowsPerPage={
                                                    handlePerRowsChange
                                                }
                                                onChangePage={handlePageChange}
                                                onSelectedRowsChange={({
                                                    selectedRows,
                                                }) => console.log(selectedRows)}
                                                paginationPerPage={
                                                    TABLE_SETTINGS.PAGINATION_PER_PAGE
                                                }
                                                paginationRowsPerPageOptions={
                                                    TABLE_SETTINGS.ROW_PER_PAGE
                                                }
                                                noDataComponent={<Empty />}
                                            />
                                            {
                                                Array.isArray(data) && data.length > 0 ?
                                                    <div className="text-center mt-5">
                                                        <Button
                                                            onClick={() =>
                                                                exportReport('excel')
                                                            }
                                                            disabled={isSubmit}
                                                        >
                                                            {isSubmit ? (
                                                                <Spinner
                                                                    size="sm"
                                                                    color="secondary"
                                                                    className="mr-2"
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                            Export to Excel
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                exportReport('pdf')
                                                            }
                                                            disabled={isSubmit}
                                                        >
                                                            {isSubmit ? (
                                                                <Spinner
                                                                    size="sm"
                                                                    color="link"
                                                                    className="btn-success"
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                            Export to PDF
                                                        </Button>
                                                    </div>
                                                :''
                                            }
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default reduxForm({
    form: "select_tax_year",
})(PropertiesByZip);
