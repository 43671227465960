/* eslint-disable */
import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row, Table, Button, Spinner } from 'reactstrap';
import { successMsg, globalError, formError, successMsgCustom } from "@/utils/message_herlper"
import ApiServices from "@/utils/ApiServices"
import _ from 'lodash';

const ProfileDeleteRequest = ({ clientData, requestChangesData }) => {
    const [isSubmit, setIsSubmit] = useState(false)
    const [approved, setApproved] = useState(false)
    const [clientDatas, setClientData] = useState([])

    const AcceptChanges = () => {
        setIsSubmit(true)

        ApiServices.approveDeleteAccount(requestChangesData)
        .then((result) => {  
            setIsSubmit(false)
            successMsgCustom('Account successfully deleted!')
            setApproved(true)
        })
        .catch((error) => {
            setIsSubmit(false)
            globalError()
        });
    }
    const RejectChanges = () => {

    }
    const clientDetails = (id) => {
        ApiServices.clientsDetails(id).then((result) => {  
            setClientData(result?.data);
        })
        .catch((error) => {
            formError();
        })
    }

    const rejectChanges = () =>{
        console.log("rejectChanges")
    }

    useEffect(() => {
        if( requestChangesData.status === 1 ){
            setApproved(true)
        }

        if( !_.isArray(clientData) ){
            clientDetails(clientData)
        }else{
            setClientData(clientData)
        }

    }, [])

    return (
        <div className="activity-changes-wrapper mt-3">
        <p>Request to <span className="highlight">delete</span> account:</p>
       
        <Row>
            <Col md={5} className="pt-3 text-left action-btn-wrapper">
                {!approved && <>
                <Button color="primary" onClick={AcceptChanges} disabled={isSubmit}>{isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''} Approve</Button>
                <Button  onClick={rejectChanges}  color="secondary">Reject</Button>
                </> }
                { approved && 
                <Button color="primary" disabled>Approved</Button>
                }
            </Col>
        </Row>
        </div>
    )
}

export default ProfileDeleteRequest;