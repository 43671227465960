/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Alert
} from "reactstrap"
import { Link, useHistory, useParams } from "react-router-dom"
import ApiServices from "@/utils/ApiServices"
import { successMsg, globalError, formError, errorMsgCustom } from "@/utils/message_herlper"
import EditForm from './components/EditForm'
import EditFormClient from './components/EditFormClient'
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import ModalModeration from '@/components/ModalModeration'
import InfoIcon from '@/images/infoIcon'
import { USERLEVELS  } from '@/utils/constant'

const PropertyEdit = () => {
    let { id } = useParams(); 
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();
    const [isSubmit, setSubmit] = useState(false);
    const [errors, seErrors] = useState([]);
    const [details, setDetails] = useState(null);
    const [county, setCounty] = useState([]);
    const [propertyType, sePropertyType] = useState([]);
    const [exemptions, setExemptions] = useState([]);
    const userData = JSON.parse(localStorage.getItem('userData'));

    useEffect(() => { 
        clientDetails();
        getCounty();
        getPropertyTypes();
        getExemptionsTypes();
    }, [id]);

    const clientDetails = () => {
        ApiServices.propertyDetailsOnly(id).then((result) => {  
            setDetails(result.data);
        })
        .catch((error) => {
            if(error?.response?.status === 403){
                errorMsgCustom("Property not found!")
                setTimeout(() =>{
                    history.push({pathname: `/dashboard`});
                },1000)
                return
            }
            formError();
        })
    }

    const getExemptionsTypes = () => {
        ApiServices.ExemptionTypes()
            .then((result) => {
                let data = result.data
                data.unshift({
                    id : 0,
                    title: 'None'
                })
                setExemptions(data);
            })
            .catch(function (error) {});
    };

    const onSubmit = (data) => { 
        setSubmit(true)
        data.id = id
        ApiServices.editProperties(data)
        .then((result) => { 
            successMsg('PROPERTY_UPDATE_SUCCESS');
            setTimeout(() => {
                history.push({pathname: `/property/details/${id}`});
            },1000)
        })
        .catch((error) => {
            setSubmit(false)
            if (error.response) {
                seErrors(error?.response?.data?.errors)
                formError(error?.response?.data?.errors)
               return
            }
            globalError()
        });
    }

    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(!open)
    }
    const onSubmitModeration = (data) => { 
        setSubmit(true)
        data.id = id
        ApiServices.editPropertiesModeration(data)
        .then((result) => { 
            setSubmit(false)
            setOpen(true)
            // successMsg('PROPERTY_UPDATE_SUCCESS');
        })
        .catch((error) => {
            setSubmit(false)
            globalError()
        });
    }

    const getCounty = () =>{
        ApiServices.county()
        .then((result) => {   
            setCounty(result.data);
        })
        .catch(function (error) {
        });
    }
    
    const Oncancel = () => { 
       history.push({pathname: `/property/details/${id}`});
    }

    const getPropertyTypes = () =>{
        ApiServices.PropertyTypes()
        .then((result) => { 
            sePropertyType(result.data);
        })
        .catch(function (error) {
        });
    }
    
    return (
        <>
        <ModalModeration open={open} close={handleClose} />
        <Container className="client-registration-container">
            <TitleBreadCrumbs title={`${ userData.userlevel !== USERLEVELS.ADMIN ? 'Property' : 'Edit Property' }`} >
            { userData.userlevel === USERLEVELS.ADMIN &&
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to={`/property/details/${id}`}>Property</Link>
                    <span className="current_breadcrumbs">
                        Edit
                    </span>
                </div>
            }
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody className="client-registration-form-container">
                            { userData.userlevel === USERLEVELS.ADMIN && 
                                <EditFormClient details={details} county={county} propertyType={propertyType}  propertyType={propertyType} onSubmit={(data)=> onSubmit(data)}  onCancel={()=> Oncancel()}  isSubmit={isSubmit} errorList={errors} exemptions={exemptions} />
                            }
                            { userData.userlevel !== USERLEVELS.ADMIN && 
                                <EditFormClient details={details} county={county} propertyType={propertyType}  propertyType={propertyType} onSubmit={(data)=> onSubmitModeration(data)}  onCancel={()=> Oncancel()}  isSubmit={isSubmit} errorList={errors}  exemptions={exemptions} />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    );
};


export default PropertyEdit;

