/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import "moment-timezone";
import moment from "moment";
import EyeIcon from "mdi-react/EyeIcon";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { Button, Alert, Tooltip, Spinner } from "reactstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import { API, US_STATES, TIMEZONE } from "@/utils/constant";
import { emailPatter, zipPattern } from "@/shared/helpers";
import "./register.css";
import AngleArrowLeft from '@/images/angleArrowLeft';
import ApiServices from "@/utils/ApiServices";
import {
    successMsg,
    errorMsgCustom,
    globalError,
    formError,
} from "@/utils/message_herlper";
import CheckIcon from "@/images/checkIcon";
import AttentionIcon from "@/images/attentionIcon";
import CameraIcon from "@/images/cameraIcon";
import InfoIcon from "@/images/infoIcon";
import PaperClip from "@/images/paperClip";
import ModalUpload from "@/components/ModalUploadHomepage";
import PaperIcon from "@/images/paperIcon";
import TrashIcon from "@/images/trashIcon";
import PropertyDescriptionDocs from "@/components/propertyDescriptionDoc";
import { formatPhoneNumber } from "@/utils/helpers"

const RegisterForm = ({ errorMessage }) => {
    const history = useHistory();
    const {
        handleSubmit,
        reset,
        control,
        errors,
        register,
        setValue,
        getValues,
        clearErrors,
        formState,
    } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [formSending, setFormSending] = useState(false);
    const [isSubmit, setSubmit] = useState(false);
    const [county, setCounty] = useState([]);
    const [noSubmit, setNoSubmit] = useState(0);
    const [emailError, setEmailError] = useState(null);
    const [usernameError, setUsernameError] = useState(null);
    const [propertyType, sePropertyType] = useState([]);
    const [exemptions, setExemptions] = useState([]);
    const [paperbilling, setPaperbilling] = useState("Y");
    const [purchasedTwoYears, setPurchasedTwoYears] = useState(false);
    const [addLabel, setAddLabel] = useState("files");
    const [docType, setDocType] = useState("1");
    const [type, setType] = useState(1);
    const [show, setShow] = useState(false);
    const [purchasedDocs, setPurchasedDocs] = useState([]);
    const [majorDMGdocs, setMajorDMDdocs] = useState([]);
    const [remodelDocs, setRemodelDocs] = useState([]);
    const [questionDoc, setQuestionDoc] = useState(1);
    const [document1, set] = useState(null);
    const [majorChanges, setMajorChanges] = useState(false);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);
    const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
    const [remodelUpgrades, setRemodelUpgrades] = useState(false);
    const [settings, setSettings] = useState(null)
    const [homeStateValue, setHomeStateValue] = useState('');
    const [billingCheck, setBillingCheck] = useState(false)
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');

    useEffect(() => { 
        getSettings();
        setBillingCheck(true)
    }, []);

    const getSettings = () => {
        ApiServices.getSettings().then((result) => {
            if(result?.data?.length > 0){
                if( result?.data[0].status ){
                    setSettings( result?.data[0]?.message )
                }
            }
        });
    };

    const handleClose = () => {
        setShow(false);
    };

    useEffect(() => {
        setValue("property_state", "TX");
        getCounty();
        getPropertyTypes();
        getExemptionsTypes();
    }, [errorMessage]);

    useEffect(() => {
        setNoSubmit(formState?.submitCount || 0);
        if (
            formState.isSubmitted &&
            Object.keys(formState?.errors).length > 0 &&
            formState?.submitCount != noSubmit
        ) {
            formError();
            return;
        }
    }, [formState]);

    const getPropertyTypes = () => {
        ApiServices.PropertyTypes()
            .then((result) => {
                sePropertyType(result.data);
            })
            .catch(function (error) {});
    };

    const getExemptionsTypes = () => {
        ApiServices.ExemptionTypes()
            .then((result) => {
                let data = result.data
                data.unshift({
                    id : 0,
                    title: 'None'
                })
                setExemptions(data);
            })
            .catch(function (error) {});
    };

    
    const getCounty = () => {
        ApiServices.county().then((result) => {
            setCounty(result.data);
        });
    };

    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const [state, setState] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        homeaddress: "",
        homecity: "",
        homestate: "",
        homecounty: "",
        homezip: "",
        homephone1: "",
        property_address: "",
        propertycounty: "",
        property_city: "",
        property_state: "",
        property_zip: "",
        txtdescription: "",
        company_name: "",
    });

    const [currentDate, setCurrentDate] = useState("");
    const [checkTerms, setCheckTerms] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [submitDocument, setSubmitDocument] = useState(false);
    const termsCondition = (e) => {
        if (!isChecked) {
            var todaysDate = new Date();
            todaysDate =
                todaysDate.getMonth() +
                1 +
                "/" +
                todaysDate.getDate() +
                "/" +
                todaysDate.getFullYear() +
                "  " +
                (todaysDate.getHours() > 12
                    ? todaysDate.getHours() - 12
                    : todaysDate.getHours()) +
                ":" +
                todaysDate.getMinutes() +
                ":" +
                todaysDate.getSeconds() +
                " " +
                (todaysDate.getHours() >= 12 ? "PM" : "AM");
            setCurrentDate(todaysDate);
            setCheckTerms(!checkTerms);
        } else {
            setIsChecked(false);
        }
    };

    const acceptTerms = () => {
        if (!clientName) {
            errorMsgCustom("Please enter your name");
            return;
        }
        setCheckTerms(false);
        setIsChecked(true);
    };

    const [clientName, setClientName] = useState("");
    const handleClientSigChange = (e) => {
        setClientName(e.target.value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const onchangeState = (value) => {
        setValue("homestate", value);
        clearErrors("homestate");
    };

    const onchangeStateProperty = (value) => {
        setValue("property_state", value);
        clearErrors("property_state");
    };

    const onchangeCounty = (value) => {
        setValue("homecounty", value);
        clearErrors("homecounty");
    };

    const onchangeCountyProperty = (value) => {
        setValue("county_id", value);
        clearErrors("county_id");
    };

    const onchangeExemption = (value) => {
        setValue("exemption_type", value);
        clearErrors("exemption_type");
    };

    

    function saveFile(id, type, file) {
        const data = new FormData();
        data.append("id", id);
        data.append("type", type);
        data.append("file", file);
        ApiServices.registerFile(data)
            .then((result) => {
                console.log(result.data);
            })
            .catch((error) => {
                setSubmit(false);
                if (error.response) {
                    //rors(error?.response?.data?.errors)
                    formError(error?.response?.data?.errors);
                    return;
                }
                globalError();
            });
    }

    function onSubmit(data) {
        setSubmit(true);
        data.paperless_billing = data.paperless_billing
            ? data.paperless_billing
            : "Y";
        ApiServices.register(data)
            .then((result) => {
                setTimeout(() => {
                    if (!result?.data?.success) {
                        setSubmit(false);
                        if (result?.data?.message?.email) {
                            errorMsgCustom(result?.data?.message?.email);
                            setEmailError(result?.data?.message?.email);
                        }
                        if (result?.data?.message?.username) {
                            errorMsgCustom(result?.data?.message?.username);
                            setUsernameError(result?.data?.message?.username);
                        }
                    } else {
                        if (purchasedTwoYears) {
                            for (
                                let index = 0;
                                index < purchasedDocs.length;
                                index++
                            ) {
                                saveFile(
                                    result?.data?.message,
                                    1,
                                    purchasedDocs[index]
                                );
                            }
                        }

                        if (majorChanges) {
                            for (
                                let index = 0;
                                index < majorDMGdocs.length;
                                index++
                            ) {
                                saveFile(
                                    result?.data?.message,
                                    2,
                                    majorDMGdocs[index]
                                );
                            }
                        }

                        if (remodelUpgrades) {
                            for (
                                let index = 0;
                                index < remodelDocs.length;
                                index++
                            ) {
                                saveFile(
                                    result?.data?.message,
                                    3,
                                    remodelDocs[index]
                                );
                            }
                        }
                        setSubmit(false);
                        setTimeout(() => {
                            successMsg("REGISTER_SUCCESS");
                            history.push({ pathname: `/login`, register_success: true });
                        }, 100);
                    }
                }, 1000);
            })
            .catch((error) => {
                setSubmit(false);
                if (error.response) {
                    //rors(error?.response?.data?.errors)
                    formError(error?.response?.data?.errors);
                    return;
                }
                globalError();
            });
    }
    const [copyValues, setCopyValues] = useState({});

    const UploadFiles = (id) => {
        // console.log("UploadFiles -->", id);
    };
    const handleCopyMailing = (e) => {
        if (e.target.checked) {
            setValue("county_id", getValues("homecounty"));
            clearErrors("county_id");

            setValue("property_city", getValues("homecity"));
            clearErrors("property_city");

            setValue("property_address", getValues("homeaddress"));
            clearErrors("property_address");

            setValue("property_zip", getValues("homezip"));
            clearErrors("property_zip");
        }
    };

    const onchangeType = (value) => {
        setValue("property_type", value);
        clearErrors("property_type");
    };

    const downloadVirtualContact = () => {
        window.open(
            `${API}property/client-sign-terms-virtual/${btoa(
                clientName
            )}/${btoa(
                moment()
                    .tz(TIMEZONE)
                    .add(1, "hours")
                    .format("YYYY-MM-DD HH:mm:ss")
            )}/${paperbilling}`,
            "_blank"
        );
    };

    const handlePutfiles = (file) => {
        if (questionDoc === 1) {
            const newpurchasedDocs = [...purchasedDocs];
            for (let index = 0; index < file.length; index++) {
                newpurchasedDocs.push(file[index]);
            }
            setPurchasedDocs(newpurchasedDocs);
        } else if (questionDoc === 2) {
            const newMajorDocs = [...majorDMGdocs];
            for (let index = 0; index < file.length; index++) {
                newMajorDocs.push(file[index]);
            }
            setMajorDMDdocs(newMajorDocs);
        } else {
            const newRemodelDocs = [...remodelDocs];
            for (let index = 0; index < file.length; index++) {
                newRemodelDocs.push(file[index]);
            }
            setRemodelDocs(newRemodelDocs);
        }
    };

    const onSubmitDocument = (file) => {
        setSubmitDocument(true);
        const data = new FormData();
        data.append("id", id);
        data.append("file", file.file);
        data.append("type", file.type);
        if (file.type === 1) {
            setSubmitGeneral(true);
        }

        ApiServices.uploadDocument(data)
            .then((result) => {
                setSubmitGeneral(false);
                if (file.type === 1) {
                    propertyDocuments(1);
                }

                setTimeout(() => {
                    setSubmitDocument(false);
                }, 1500);
            })
            .catch((error) => {
                setSubmitGeneral(false);
                setSubmitDocument(false);
                globalError();
            });
    };

    const deleteDocument = (id, doc) => {
        if (doc === 1) {
            let temp = purchasedDocs.filter((file, index) => index !== id);
            setPurchasedDocs(temp);
        } else if (doc === 2) {
            let temp = majorDMGdocs.filter((file, index) => index !== id);
            setMajorDMDdocs(temp);
        } else {
            const temp = remodelDocs.filter((file, index) => index !== id);
            setRemodelDocs(temp);
        }
    };

    const onChangePhone1 = (value) =>{
        let formatted_number = formatPhoneNumber(value)
        setPhone1(formatted_number)
        setValue("homephone1", formatted_number);
        clearErrors("homephone1");
    }

    const onChangePhone2 = (value) =>{
        let formatted_number = formatPhoneNumber(value)
        setPhone2(formatted_number)
        setValue("homephone2", formatted_number);
        clearErrors("homephone2");
    }

    function submitForm(form){
        form.purchase_two_years = purchasedTwoYears ? 1 : 0
        form.property_major_changes = majorChanges ? 1 : 0
        form.remodels_three_years = remodelUpgrades ? 1 : 0
        onSubmit(form)
      }

    return (
        <>
            <ModalUpload
                open={show}
                close={handleClose}
                type={type}
                uploadFiles={(files) => handlePutfiles(files)}
                is_submitDocument={submitDocument}
                docuType={docType}
                addLabel={addLabel}
            />
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: "",
                    duration: 5000,
                    style: {
                        background: "#363636",
                        color: "#fff",
                    },
                    // Default options for specific types
                    success: {
                        duration: 3000,
                        theme: {
                            primary: "green",
                            secondary: "black",
                        },
                    },
                }}
            />
            <Alert color="danger" isOpen={!!errorMessage}>
                {errorMessage}
            </Alert>
            <Modal
                className="terms-cond-modal"
                show={checkTerms}
                onHide={() => setCheckTerms(false)}
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title>REPUBLIC PROPERTY TAX</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="text-left">
                    <h3>Terms and Conditions</h3>
                    <p>
                        The undersigned owner, authorized agent of owner or
                        person electronically affixing their signature (“Owner”)
                        hereby engages Republic Property Tax (“Republic”) to
                        represent Owner inprotesting Owner’s property taxes for
                        all property in which Owner owns or is responsible
                        forpaying taxes unless otherwise omitted in writing.
                        Owner agrees to pay Republic a contingencyfee equal to
                        50% of Owner’s Estimated Tax Savings achieved by
                        Republic through administrativehearings for each
                        protested tax year unless mutually agreed upon and
                        identified in the“Percentage of Savings” portion of your
                        account on Republic’s website. “Estimated Tax
                        Savings”for a given protested tax year are defined as
                        follows: subtract Owner’s property’s final value forthat
                        year from its noticed value times the latest known tax
                        rate. The resulting number is theEstimated Tax Savings
                        used to calculate the contingency fee. The fee is due
                        for each year forwhich tax liability is settled. This
                        agreement will remain in effect until notice is given in
                        writing byeither party sixty (60) days prior to the
                        following year’s protest deadline. Owner’s notice
                        ofcancelation must be in writing, clearly identify the
                        client’s name, any entity names associatedwith the
                        property or properties, property address(es) and
                        delivered by one of the followingmethods: 1) certified
                        mail; or 2) a message sent via Republic’s website found
                        under the “ContactUs” tab. Any given year that Republic
                        does not save Owner any money Owner will not pay
                        anyfees. The settlement procedure and amount of
                        settlement are the sole discretion of Republic.
                    </p>

                    <p>
                        <b>Terms & Limitations:</b> Payments in full for
                        services are due within thirty (30) days of the date
                        ofRepublic’s invoice, unless mutually agreed to in
                        writing with Republic. Beginning thirty days afterthe
                        invoice date, Owner agrees to pay interest on the unpaid
                        balance at a rate equal of 1.5% permonth, and cost of
                        collection and attorney’s fees, if any. Owner
                        acknowledges that Republic hasgiven no assurances
                        regarding the outcome of any hearing, arbitration or
                        appeal. In making thisagreement, neither party has
                        relied on any representation or agreement that is not
                        expresslystated in this agreement. This agreement can be
                        amended only by a written document signedby both
                        parties. Any action arising from this agreement shall be
                        brought in Harris County, Texasunless prohibited by law.
                        Republic reserves the right to withdraw from this
                        agreement withoutnotice if its fees become delinquent,
                        or requested information is not provided timely. In
                        theevent the Property or Properties which are the
                        subject of this agreement are sold within thecurrent
                        protest year, the full amounts of the fees are due
                        regardless of the date of sale.Estimated Tax Savings are
                        calculated for the purpose of determining fees for
                        services renderedin prior, current, and future years by
                        reference to assessed property values, with
                        consideration toany Texas tax exemptions such as
                        Homestead, Over-65 and Disability, but not jurisdiction
                        specific exemptions. Signatory of this agreement
                        personally guarantees payment of anyamounts owed and
                        grants Republic shall have a security interest in
                        Owner’s non-homesteadProperty until any amounts owed are
                        paid in full. If the property owner is an entity, then
                        theindividual agreeing to the use of the electronic
                        signature as his or her written signaturerepresents that
                        he or she is duly authorized and empowered to enter into
                        this agreement. Thisagreement supersedes all prior
                        agreements between Owner and Republic.
                    </p>

                    <p>
                        <b>Republic’s Liability:</b> If Owner fails to timely
                        provide information or documents to Republic,Owner shall
                        have no claim against Republic for damages. If Republic
                        fails to provide the servicesas listed herein, our
                        liability for any and all claims is limited to the
                        lesser of: a.) The amount offees paid by Owner for the
                        tax year in question on the property in question, or b.)
                        $50.00. Owneris engaging Republic as a contractor and
                        Republic’s liability as a fiduciary is limited by the
                        termsof this agreement. This document represents the
                        entire agreement between Owner and Republic.
                    </p>

                    <p>
                        Property Tax Consultants are Regulated by the Texas
                        Department of Licensing and Regulation,P.O. Box 12157,
                        Austin, Texas 78711, 1-800-803-9202, 512-463-6599,
                        website:www.tdlr.texas.gov.
                    </p>

                    <div className="client-term-footer">
                        <label>Your name for electronic signature</label>
                        <div className="form__electronic-signature">
                            <Field
                                name="electronicsignature"
                                component="input"
                                type="text"
                                onChange={handleClientSigChange}
                                value={clientName}
                                placeholder="Type in your name to be used as your electronic signature"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="text-center">
                    <Button color="danger" onClick={() => setCheckTerms(false)}>
                        Decline
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            acceptTerms();
                        }}
                    >
                        Sign
                    </Button>
                </Modal.Footer>
            </Modal>

            {emailError && (
                <div className="mb-3">
                    <Alert color="danger" className="p-2">
                        {emailError}
                    </Alert>
                </div>
            )}
            {usernameError && (
                <div className="mb-3">
                    <Alert color="danger" className="p-2">
                        {usernameError} 
                    </Alert>
                </div>
            )}
            <div className="mb-4 pb-3">
                <Link className="backto-link" to={`/login`}><AngleArrowLeft color="#FFA647" />Back to login</Link>
            </div>
            <form
                className="form register-form"
                onSubmit={handleSubmit(submitForm)}
            >
                <h4>Personal Information</h4>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        First Name <span className="required-indicator">*</span>{" "}
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="client_first_name"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                }}
                                placeholder="First Name"
                                className={
                                    errors?.client_first_name
                                        ? "error-field"
                                        : ""
                                }
                            />
                            <div>
                                {errors?.client_first_name && (
                                    <span className="form__form-group-error">
                                        {errors?.client_first_name?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Last Name <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="client_last_name"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                }}
                                placeholder="Last Name"
                                className={
                                    errors?.client_last_name
                                        ? "error-field"
                                        : ""
                                }
                            />
                            <div>
                                {errors?.client_last_name && (
                                    <span className="form__form-group-error">
                                        {errors?.client_last_name?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Company Name
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="company_name"
                                component="input"
                                control={control}
                                errors={errors}
                                placeholder="Company Name"
                                className={
                                    errors?.company_name ? "error-field" : ""
                                }
                            />
                            <div>
                                {errors?.company_name && (
                                    <span className="form__form-group-error">
                                        {errors?.company_name?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Email Address{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="email"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                    pattern: {
                                        value: emailPatter,
                                        message:
                                            "Entered value does not match email format",
                                    },
                                }}
                                defaultValue=""
                                placeholder="Email"
                                className={errors?.email ? "error-field" : ""}
                            />
                            <div>
                                {errors?.email && (
                                    <span className="form__form-group-error">
                                        {errors?.email?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <h4>Account Settings</h4>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Create a Username{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="username"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                }}
                                placeholder="Create a Username"
                                className={
                                    errors?.username ? "error-field" : ""
                                }
                            />
                            <div>
                                {errors?.username && (
                                    <span className="form__form-group-error">
                                        {errors?.username?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Create a Password{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="password"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                    minLength: {
                                        value: 8,
                                        message:
                                            "Password must have at least 8 characters",
                                    },
                                }}
                                placeholder="Create a Password"
                                type={showPassword ? "text" : "password"}
                                className={
                                    errors?.password ? "error-field" : ""
                                }
                            />
                            <div
                                className="eye-icon"
                                onClick={toggleShowPassword}
                            >
                                <EyeIcon
                                    color={showPassword ? "#FFA647" : "#717171"}
                                />
                            </div>
                            <div>
                                {errors?.password && (
                                    <span className="form__form-group-error">
                                        {errors?.password?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <h4>Contact Information</h4>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Mailing Address{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="homeaddress"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                }}
                                className={
                                    errors?.homeaddress ? "error-field" : ""
                                }
                                placeholder="Mailing Address"
                            />
                            <div>
                                {errors?.homeaddress && (
                                    <span className="form__form-group-error">
                                        {errors?.homeaddress?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>     
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Mailing City{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="homecity"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                }}
                                placeholder="Mailing City"
                                className={
                                    errors?.homecity ? "error-field" : ""
                                }
                            />
                            <div>
                                {errors?.homecity && (
                                    <span className="form__form-group-error">
                                        {errors?.homecity?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>                           
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Mailing State{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormSelect
                                name="homestate"
                                control={control}
                                options={US_STATES}
                                isOject={true}
                                objectName="name"
                                placeholder="Select State"
                                defaultValue={"DEFAULT"}
                                {...register("homestate", {
                                    required: "This is required field",
                                })}
                                onChange={(value) => { onchangeState(value); setHomeStateValue(value); }}
                                className={
                                    errors?.homestate ? "error-field" : ""
                                }
                            />
                            <div>
                                {errors?.homestate && (
                                    <span className="form__form-group-error">
                                        {errors?.homestate?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Mailing ZIP{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="homezip"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                    pattern: {
                                        value: zipPattern,
                                        message:
                                            "Entered value does not match zip code format",
                                    },
                                }}
                                placeholder="Mailing ZIP"
                                className={errors?.homezip ? "error-field" : ""}
                            />
                            <div>
                                {errors?.homezip && (
                                    <span className="form__form-group-error">
                                        {errors?.homezip?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>                
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Mailing County{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="homecounty"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                }}
                                placeholder="Mailing County"
                                className={
                                    errors?.homecounty ? "error-field" : ""
                                }
                            />
                            {/* <FormSelect
                                name="homecounty"
                                control={control}
                                options={county}
                                isOject={true}
                                objectName="name"
                                placeholder="Select County"
                                defaultValue={"DEFAULT"}
                                {...register("homecounty", {
                                    required: "This is required field",
                                })}
                                onChange={(value) => onchangeCounty(value)}
                            /> */}
                            <div>
                                {errors?.homecounty && (
                                    <span className="form__form-group-error">
                                        {errors?.homecounty?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Phone <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <input
                                value={phone1}
                                {...register("homephone1",{required: "This is required field", })}
                                onChange={(value) => onChangePhone1(value?.target?.value)}
                            />
                            {/* <FormField
                                name="homephone1"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                }}
                                placeholder="Phone"
                                className={
                                    errors?.homephone1 ? "error-field" : ""
                                }
                            /> */}
                            <div>
                                {errors?.homephone1 && (
                                    <span className="form__form-group-error">
                                        {errors?.homephone1?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Phone 2 (Optional)
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                        <input
                            value={phone2}
                            {...register("homephone2")}
                            onChange={(value) => onChangePhone2(value?.target?.value)}
                        />
                            {/* <FormField
                                name="homephone2"
                                component="input"
                                control={control}
                                errors={errors}
                                placeholder="Phone 2 (Optional)"
                            /> */}
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Affiliate/Promo Code:
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="affiliatecode"
                                component="input"
                                control={control}
                                errors={errors}
                                placeholder="Affiliate/Promo Code:"
                                className={
                                    errors?.affiliatecode ? "error-field" : ""
                                }
                            />
                            <div>
                                {errors?.affiliatecode && (
                                    <span className="form__form-group-error">
                                        {errors?.affiliatecode?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <h4>How Did You Hear About Us?</h4>
                <FormField
                    name="howdidyouhear"
                    component="textarea"
                    control={control}
                    errors={errors}
                    placeholder="How Did You Hear About Us?"
                />
                
                <hr />
                <h4>Property to Protest Information</h4>
                <div className="form__form-separator">
                    <Alert color="primary" className="custom-legal-alert mb-4">
                        <span className="mr-2">
                            <InfoIcon color="#ffffff" />
                        </span>
                        <p>
                            If you have more than one property to protest, you
                            can add more after this initial one by logging in
                            and creating more
                        </p>
                    </Alert>
                    { homeStateValue === 'TX' && 
                    <div className="form__form-group-field">
                        <label>
                            <Field
                                name="copymailingadd"
                                component="input"
                                type="checkbox"
                                onChange={handleCopyMailing}
                            />{" "}
                            Copy From Mailing Address
                        </label>
                    </div>
                    }
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Property State{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="property_state"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                }}
                                placeholder="Property State"
                                disabled={true}
                            />
                            <div>
                                {errors?.property_state && (
                                    <span className="form__form-group-error">
                                        {errors?.property_state?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Property County{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormSelect
                                name="county_id"
                                control={control}
                                options={county}
                                isOject={true}
                                objectName="name"
                                placeholder="Select County"
                                defaultValue={"DEFAULT"}
                                {...register("county_id", {
                                    required: "This is required field",
                                })}
                                onChange={(value) =>
                                    onchangeCountyProperty(value)
                                }
                                className={
                                    errors?.county_id ? "error-field" : ""
                                }
                            />
                            <div>
                                {errors?.county_id && (
                                    <span className="form__form-group-error">
                                        {errors?.county_id?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Property Type{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormSelect
                                name="property_type"
                                placeholder="Select Property Type"
                                defaultValue={"DEFAULT"}
                                rules={{
                                    required: "This is required field",
                                }}
                                control={control}
                                options={propertyType}
                                isOject={true}
                                objectName="type"
                                {...register("property_type", {
                                    required: "This is required field",
                                })}
                                onChange={(value) => onchangeType(value)}
                                className={
                                    errors?.property_type ? "error-field" : ""
                                }
                            />
                            <div>
                                {errors?.property_type && (
                                    <span className="form__form-group-error">
                                        {errors?.property_type?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Exemption Type{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormSelect
                                name="exemption_type"
                                placeholder="Select Exemption Type"
                                defaultValue={"DEFAULT"}
                                // rules={{
                                //     required: "This is required field",
                                // }}
                                control={control}
                                options={exemptions}
                                isOject={true}
                                objectName="title"
                                {...register("exemption_type")}
                                onChange={(value) => onchangeExemption(value)}
                                className={
                                    errors?.exemption_type ? "error-field" : ""
                                }
                            />
                            <div>
                                {errors?.exemption_type && (
                                    <span className="form__form-group-error">
                                        {errors?.exemption_type?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Property City{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="property_city"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                }}
                                placeholder="Property City"
                                className={
                                    errors?.property_city ? "error-field" : ""
                                }
                            />
                            <div>
                                {errors?.property_city && (
                                    <span className="form__form-group-error">
                                        {errors?.property_city?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Property Address to Protest{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="property_address"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                }}
                                placeholder="Property Address to Protest"
                                className={
                                    errors?.property_address
                                        ? "error-field"
                                        : ""
                                }
                            />
                            <div>
                                {errors?.property_address && (
                                    <span className="form__form-group-error">
                                        {errors?.property_address?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        Property ZIP{" "}
                        <span className="required-indicator">*</span>
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="property_zip"
                                component="input"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: "This is required field",
                                    pattern: {
                                        value: zipPattern,
                                        message:
                                            "Entered value does not match zip code format",
                                    },
                                }}
                                placeholder="Property ZIP"
                                className={
                                    errors?.property_zip ? "error-field" : ""
                                }
                            />
                            <div>
                                {errors?.property_zip && (
                                    <span className="form__form-group-error">
                                        {errors?.property_zip?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__form-group" style={{display: "none"}}>
                    <span className="form__form-group-label">
                        Property Legal Description
                    </span>
                    <div className="form__form-group-field">
                        <div className="form_form-group-field-container">
                            <FormField
                                name="legal_desc"
                                component="input"
                                control={control}
                                errors={errors}
                                placeholder="Property Legal Description"
                                className={
                                    errors?.legal_desc ? "error-field" : ""
                                }
                            />
                            <div>
                                {errors?.legal_desc && (
                                    <span className="form__form-group-error">
                                        {errors?.legal_desc?.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <hr />
                <div style={{ width: "100%" }}>
                    <h4>Property Notes</h4>
                </div>
                <div className="form__numbered_options">
                    <span className="form__number_label">1</span>
                    <div className="form__options_container">
                        <div className="form__options_label">
                            Have you purchased this property within the last two
                            years?
                        </div>
                        <div className="form__options_wrapper">
                            <label>
                                <FormField
                                    name="purchase_two_years"
                                    component="input"
                                    type="radio"
                                    control={control}
                                    errors={errors}
                                    defaultValue="1"
                                    value="1"
                                    defaultChecked={
                                        purchasedTwoYears ? true : null
                                    }
                                    onClick={(e) => {
                                        setPurchasedTwoYears(true);
                                        e.target.value = "1";
                                    }}
                                />
                                Yes
                            </label>
                            <label>
                                <FormField
                                    name="purchase_two_years"
                                    component="input"
                                    type="radio"
                                    control={control}
                                    errors={errors}
                                    defaultValue="0"
                                    value="0"
                                    defaultChecked={
                                        !purchasedTwoYears ? true : null
                                    }
                                    //defaultChecked={ details?.purchased_within_two_years ? null : true }
                                    onClick={(e) => {
                                        setPurchasedTwoYears(false);
                                        e.target.value = "0";
                                    }}
                                />
                                No
                            </label>
                        </div>
                        {purchasedTwoYears && (
                            <div className="form__optional_upload_container">
                                <label>
                                    <Button
                                        onClick={() => {
                                            setShow(true);
                                            setType(1);
                                            setDocType("1");
                                            setAddLabel("documents");
                                            setQuestionDoc(1);
                                        }}
                                        color="secondary"
                                        className="secondary-2"
                                    >
                                        <PaperClip /> Add documents
                                    </Button>
                                </label>
                                {/* <span
                                    onHover={toggle}
                                    className="form__custom_tooltip"
                                >
                                    <a id="diclosure_tooltip">i</a>
                                </span> */}

                                {/* <Tooltip
                                    placement="top-start"
                                    isOpen={tooltipOpen}
                                    popperClassName="custom-tooltip-popper"
                                    className="custom-tooltip"
                                    target="diclosure_tooltip"
                                    arrowClassName="custom-tooltip-arrow"
                                    toggle={toggle}
                                >
                                    The Closing Disclosure is a five-page form
                                    that a lender provides to a home buyer at
                                    least 3 business days before their loan
                                    closes. It outlines the final terms and
                                    costs of the mortgage.
                                </Tooltip> */}
                            </div>
                        )}
                    </div>
                </div>
                <div className="property-desc-home mb-3">
                    {purchasedDocs.map((obj, i) => {
                        return (
                            <React.Fragment key={i}>
                                {" "}
                                <PropertyDescriptionDocs
                                    name={obj.name}
                                    onDelete={() => deleteDocument(i, 1)}
                                />
                            </React.Fragment>
                        );
                    })}
                </div>
                <div className="form__numbered_options mt-3">
                    <span className="form__number_label">2</span>
                    <div className="form__options_container">
                        <div className="form__options_label">
                            Does the property have any major damage?
                            <br />{" "}
                            <i>
                                (i.e. foundation issues, roof damage, cracks in
                                the exterior or interior walls, etc.)
                            </i>
                        </div>
                        <div className="form__options_wrapper">
                            <label>
                                <FormField
                                    name="property_major_changes"
                                    component="input"
                                    type="radio"
                                    control={control}
                                    errors={errors}
                                    defaultValue="1"
                                    value="1"
                                    defaultChecked={majorChanges ? true : null}
                                    onClick={(e) => {
                                        setMajorChanges(true);
                                        e.target.value = "1";
                                    }}
                                />
                                Yes
                            </label>
                            <label>
                                <FormField
                                    name="property_major_changes"
                                    component="input"
                                    type="radio"
                                    control={control}
                                    errors={errors}
                                    defaultValue="0"
                                    value="0"
                                    defaultChecked={!majorChanges ? true : null}
                                    onClick={(e) => {
                                        setMajorChanges(false);
                                        e.target.value = "0";
                                    }}
                                />
                                No
                            </label>
                        </div>
                        {majorChanges && (
                        <div className="form__conditional_field_container">
                            <div className="form__conditional_field-label">
                                Description
                            </div>
                            <div className="form__conditional_field-wrapper">
                                <FormField
                                    name="major_dmg_description"
                                    component="textarea"
                                    control={control}
                                    errors={errors}
                                    placeholder="Description"
                                />
                                {majorDMGdocs.map((obj, i) => {
                                    return (
                                        <>
                                            <React.Fragment key={i}>
                                                {" "}
                                                <PropertyDescriptionDocs
                                                    index={i}
                                                    docuType="1"
                                                    id={i}
                                                    status={obj?.status}
                                                    name={obj?.name}
                                                    file_type={obj?.type}
                                                    onDelete={() =>
                                                        deleteDocument(i, 2)
                                                    }
                                                    onStatus={(data) =>
                                                        onStatus(data)
                                                    }
                                                />
                                            </React.Fragment>
                                        </>
                                    );
                                })}
                                <div className="form__conditional_field-btns pt-2">
                                    <Button
                                        onClick={() => {
                                            setDocType("2");
                                            setShow(true);
                                            setType(1);
                                            setAddLabel("photos");
                                            setQuestionDoc(2);
                                        }}
                                        color="secondary"
                                        className="secondary-2"
                                    >
                                        <CameraIcon /> Add photos
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDocType("1");
                                            setShow(true);
                                            setType(1);
                                            setAddLabel("documents");
                                            setQuestionDoc(2);
                                        }}
                                        color="secondary"
                                        className="secondary-2"
                                    >
                                        <PaperClip /> Add documents
                                    </Button>
                                    {/* <span
                                        onHover={toggle}
                                        className="docu-tooltip"
                                    >
                                        <a id="desc_tooltip">
                                            <InfoIcon color="#383938" />
                                        </a>
                                    </span>
                                    <Tooltip
                                        placement="top-start"
                                        isOpen={tooltipOpen2}
                                        target="desc_tooltip"
                                        popperClassName="custom-tooltip-popper"
                                        className="custom-tooltip"
                                        arrowClassName="custom-tooltip-arrow"
                                        toggle={toggle2}
                                    >
                                        The Closing Disclosure is a five-page
                                        form that a lender provides to a home
                                        buyer at least 3 business days before
                                        their loan closes. It outlines the final
                                        terms and costs of the mortgage.
                                    </Tooltip> */}
                                </div>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="form__numbered_options mt-3">
                    <span className="form__number_label">3</span>
                    <div className="form__options_container">
                        <div className="form__options_label">
                            Have you done any remodels or upgrades to the
                            property within the last three years?
                        </div>
                        <div className="form__options_wrapper">
                            <label>
                                <FormField
                                    name="remodels_three_years"
                                    component="input"
                                    type="radio"
                                    control={control}
                                    errors={errors}
                                    defaultValue="1"
                                    value="1"
                                    defaultChecked={
                                        remodelUpgrades ? true : null
                                    }
                                    onClick={(e) => {
                                        setRemodelUpgrades(true);
                                        e.target.value = "1";
                                    }}
                                />
                                Yes
                            </label>
                            <label>
                                <FormField
                                    name="remodels_three_years"
                                    component="input"
                                    type="radio"
                                    control={control}
                                    errors={errors}
                                    defaultValue="0"
                                    value="0"
                                    defaultChecked={
                                        !remodelUpgrades ? true : null
                                    }
                                    onClick={(e) => {
                                        setRemodelUpgrades(false);
                                        e.target.value = "0";
                                    }}
                                />
                                No
                            </label>
                        </div>
                        {remodelUpgrades && (
                            <div className="form__conditional_field_container">
                                <div className="form__conditional_field-label">
                                    Description of the remodel or upgrades
                                </div>
                                <div className="form__conditional_field-wrapper">
                                    <FormField
                                        name="remodel_description"
                                        component="textarea"
                                        control={control}
                                        errors={errors}
                                        placeholder="Description of the remodel or upgrades"
                                    />
                                    {remodelDocs.map((obj, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                {" "}
                                                <PropertyDescriptionDocs
                                                    index={i}
                                                    docuType="1"
                                                    id={i}
                                                    status={obj?.status}
                                                    name={obj?.name}
                                                    file_type={obj?.type}
                                                    onDelete={() =>
                                                        deleteDocument(i, 3)
                                                    }
                                                    onStatus={(data) =>
                                                        onStatus(data)
                                                    }
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                                    <div className="form__conditional_field-btns pt-2">
                                        <Button
                                            onClick={() => {
                                                setShow(true);
                                                setType(1);
                                                setDocType("1");
                                                setAddLabel("documents");
                                                setQuestionDoc(3);
                                            }}
                                            color="secondary"
                                            className="secondary-2 mb-0"
                                        >
                                            <PaperClip /> Add documents
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <hr />
                <h4>Important Legal Information</h4>
                <div className="form__form-separator">
                    <Alert
                        color={`${
                            !isChecked ? "warning" : "success"
                        } custom-legal-alert mb-4`}
                    >
                        {!isChecked ? (
                            <>
                                <span className="mr-2">
                                    <AttentionIcon color="#ffffff" />
                                </span>{" "}
                                <p>
                                    Before we can protest your property taxes,
                                    you have to agree to our Terms & Conditions
                                </p>{" "}
                            </>
                        ) : (
                            <>
                                <span className="mr-2">
                                    <CheckIcon color="#ffffff" />
                                </span>{" "}
                                <p>Virtual Contract Signed</p>{" "}
                            </>
                        )}
                    </Alert>
                    {!isChecked ? (
                        <Button color="secondary" onClick={termsCondition}>
                            Sign Virtual Contract
                        </Button>
                    ) : (
                        <Button
                            color="secondary"
                            type="button"
                            onClick={downloadVirtualContact}
                        >
                            Download Virtual Contract
                        </Button>
                    )}
                    <div className="mt-4">
                        <span className="form__form-group-label">
                            <h4>Billing:</h4>
                        </span>
                        <div className="form__form-group-radio inline-radio-button">
                            <label>
                                <FormField
                                    name="billingmode"
                                    component="input"
                                    type="radio"
                                    control={control}
                                    errors={errors}
                                    defaultValue="paperbilling"
                                    value="paperbilling"
                                    defaultChecked={true}
                                    onClick={() => setPaperbilling("Y")}
                                />
                                Signup for Paper Billing
                            </label>
                            <label>
                                <FormField
                                    name="billingmode"
                                    component="input"
                                    type="radio"
                                    control={control}
                                    errors={errors}
                                    defaultValue="electronicbilling"
                                    value="electronicbilling"
                                    onClick={() => setPaperbilling("N")}
                                />
                                Signup for Electronic Billing
                            </label>
                        </div>
                    </div>
                    <div style={{display: 'none'}} className={`form__form-group-field check-container-with-border inline-checkbox-field ${errors.protest ? 'error-field' : ''}`}>
                        <FormField
                            name="protest"
                            component="input"
                            type="checkbox"
                            control={control}
                            errors={errors}
                            defaultValue=""
                            value=""
                            onClick={(e) => {
                                e.target.value = e.target.value === '' ? '1' : '';
                            }}
                        />
                        <label>
                            { settings ? settings : 
                            <>
                            Because the protest deadline has already passed. I
                            understand that I am signing up for the my 2022
                            Property Tax Protest. If you are interested in
                            protesting your 2021 Tax Protest and have already
                            filed it please contact our office.
                            </>
                            }
                        </label>
                    </div>
                    {errors?.protest && (
                        <div>
                            <span className="form__form-group-error">
                                {errors?.protest?.message}
                            </span>
                        </div>
                    )}
                </div>
                <hr />
                <div>By signing up you agree to our Terms of Condtions.</div>

                <div className="account__btns register__btns pt-2">
                    <Button type="submit" color="primary" disabled={isSubmit}>
                        {isSubmit ? (
                            <Spinner
                                size="sm"
                                color="secondary"
                                className="mr-2"
                            />
                        ) : (
                            ""
                        )}
                        Register
                    </Button>
                </div>
            </form>
        </>
    );
};

RegisterForm.defaultProps = {
    errorMessage: "",
};

export default React.memo(
    reduxForm({
        form: "register_form",
    })(RegisterForm)
);
