/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { 
   CardBody, Container
} from 'reactstrap';

import { Link, useLocation, useHistory } from 'react-router-dom';
import ClientResult from './ClientResult'
import PropertyResult from './PropertyResult'
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'

const SearchResults = (props) => {
    const location = useLocation()
    const history = useHistory()
    const [params, setParams] = useState(null)
    const [stype, setStype] = useState('client')
    let search = queryString.parse(props.location.search)  
    let search_params = {}
    useEffect(() => {  
        let { key, } = props?.match?.params;
        let url_params = atob(key);
        let { type, first_name, last_name, phone, email, company, status, owner_name, account_number, property_address, city, county, property_type , zipcode, state} = queryString.parse(url_params);
        setStype(type)
        let search_active =  'A';
        if(search?.active === 'false'){
            search_active= 'I'
        }
        if(type === 'client') {
            search_params = {
                first_name:  first_name,
                last_name: last_name,
                phone:  phone,
                email:  email,
                company:  company,
                county:  county,
                status:  search_active,
           }
           setParams(JSON.stringify(search_params))
        }
        if(type === 'property') {
            search_params = {
                owner_name:  owner_name,
                account_number:  account_number,
                property_address:  property_address,
                city:  city,
                state:  state,
                county:  county,
                property_type:  property_type,
                zipcode:  zipcode,
                status:  search_active,
            }
            setParams(JSON.stringify(search_params))
        }
   
    }, [location]);
    
    return (
        <Container>
            <TitleBreadCrumbs title="Search Results" >
                <div className="page-breadcrumbs">
                   <Link to="/">Home</Link>
                    <Link to={`/${stype === 'client' ? 'clients': 'properties'}`}>{stype === 'client' ? 'Client': 'Properties'}</Link>
                    <span className="current_breadcrumbs">Search Results</span>
                </div>
            </TitleBreadCrumbs>
            <CardBody>
                {stype === 'client' ? 
                    <ClientResult  params={params} />
                :<PropertyResult params={params}  />}
               
            </CardBody>
        </Container>
    );
}


export default withRouter(SearchResults);