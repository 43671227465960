/* eslint-disable */
import React, {useEffect , useState} from "react";
import { useParams, useLocation, useHistory} from 'react-router-dom';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { RTLProps } from "@/shared/prop-types/ReducerProps";
import ActiveClientDetails from "./components/ActiveClientDetails";
import ActivePropertyDetails from "./components/ActivePropertyDetails";
import NewClientsProperties from "./components/NewClientProperties";
import RecentActivities from "./components/RecentActivities";
import RecentViewed from "./components/RecentViewed";
import Reports from "./components/Reports";
import ExportData from "./components/ExportData";
import ClientSideProperties from './components/ClientSideProperties'
import ClientSideProfileView from './components/ClientSideProfileView'
import "./dashboard-style.css";
import ApiServices from "@/utils/ApiServices"
import { USERLEVELS } from '@/utils/constant'

const AppDashboard = (props) => {
    let { id } = useParams()
    const location = useLocation()
    const history = useHistory()

    const [activeClient,seActiveClient] = useState(0)
    const [activeClientYear,seActiveClientYear] = useState(0)
    const [activeProperty,seActiveProperty] = useState(0)
    const [activePropertyYear,seActivePropertyYear] = useState(0)
    const userData = JSON.parse(localStorage.getItem('userData'));

    const [clientSideActivePropCount, setClientSideActivePropCount] = useState(0)

    const [recent,seRecent] = useState([])

    useEffect(() => { 
        numberClient('A');
        numberClientCurrent();
        numberProperties('A');
        numberPropertiesCurrent();
        recent_view();
     },[props]);

    const numberClient = (status) =>{
        ApiServices.numberClient({status: status})
        .then((result) => {   
            seActiveClient(result?.data)
        })
    }

    const numberClientCurrent = (status) =>{
        ApiServices.numberClientCurrent({status: status})
        .then((result) => {   
            seActiveClientYear(result?.data)
        })
    }

    const numberProperties = (status) =>{
        ApiServices.numberProperties({status: status})
        .then((result) => {   
            seActiveProperty(result?.data)
        })
    }

    const numberPropertiesCurrent = (status) =>{
        ApiServices.numberPropertiesCurrent({status: status})
        .then((result) => {   
            seActivePropertyYear(result?.data)
        })
    }

    const recent_view = () =>{
        ApiServices.recent_view()
        .then((result) => {   
            seRecent(result?.data)
        })
    }



    
 
    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Dashboard</h3>
                </Col>
            </Row>
            <Row>
            { userData?.userlevel !== USERLEVELS.CLIENT && <> 
                <Col md={6}>
                    <Card className="card-controlled">
                        <CardBody className="dashboard__card-widget">
                            <ActiveClientDetails activeClient={activeClient}  activeClientYear={activeClientYear} />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="card-controlled">
                        <CardBody className="dashboard__card-widget">
                            <ActivePropertyDetails activeProperty={activeProperty} activePropertyYear={activePropertyYear} />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={12}>
                    <Card className="card-controlled">
                        <CardBody>
                            <NewClientsProperties />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={9}>
                    <Card>
                        <CardBody>
                            {/*<RecentActivities />*/}
                            { userData?.userlevel === USERLEVELS.ADMIN ? <> 
                            <Reports />
                            <ExportData />
                            </>
                            :<h5>User role not allowed to access reports or export any data </h5>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card>
                        <CardBody>
                            <RecentViewed recent={recent} />
                        </CardBody>
                    </Card>
                </Col>
                {/*
                <Col md={9}>
                    <Card>
                        <CardBody>

                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card>
                        <CardBody>

                        </CardBody>
                    </Card>
                </Col>
                */}
            </>}

            { userData?.userlevel === USERLEVELS.CLIENT && <> 
                <Col md={6}>
                    <Card className="card-controlled">
                        <CardBody className="dashboard__card-widget">
                            <ActiveClientDetails activeClient={activeClient}  activeClientYear={activeClientYear} />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="card-controlled">
                        <CardBody className="dashboard__card-widget">
                            <ActivePropertyDetails activeProperty={clientSideActivePropCount} activePropertyYear={activePropertyYear} />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={12}>
                    <Card className="card-controlled">
                        <CardBody>
                            <ClientSideProperties setActiveCount={setClientSideActivePropCount} />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={12}>
                    <Card className="card-controlled">
                        <CardBody>
                            <ClientSideProfileView />
                        </CardBody>
                    </Card>
                </Col>
               
            </>}
            </Row>
        </Container>
    );
};

AppDashboard.propTypes = {
    rtl: RTLProps.isRequired,
};

export default connect((state) => ({
    rtl: state.rtl,
}))(AppDashboard);
