/* eslint-disable */
import React, { useState } from 'react';
import { 
    Card, CardBody, Col, Container, Row
  } from 'reactstrap';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'

import './contact-style.css';

const EmailAll = () => {
    localStorage.setItem('account', 'false');
    // Dynamic data soon
    const columns = [
        {
            name: 'No.',
            selector: 'id',
            sortable: true,
            width: '70px'
        },
        {
            name: 'Client Name',
            selector: 'clientname',
            sortable: true,
            width: '200px'
        },
        {
            name: 'Company Name',
            selector: 'company',
            sortable: true,
            width: '200px'
        },
        {
            name: 'Address',
            selector: 'address',
            sortable: true,
            width: '300px'
        },
        {
            name: 'Home Phone',
            selector: 'phone',
            sortable: true,
            width: '200px'
        },
        {
            name: 'Email Address',
            selector: 'email',
            sortable: true,
            width: '250px'
        }
    ]

    const data = [
        { id: 1, clientname: 'ANGEL GARZA', company: '', address: '8811 VENTURE LANE LA PORTE,TEXAS,77571', phone: '(832) 766-4310', email: 'mgarza@rosen-group.com' },
        { id: 2, clientname: 'ADAM COLLIER', company: 'MR.', address: '3502 DAPPLED RIDGE WAY PEARLAND,TX,77581', phone: '(346) 229-8849', email: 'Ancollier2@gmail.com' },
        { id: 3, clientname: 'ALCIDES OMAR VENTURA', company: '', address: '3510 AFTON FOREST LN KATY,TX,77449', phone: '(281) 690-3387', email: 'omarvntr@yahoo.com' },
    ];

    return (
        <Container>
            <TitleBreadCrumbs title="Contact" >
                <div className="page-breadcrumbs">
                <Link to="/">Home</Link>
                    <span className="current_breadcrumbs">Contact</span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <DataTable pagination="true" selectableRows columns={columns} data={data} /> 
                                    <div className="text-center send-email-btns">
                                        <Button className="email-all-btn" color="primary">Send Email</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
export default EmailAll;