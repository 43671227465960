/* eslint-disable */
import React, { useCallback, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button, Spinner } from 'reactstrap'
import CloseIcon from '@/images/closeIcon'
import { useDropzone } from 'react-dropzone'
import PaperIcon from '@/images/paperIcon'
import TrashIcon from '@/images/trashIcon'
import PlusIcon from '@/images/plusIcon'
import toast, { Toaster } from 'react-hot-toast';

const ModalDocuUpload = ({ open, close, type, submitDocment, is_submitDocument, docuType="2", addLabel="files" }) => {
    const [files, setFiles] = useState([]);
    const [theType, setTheType] = useState(2);

    const [submitting, setSubmitting] = useState(false)
    
    const onDropRejected = useCallback(() => {
        //console.log( 'TYPE IS -->', theType );
        // if( theType === 1 ){
        //     toast.error('Only .xlsx, .pdf, .doc files are accepted');
        // }else{
        //     toast.error('Only .jpg, .jpeg, .png files are accepted');
        // }
        
    }, []);
    
    const onDropAccepted = useCallback((acceptedFiles) => {
        acceptedFiles.map((file) => {
            setFiles((files) => [...files, file ]);
        });
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
      } = useDropzone({
        onDropRejected,
        onDropAccepted,
       // accept: theType === 1 ? '.doc, .docx, .pdf, .xlsx' : 'image/jpeg, image/png',
        maxFiles: 5,
        multiple: true,
    });

    const upload = () => {
        setSubmitting(true)

        files.map((file) => {
            submitDocment({type: type, file: file})
        })
        
        setSubmitting(false)

        setTimeout(function(){
            setFiles([])
            close();
        }, 1000)
        
    }

    const removeFile = (fileID) => {
        const temp = files.filter((file, index) => index !== fileID);
        setFiles(temp)
    }

    useEffect(() => {
        setTheType(parseInt(docuType));
    }, [docuType])

    return (
        <Modal className="modal-doc-upload" show={open} onHide={() => { setFiles([]); close(); } } centered size="md">
            <Button onClick={() => { setFiles([]); close(); } } className="modalconfirm-closebtn modal-closebtn"><CloseIcon color="#383938" /></Button>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                    background: '#363636',
                    color: '#fff',
                    },
                    // Default options for specific types
                    success: {
                    duration: 3000,
                    theme: {
                        primary: 'green',
                        secondary: 'black',
                    },
                    },
                }}
            />  
            <Modal.Body className="text-center">
                <h3>Add {addLabel}</h3>
                { files &&
                <div className="docu-file-wrapper">
                    {files.map((file, index) => (
                        <div key={`${index} '-' ${file.path}`} className="docu-file-item">
                            <div >
                                <div><PaperIcon color="#FFA647" /></div>
                                <div className="item-path">{file.path}</div>
                            </div>
                            <div>
                                <span className="cursor-pointer" onClick={() => removeFile(index)}><TrashIcon color="#A8B5BF" /></span>
                            </div>
                        </div>
                    ))}
                </div>
                }
                 <div {...getRootProps({className: 'dropzone disabled'})}>
                    <input {...getInputProps()} />
                    <p><PlusIcon color="#FFA647" /> Add {addLabel}</p>
                </div>
                <hr />
                <div className="docu-modal-btns">
                    <Button color="secondary" className="mr-2 mb-0"onClick={() => { setFiles([]); close(); } }>
                        Cancel
                    </Button>
                    <Button color="primary" className={`${ files.length === 0 ? 'disabled' : '' } primary-2 mb-0` }  disabled={is_submitDocument} onClick={upload}>
                        {is_submitDocument ? <Spinner size="sm" color="secondary" className="mr-2" /> : '' }Upload
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default ModalDocuUpload;