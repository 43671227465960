/* eslint-disable */

const DollarIcon = ({ color="#00C48C", custom }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill={color} />
            <g clipPath="url(#clip0)">
                <path d="M10 5.4165V14.5832" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.0833 7.0835H8.95833C8.57156 7.0835 8.20063 7.23714 7.92714 7.51063C7.65365 7.78412 7.5 8.15506 7.5 8.54183C7.5 8.9286 7.65365 9.29954 7.92714 9.57303C8.20063 9.84652 8.57156 10.0002 8.95833 10.0002H11.0417C11.4284 10.0002 11.7994 10.1538 12.0729 10.4273C12.3464 10.7008 12.5 11.0717 12.5 11.4585C12.5 11.8453 12.3464 12.2162 12.0729 12.4897C11.7994 12.7632 11.4284 12.9168 11.0417 12.9168H7.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
                </clipPath>
            </defs>
        </svg>
    );
}
export default DollarIcon;