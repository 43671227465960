/* eslint-disable */
import React from "react";
import FileTableOutlineIcon from 'mdi-react/FileTableOutlineIcon';


const Empty = ({title}) => {
    return(
        <div className="empty-content">
            <FileTableOutlineIcon color="#dce0e6" size={80} />
            {title? title : 'There are no records to display'}
        </div>
    )
};

export default Empty;