/* eslint-disable */
import React from "react";
import _ from 'lodash';
import { Route, Switch, useHistory, Redirect } from "react-router-dom";

import { useCookies } from "react-cookie";
import { USERLEVELS  } from '@/utils/constant'
import Layout from "../../../Layout/index";

import NotFound from "../../..//DefaultPage/404/index";
import Account from "./Account";
import Footer from "../../../Layout/footer";

import AppDashboard from "../../../Dashboards/App/index";
import AppDashboardDetails from "../../../Dashboards/App/details";
import Profile from "../../../Profile";

import ResetPassword from "../../../Account/ResetPassword";

import Clients from "../../../Client";
import ClientCreate from "../../../Client/client-create";
import ClientSearch from "../../../Client/client-search";
import ClientDetails from "../../../Client/client-details";
import ClientEdit from "../../../Client/client-edit";

import SystemSettings from "../../../SystemSettings";
import Acknowledgment from "../../../SystemSettings/acknowledgment";
import RegistrationSettings from "../../../SystemSettings/RegistrationSettings";
import AllActivities from "../../../Activities";

import InvoiceReport from "../../../Reports/invoice-reports";
import InvoiceReportDetails from "../../../Reports/invoice-property-details";
import CommissionReportDetails from "../../../Reports/commission-report-details";
import AccountSpecificReport from "../../../Reports/account-specific-report";
import AccountSpecificReportDetails from "../../../Reports/account-specific-properties";
import AccountSpecificReportBillingDetails from "../../../Reports/account-specific-report-billing-details";
import BalanceReport from "../../../Reports/outstanding-balance";

import YearlyAccountingTotal from "../../../Reports/yearly-accounting-totals";
import PropertiesByZip from "../../../Reports/properties-zip";
import NotInvoiced from "../../../Reports/not-invoiced";
import NotInvoicedProperties from "../../../Reports/not-invoice-properties";
import CustomReport from "../../../Reports/custom";
import Leads from "../../../Reports/leads";


import SearchResults from "../../../SearchResults";
import CustomSearchResults from "../../../CustomSearchResults";

import Properties from "../../../Properties";
import PropertiesSearch from "../../../Properties/property-search";
import PropertyDetails from "../../../Properties/property-details";
import PropertyDescription from "../../../Properties/propertyDescription";
import PropertyCreate from "../../../Properties/property-create";
import PropertyEdit from "../../../Properties/property-edit";
import AddBilling from "../../../Properties/add-billing";

import CountyList from "../../../County/countylist";
import ExemptionList from "../../../Exemption/exemptionList";
import ExemptionCreate from "../../../Exemption/create-form";

import CountyEdit from "../../../County/edit-county";
import CountyCreate from "../../../County/create-county";

import PropTypeList from "../../../PropertyType";
import PropTypeCreate from "../../../PropertyType/create";

import StaffList from "../../../Staff";

import AccountDetails from "../../../AccountDetails";

import SendEmail from "../../../Contact/send-email";
import EmailAll from "../../../Contact/email-all";

import AccountForm from "../../../AccountForm/index";
import AccountForms from "../../../SystemSettings/account-form";
import SessionModal from "./SessionModal";
import RequestEmail from "../../../County/countylist";


import AdminRoutes from './AdminRoutes'

import MultiUpload from "../../../MultiUpload";
import BatchEmail from "../../../BatchEmail";
import CommissionReport from "../../../Reports/commission-report";

function WrapRoute() {
    const [cookies, removeCookie] = useCookies(["name"]);
    const userData = JSON.parse(localStorage.getItem('userData'));
   
    return (
        <>
            {localStorage.getItem("loggedin_id") !== null && !_.isUndefined(cookies.token)  ? ( //&& cookies.token !== 'undefined'
                <>
                    <Layout />
                    <div className="user-loggedin container__wrap">
                        
                      
                        <Route
                            exact
                            path="/dashboard"
                            component={AppDashboard}
                        />
                        <AdminRoutes path="/account" component={Account} />
                        <AdminRoutes path="/profile" component={Profile} />
                        
                        <AdminRoutes
                            path="/dashboard-details/:type"
                            component={AppDashboardDetails}
                        />
                        
                        <AdminRoutes path="/clients" component={Clients} />
                        <AdminRoutes path="/client-create" component={ClientCreate} />
                        <AdminRoutes
                            path="/client/details/:id/:rid?/:activities?"
                            component={ClientDetails}
                        />
                        <AdminRoutes path="/client/edit/:id" component={ClientEdit} />
                        <AdminRoutes path="/client-search" component={ClientSearch} />
                        <AdminRoutes
                            path="/reports/invoice-report/:key?"
                            component={InvoiceReport}
                        />
                        <AdminRoutes
                            path="/invoice-report/details/:key"
                            component={InvoiceReportDetails}
                        />
                         <AdminRoutes
                            path="/commission-report/details/:key"
                            component={CommissionReportDetails}
                        />
                        
                        <AdminRoutes
                            path="/reports/account-specific-report/:key?/:name"
                            component={AccountSpecificReport}
                        />
                        <AdminRoutes
                            path="/account-specific-report/details/:key?"
                            component={AccountSpecificReportDetails}
                        />

                       <AdminRoutes
                            path="/account-specific-report/billing-details/:key?"
                            component={AccountSpecificReportBillingDetails}
                        />

                        <AdminRoutes
                            path="/reports/yearly-accounting-totals/:year?"
                            component={YearlyAccountingTotal}
                        />
                        <AdminRoutes
                            path="/reports/properties-zip/:zipcodes?"
                            component={PropertiesByZip}
                        />
                        <AdminRoutes
                            path="/reports/not-invoiced/:year?"
                            component={NotInvoiced}
                        />
                        <AdminRoutes
                            path="/reports/outstanding-balance"
                            component={BalanceReport}
                        />
                        
                        <AdminRoutes
                            path="/reports/not-invoiced-properties/:county_id/:year/:county"
                            component={NotInvoicedProperties}
                        />
                        <AdminRoutes
                            path="/reports/custom-report/:key?"
                            component={CustomReport}
                        />
                        <AdminRoutes
                            path="/reports/leads/:key?"
                            component={Leads}
                        />
                        <AdminRoutes
                            path="/properties-search"
                            component={PropertiesSearch}
                        />
                        <AdminRoutes
                            path="/search-results/:type/:keyword/:active"
                            component={SearchResults}
                        />
                        <AdminRoutes
                            path="/custom-search-results/:key"
                            component={CustomSearchResults}
                        />

                        <AdminRoutes path="/county-list" component={CountyList} />
                        <AdminRoutes path="/exemption-type" component={ExemptionList} />
                        <AdminRoutes
                            path="/exemption-form/:type?/:id?"
                            component={ExemptionCreate}
                        />
                        <AdminRoutes path="/edit-county" component={CountyEdit} />
                        <AdminRoutes
                            path="/county-form/:type?/:id?"
                            component={CountyCreate}
                        />
                        <AdminRoutes path="/property-type" component={PropTypeList} />
                        <AdminRoutes
                            path="/property-type-form/:type?/:id?"
                            component={PropTypeCreate}
                        />
                        <AdminRoutes path="/stafflist" component={StaffList} />
                        <AdminRoutes
                            path="/account-page/:type?/:id?"
                            component={AccountForm}
                        />
                        <AdminRoutes path="/send-email" component={SendEmail} />
                        <AdminRoutes path="/email-all" component={EmailAll} />

                        <AdminRoutes path="/settings" component={SystemSettings} />
                        <AdminRoutes path="/acknowledgment" component={Acknowledgment} />
                        <AdminRoutes path="/registration-settings" component={RegistrationSettings} />
                        <AdminRoutes path="/multi-upload-documents/:type" component={MultiUpload} />
                        
                        {/* path="/reports/invoice-report/:year?/:property?/:billing?/:result?" */}

                        <Route path="/properties" component={Properties} />
                        <Route path="/property/details/:id/:documents?/:activities?" component={PropertyDetails} />
                        <Route
                            path="/property/description/:id"
                            component={PropertyDescription}
                        />
                        <Route
                            path="/reset-password"
                            component={ResetPassword}
                        />
                        <Route
                            path="/property/create/:id/:owner_name"
                            component={PropertyCreate}
                        />
                        <Route
                            path="/property/edit/:id"
                            component={PropertyEdit}
                        />
                        <Route
                            path="/property/add-billing/:id"
                            component={AddBilling}
                        />
                        <Route
                            path="/property/edit-billing/:id/:billing_id?"
                            component={AddBilling}
                        />
                        <Route
                            path="/account-details"
                            component={AccountDetails}
                        />

                        {/* <AdminRoutes path="/send-email" component={SendEmail} /> */}
                        <AdminRoutes path="/batch-email" component={BatchEmail} />
                        <AdminRoutes path="/email-all" component={EmailAll} />

                        {/* <Route path="/settings" component={SystemSettings} /> */}
                        {/* <Route path="/account-form/:type?/:id?" component={AccountForms} /> */}

                        {/* <Route path="/new-client-properties" component={NewClientProperties} /> */}
                        <AdminRoutes path="/activities/:type?" component={AllActivities} />
                        <AdminRoutes path="/request-profile-edit/:id" component={AppDashboard} />
                        <AdminRoutes path="/reports/commission-report/:key?" component={CommissionReport} />
                        {/* <Route path="*" component={NotFound} />
                        </Switch> */}

                    </div>
                </>
            ) : (
                // 
                <>
                    <Route path="*" exact={true} component={NotFound} />
                    {/* <Redirect to="/admin-login" /> */}
                </>
            )}
        </>
    );
}

export default WrapRoute;
