/* eslint-disable */
import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
} from "react";
import DataTable from "react-data-table-component";
import ModalConfirm from "@/components/ModalConfirm";
import ApiServices from "@/utils/ApiServices";
import CheckIcon from "@/images/checkIcon";
import { TABLE_SETTINGS } from "@/utils/constant";
import { successMsg, globalError } from "@/utils/message_herlper";
import moment from "moment";
import Empty from "@/components/Empty";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Spinner } from "reactstrap";
import TableLoading from "@/components/TableLoading";
import CustomPagination from '@/components/CustomPagination'
import { USERLEVELS } from "@/utils/constant";

const ClientTable = ({ title, status, is_new, isTotal, isReload, isReloaded }) => {   
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [rowCount, setRowCount] = useState(0)
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLoad, setPageLoad] = useState(false);
    const history = useHistory();
    const page_ref = useRef(1);
    const id_ref = useRef(0);
    const userData = JSON.parse( localStorage.getItem('userData') )

    useEffect(() => {
        fetchUsers(1);
    }, []);

    useEffect(() => {
        fetchUsers(1);
    },[isReloaded]);
    

    const fetchUsers = async (page, size = perPage) => {
        setLoading(true);
        page_ref.current = page;
        ApiServices.clientsListActiveInactive({
            page: page,
            per_page: size,
            pstatus: status,
            is_new: is_new,
        })
            .then((result) => { 
                setData(result.data.data);
                setTotalRows(result.data.total);
                setRowCount(result.data.data.length);
                setLoading(false);
                if(isTotal){
                    isTotal(result.data.total);
                }
                setPageLoad(true)
            })
            .catch(function (error) {
                setLoading(false);
            });
    };

    const handleViewDetails = (clientID) => {
        history.push({
            pathname: `/client/details/${clientID}`,
        });
    };

    const columns = useMemo(() => [
        {
            name: "Date added",
            selector: "termsofusetimestamp",
            sortable: false,
            width: "150px",
        },
        {
            name: "Full Name",
            selector: "name",
            sortable: false,
            width: "200px",
        },
        {
            name: "Address, City, State Zip code",
            selector: "homeaddress",
            sortable: false,
            width: "250px",
        },
        {
            name: "Phone",
            selector: "homephone1",
            sortable: false,
            width: "150px",
        },
        {
            name: "Email",
            selector: "email",
            sortable: false,
            width: "250px",
        },
        {
            cell: (row) => (
                <>
                    <Button
                        className="my-0  btn-sm white-nowrap"
                        onClick={() => handleViewDetails(row.id)}
                        color="secondary"
                    >
                        Details
                    </Button>
                    { is_new && userData?.userlevel !== USERLEVELS.CLIENT  && userData?.userlevel !== USERLEVELS.STAFF && userData?.userlevel !== USERLEVELS.STAFF_ROLE && (
                        <Button
                            className="my-1 primary-2 white-nowrap"
                            color="primary"
                            onClick={() => handleMark(row.id)}
                        >
                            <CheckIcon color="#ffffff" /> Mark ready
                        </Button>
                    )}
                </>
            ),
            right: true,
        },
    ]);

    const handleMark = (value) => {
        id_ref.current = value;
        setConfirm(true);
    };

    const handlePageChange = (page) => {
        fetchUsers(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchUsers(page, newPerPage);
        setPerPage(newPerPage);
    };


    const onMarkReady = () => {
        setSubmit(true);
        ApiServices.markReady(id_ref.current)
            .then((result) => {
                setConfirm(false);
               
                successMsg("CLIENT_MARKED_SUCCESS");
                fetchUsers(1);
                if(isReload){
                    isReload()
                }
                setTimeout(() => {
                     setSubmit(false);
                }, 1000);
            })
            .catch((error) => {
                setSubmit(false);
                globalError();
            });
    };

    const dataTable = [];
    const list = data;
    for (let index = 0; index < list.length; index++) {
        dataTable.push({
            id: list[index].id,
            termsofusetimestamp: moment
                .utc(list[index].termsofusetimestamp)
                .local()
                .format("MMM DD, YYYY hh:mm A"),
            name: `${list[index].client_first_name} ${list[index].client_last_name}`,
            homeaddress: `${list[index].homeaddress}, ${
                list[index]?.homecity || ""
            }, ${list[index]?.homestate || ""} ${list[index]?.homezip || ""}`,
            homephone1: list[index].homephone1,
            email: list[index].email,
        });
    }

    return (
        <>
            <ModalConfirm
                submit={submit}
                title="Mark Client  Ready"
                description="Are you sure you want to mark this client  ready?"
                btnText="Mark  Ready"
                open={confirm}
                Onsubmit={() => onMarkReady()}
                close={() => setConfirm(false)}
            />
            <Row>
                <Col md={12} className="mb-2">
                    <div className="dashboard-card-title mt-2">
                        <p>{title}</p>
                    </div>
                    {!pageLoad ?<Spinner size="sm" color="warning" /> :''}
                </Col>
                <Col md={12}>
                {pageLoad ?
                        <>
                    <DataTable
                        //title="Users"
                        columns={columns}
                        data={dataTable}
                        progressPending={loading}
                        progressComponent={<TableLoading />}
                        pagination={false}
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationDefaultPage={currentPage}
                        striped={TABLE_SETTINGS.STRIPED}
                        highlightOnHover={true}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        onSelectedRowsChange={({ selectedRows }) =>
                            console.log(selectedRows)
                        }
                        paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                        paginationRowsPerPageOptions={
                            TABLE_SETTINGS.ROW_PER_PAGE
                        }
                        noDataComponent={<Empty />}
                    />
                    <CustomPagination rowCount={rowCount} currentPage={currentPage} totalRows={totalRows} perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE} handlePageChange={handlePageChange} />
                    </>
                    : ''}
                </Col>
            </Row>
        </>
    );
};

export default React.memo(ClientTable);
