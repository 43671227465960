/* eslint-disable */
import React from "react";
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import Image404 from "@/shared/img/404/404.png";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { USERLEVELS  } from '@/utils/constant'

function NotFound404() {
    const [cookies, removeCookie] = useCookies(["name"]);
    const userData = JSON.parse(localStorage.getItem('userData'));

    let adminStyle = {}
    if(!_.isUndefined(cookies.token)){
        adminStyle = {
            margin: '0px 20px 0px 20px'
        }
    }

    return (
        <div className="custom-404-content">
        <Container className="custom-404">
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <h2 className="page-heading">Oops!</h2>
                            <p>We couldn't find the page you're looking for.</p>
                            {localStorage.getItem("loggedin_id") !== null && !_.isUndefined(cookies.token) ? (
                                    <Link className="btn btn-primary mb-0" to={userData?.userlevel !== USERLEVELS?.ADMIN ? '/properties' : '/dashboard'}>
                                        Back to {userData?.userlevel !== USERLEVELS.ADMIN ? 'Properties' : 'Dashboard'}
                                    </Link>
                                ):(
                                    <Link className="btn btn-primary mb-0" to="/login">
                                        Back to Login
                                    </Link>
                                )
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        </div>
    );
}

export default NotFound404;
