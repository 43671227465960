/* eslint-disable */
import React, { useState, useEffect, } from "react";
import PropTypes from "prop-types";
import { Col, Container, Card, CardBody, Button, Spinner, Alert} from "reactstrap";
import { useTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import { emailPatter, zipPattern } from "@/shared/helpers";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountIcon";
import AlternateEmailIcon from "mdi-react/EnvelopeIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import PhoneIcon from "mdi-react/MobilePhoneIcon";
import classNames from "classnames";
import { formError } from "@/utils/message_herlper"
import { formatPhoneNumber } from "@/utils/helpers"

const paperType = [
    {id: 'Y', name: 'Yes'},
    {id: 'N', name: 'No'},
]

const CreateForm = ({ onSubmit, isSubmit, errorList, county, onBack }) => {
    const { t } = useTranslation("common");
    const {
        handleSubmit,
        reset,
        control,
        errors,
        register,
        setValue,
        clearErrors,
        formState
    } = useForm();
    const [emailError, setEmailError] = useState([]);
    const [usernameError, setUsernameError] = useState([]);
    const [noSubmit, setNoSubmit] = useState(0);  

    useEffect(() => {
        setEmailError(errorList?.email ? errorList?.email : []);
        setUsernameError(errorList?.username ? errorList?.username : []);
    }, [errorList]);

    const expandClass = classNames({
        icon: true,
        expand: true,
        "expand--load": isSubmit,
    });

    const [showPassword, setShowPassword] = useState(false);
    const [addtionalContact, setAddtionalContact] = useState('');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const onchangeCounty = (value) => {
        setValue("homecounty", value);
        clearErrors("homecounty");
    };

    const onchangePaper = (value) => { 
        setValue("paperless_billing", value);
        clearErrors("paperless_billing");
    };

    

    const onChangeAddtionalContact = (value) =>{
        let formatted_number = formatPhoneNumber(value)
        setAddtionalContact(formatted_number)
        setValue("additionalcontactnumber", formatted_number);
    }

    const onChangePhone1 = (value) =>{
        let formatted_number = formatPhoneNumber(value)
        setPhone1(formatted_number)
        setValue("homephone1", formatted_number);
        clearErrors("homephone1");
    }

    const onChangePhone2 = (value) =>{
        let formatted_number = formatPhoneNumber(value)
        setPhone2(formatted_number)
        setValue("phone2", formatted_number);
        clearErrors("phone2");
    }
    
    useEffect(() => {    
        setNoSubmit(formState?.submitCount || 0)
        if (formState.isSubmitted && Object.keys(formState?.errors).length > 0 && formState?.submitCount != noSubmit ) {
            formError()
            return
        }
    }, [formState])
 
    return (
        <Container className="client-registration-container">
            <Col md={12}>
                <Card>
                    <CardBody className="client-registration-form-container">
                        <form
                            className="client-reg-form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            {emailError.length > 0 &&
                                emailError.map((obj, i) => {
                                    return (
                                        <Alert color="danger" className="alert-content">
                                            <p>{obj}</p>
                                        </Alert>
                                    );
                                })}
                            {usernameError.length > 0 &&
                                usernameError.map((obj, i) => {
                                    return (
                                        <Alert color="danger" className="alert-content">
                                            <p>{obj}</p>
                                        </Alert>
                                    );
                                })}

                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    PAPER CLIENT?
                                </span>
                                <div>
                                     <FormSelect
                                        name="paperless_billing"
                                        control={control}
                                        options={paperType}
                                        isOject={true}
                                        objectName="name"
                                        placeholder="Select Type"
                                        defaultValue={'Y'}
                                        {...register("paperless_billing")}
                                        onChange={(value) =>
                                            onchangePaper(value)
                                        }
                                    />
                                    <p>
                                        (Note: If YES, it means the client is
                                        new and they have sent in a paper Terms
                                        of Use and paper A to A agreement (that
                                        you have on file with them). Checking
                                        this box will send them a diffrent email
                                        with their account information, and it
                                        will tell the database that this client
                                        has a paper A of A and has agreed to the
                                        Terms of Use).
                                    </p>
                                </div>
                            </div>

                            <div className="form_two_columns">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        First Name
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <AccountOutlineIcon />
                                        </div>
                                        <FormField
                                            name="client_first_name"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required:
                                                    "This is required field",
                                            }}
                                            placeholder="First Name"
                                            // defaultValue={state.firstname}
                                            // onChange={handleChange}
                                        />
                                    </div>
                                    {errors?.client_first_name && (
                                        <span className="form__form-group-error">
                                            {errors?.client_first_name?.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Last Name
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <AccountOutlineIcon />
                                        </div>
                                        <FormField
                                            name="client_last_name"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required:
                                                    "This is required field",
                                            }}
                                            defaultValue=""
                                            placeholder="Last Name"
                                        />
                                    </div>
                                    {errors?.client_last_name && (
                                        <span className="form__form-group-error">
                                            {errors?.client_last_name?.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Company Name
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AccountOutlineIcon />
                                    </div>
                                    <FormField
                                        name="company_name"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            required: "This is required field",
                                        }}
                                        defaultValue=""
                                        placeholder="Company Name"
                                    />
                                </div>
                                {errors?.company_name && (
                                    <span className="form__form-group-error">
                                        {errors?.company_name?.message}
                                    </span>
                                )}
                            </div>

                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Email
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AlternateEmailIcon />
                                    </div>
                                    <FormField
                                        name="email"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            required: "This is required field",
                                            pattern: {
                                                value: emailPatter,
                                                message:
                                                    "Entered value does not match email format",
                                            },
                                        }}
                                        defaultValue=""
                                        placeholder="Email"
                                    />
                                </div>
                                {errors?.email && (
                                    <span className="form__form-group-error">
                                        {errors?.email?.message}
                                    </span>
                                )}
                            </div>

                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Create a Username
                                </span>
                                <div className="form__form-group-field">
                                    <FormField
                                        name="username"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            required: "This is required field",
                                        }}
                                        defaultValue=""
                                        placeholder="Create a Username"
                                    />
                                </div>
                                {errors?.username && (
                                    <span className="form__form-group-error">
                                        {errors?.username?.message}
                                    </span>
                                )}
                            </div>

                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Create a Password
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <KeyVariantIcon />
                                    </div>
                                    <FormField
                                        name="password"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            required: "This is required field",
                                        }}
                                        defaultValue=""
                                        placeholder=" Create a Password"
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                    />
                                    <button
                                        type="button"
                                        className={`form__form-group-icon form__form-group-button${
                                            showPassword ? " active" : ""
                                        }`}
                                        onClick={toggleShowPassword}
                                    >
                                        <EyeIcon />
                                    </button>
                                </div>
                                {errors?.password && (
                                    <span className="form__form-group-error">
                                        {errors?.password?.message}
                                    </span>
                                )}
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Mailing Address
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AccountOutlineIcon />
                                    </div>
                                    <FormField
                                        name="homeaddress"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            required: "This is required field",
                                        }}
                                        defaultValue=""
                                        placeholder="Mailing Address"
                                    />
                                </div>
                                {errors?.homeaddress && (
                                    <span className="form__form-group-error">
                                        {errors?.homeaddress?.message}
                                    </span>
                                )}
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Mailing Address 2
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AccountOutlineIcon />
                                    </div>
                                    <FormField
                                        name="homeaddress2"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        defaultValue=""
                                        placeholder="Mailing Address 2"
                                    />
                                </div>
                                {errors?.homeaddress2 && (
                                    <span className="form__form-group-error">
                                        {errors?.homeaddress2?.message}
                                    </span>
                                )}
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Mailing City:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <MapMarkerIcon />
                                        </div>
                                        <FormField
                                            name="homecity"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required:
                                                    "This is required field",
                                            }}
                                            defaultValue=""
                                            placeholder="Mailing City"
                                        />
                                    </div>
                                    {errors?.homecity && (
                                        <span className="form__form-group-error">
                                            {errors?.homecity?.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Mailing State:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <MapMarkerIcon />
                                        </div>
                                        <FormField
                                            name="homestate"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required:
                                                    "This is required field",
                                            }}
                                            defaultValue=""
                                            placeholder=" Mailing State"
                                        />
                                    </div>
                                    {errors?.homestate && (
                                        <span className="form__form-group-error">
                                            {errors?.homestate?.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        County:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <MapMarkerIcon />
                                        </div>
                                        {/* <FormSelect
                                            name="homecounty"
                                            control={control}
                                            options={county}
                                            isOject={true}
                                            objectName="name"
                                            placeholder="Select Country"
                                            defaultValue={"DEFAULT"}
                                            {...register("homecounty", {
                                                required:
                                                    "This is required field",
                                            })}
                                            onChange={(value) =>
                                                onchangeCounty(value)
                                            }
                                        /> */}
                                        <FormField
                                            name="homecounty"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required:
                                                    "This is required field",
                                            }}
                                            defaultValue=""
                                            placeholder=" Mailing County"
                                        />
                                    </div>
                                    {errors?.homecounty && (
                                        <span className="form__form-group-error">
                                            {errors?.homecounty?.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Mailing Zip:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <MapMarkerIcon />
                                        </div>
                                        <FormField
                                            name="homezip"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required:
                                                    "This is required field",
                                                pattern: {
                                                    value: zipPattern,
                                                    message:
                                                        "Entered value does not match zip code format",
                                                },
                                            }}
                                            defaultValue=""
                                            placeholder="Mailing Zip"
                                        />
                                    </div>
                                    {errors?.homezip && (
                                        <span className="form__form-group-error">
                                            {errors?.homezip?.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Phone:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <PhoneIcon />
                                        </div>
                                        <input
                                           value={phone1}
                                           {...register("homephone1",{required: "This is required field", })}
                                          onChange={(value) => onChangePhone1(value?.target?.value)}
                                        />
                                        {/* <FormField
                                            name="homephone1"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required:
                                                    "This is required field",
                                            }}
                                            defaultValue=""
                                            placeholder="Phone"
                                            onChange={handleChange}
                                        /> */}
                                    </div>
                                    {errors?.homephone1 && (
                                        <span className="form__form-group-error">
                                            {errors?.homephone1?.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Phone 2:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <PhoneIcon />
                                        </div>
                                        <input
                                           value={phone2}
                                           {...register("phone2")}
                                          onChange={(value) => onChangePhone2(value?.target?.value)}
                                        />
                                        {/* <FormField
                                            name="phone2"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            defaultValue=""
                                            placeholder="Phone 2"
                                        /> */}
                                    </div>
                                    {errors?.phone2 && (
                                        <span className="form__form-group-error">
                                            {errors?.phone2?.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Additional Contact Name:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <AccountOutlineIcon />
                                        </div>
                                        <FormField
                                            name="additionalcontactname"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            defaultValue=""
                                            placeholder="Additional Contact Name:"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {errors?.additionalcontact && (
                                        <span className="form__form-group-error">
                                            {errors?.additionalcontact?.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Additional Contact Number:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <PhoneIcon />
                                        </div>
                                        <input
                                           value={addtionalContact}
                                           {...register("additionalcontactnumber")}
                                          onChange={(value) => onChangeAddtionalContact(value?.target?.value)}
                                        />
                                    </div>
                                    {errors?.additionalcontact && (
                                        <span className="form__form-group-error">
                                            {errors?.additionalcontact?.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Additional Contact Email:
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AlternateEmailIcon />
                                    </div>
                                    <FormField
                                        name="additionalcontactemail"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            pattern: {
                                                value: emailPatter,
                                                message:
                                                    "Entered value does not match email format",
                                            },
                                        }}
                                        defaultValue=""
                                        placeholder="Additional Contact Email"
                                    />
                                </div>
                                {errors?.additionalcontactemail && (
                                    <span className="form__form-group-error">
                                        {
                                            errors?.additionalcontactemail
                                                ?.message
                                        }
                                    </span>
                                )}
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Affiliate/Promo Code:
                                </span>
                                <div className="form__form-group-field">
                                    <FormField
                                        name="affiliatecode"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        defaultValue=""
                                        placeholder="Affiliate/Promo Code"
                                    />
                                </div>
                                {errors?.affiliatecode && (
                                    <span className="form__form-group-error">
                                        {errors?.affiliatecode?.message}
                                    </span>
                                )}
                            </div>

                            <div className="client-create-btns">
                                <Button
                                    color="secondary"
                                    className="cancel__btn"
                                    type="button"
                                    onClick={()=> onBack()}
                                >
                                    {/* <CancelIcon /> */}
                                     Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className={isSubmit ? expandClass : ""}
                                    disabled={isSubmit}
                                >
                                    {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Container>
    );
};

CreateForm.propTypes = {
    isHorizontal: PropTypes.bool,
};

CreateForm.defaultProps = {
    isHorizontal: false,
};

export default reduxForm({
    form: "client_registration",
})(CreateForm);
