/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from 'react-router-dom';
import ApiServices from "@/utils/ApiServices";
import { TABLE_SETTINGS } from "@/utils/constant";
import { successMsg, globalError } from "@/utils/message_herlper";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Container, Card, CardBody } from "reactstrap";
import PropertyTable from "./components/PropertyTable";
import ClientsTable from "./components/ClientsTable";

const Details = (props) => {
    let { type } = useParams()
    let year = new Date().getFullYear();

    return (
        <>
            <Container className="dashboard">
                <Row>
                    <Col md={12}>
                       <h3 className="page-title"> 
                            { type === 'active-clients' ?  'All Active Client ':''}
                            { type === 'new-clients' ?  `New Client Accounts In ${year}`:''}
                            { type === 'active-property' ?  'All Active Property ':''}
                            { type === 'new-property' ?  `New Property  In ${year}`:''}
                       </h3>
                    </Col>
                </Row>
                <Card className="card-controlled" >
                    <CardBody>
                        {
                            type === 'active-clients'  || type === 'new-clients' ?
                               <ClientsTable type={type} />
                            :''
                        }
                        {
                            type === 'active-property'  || type === 'new-property' ?
                               <PropertyTable type={type} />
                            :''
                        }
                        
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};

export default React.memo(Details);
