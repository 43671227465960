/* eslint-disable */
import React from 'react';
import { Col, Row, Table, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import AngleRight from '@/images/angleArrowRight'
import _ from 'lodash';
import Empty from "@/components/Empty";

const RecentViewed = ({recent}) => {  
   

    return (
        <Row>
            <Col md={12}>
                <div className="dashboard-card-title">
                    <p>Recently viewed</p>
                </div>
            </Col>
            <Col md={12}>
                <ul className="recent-viewed-list my-3">
                { _.isArray(recent) &&recent.map(( user ) => (
                    <li key={user.id}>
                        <a href={`/client/details/${user.client_id}`} rel="noreferrer noopener">{user.client_first_name} {user.client_last_name} <AngleRight color="#383938"  target="_blank" rel="noopener noreferrer"  /></a>
                    </li>
                ))}
                </ul>
                {_.isArray(recent) && recent.length < 1 ? <Empty /> :''}
            </Col>
        </Row>
    )
}
export default RecentViewed;