/* eslint-disable */
import React from "react";
import { Progress } from "reactstrap";
import TrashIcon from "@/images/trashIcon";
import { USERLEVELS } from "@/utils/constant";

const CardDocument = ({
    progress,
    name,
    type,
    is_view,
    property_id,
    id,
    onDelete,
    owner_name,
}) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    
    return (
        <div
            className={`multi-upload-files ${property_id === 0 ? "error" : ""}`}
            //onClick={() => (is_view ? agentFile() : () => false)}
            //style={{ cursor: is_view ? "pointer" : "default" }}
        >
            <div>
                <span className="filetype">{type.split("/").pop()}</span>
                <div className="progress-wrapper">
                    <span className="filename">{name}</span>
                    {!is_view && (
                        <Progress animated value={progress}>
                            {progress}%
                        </Progress>
                    )}
                </div>
            </div>
            {is_view && (
                <div className="action">
                    {owner_name || "None"}
                    {userData?.userlevel === USERLEVELS.ADMIN && (
                        <span
                            className="cursor-pointer docu-delete"
                            onClick={() => onDelete(id)}
                        >
                            <TrashIcon
                                color={`${
                                    property_id === 0 ? "#f44336" : "#FFA647"
                                }`}
                            />{" "}
                            Delete
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default CardDocument;
