/* eslint-disable */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Button, Col, Row } from 'reactstrap';
import Empty from "@/components/Empty";
import { USERLEVEL } from "@/utils/constant";
import ProfileRequestChanges from '@/containers/Client/components/profileRequestchanges';
import PropertyRequestChanges from '@/containers/Properties/components/PropertyRequestChanges';
import PropertyDeleteRequest from '@/containers/Properties/components/PropertyDeleteRequest';
import ReactHtmlParser from 'react-html-parser';


const ActivitiesList = ({ activityData, clientData }) => {  

    function isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }



    return ( <>
        { _.isArray(activityData) && 
        <p className="tab-content-title pt-4">Activities</p>
        }
        <div className="activity-table-wrapper mt-3">
             {_.isArray(activityData) && activityData.map((obj,i) => (  
                <div className="activities-row">
                    <Row key={i}>
                        <Col md={6}> 
                            <p className="activity_user"><b>{obj.user_type && obj.user_type === 1 ? 'Admin:' : 'Client:'} {obj?.client_first_name || obj?.first_name || clientData?.client_first_name} {obj?.client_last_name || obj?.last_name || clientData?.client_last_name }</b></p>
                        </Col>
                        <Col md={6} className="text-right">
                            <p>{moment(obj.created_at).format('MMM DD, h:mm A')}</p>
                        </Col>
                        <Col md={12} className="activity-changes">
                            {obj.type === 'profile_change' && 
                            <ProfileRequestChanges clientData={clientData} requestChangesData={obj}/>
                            }
                            {obj.type === 'property_change' && 
                            <PropertyRequestChanges clientData={clientData} requestChangesData={obj}/>
                            }
                            {obj.type === 'delete_property' && 
                            <PropertyDeleteRequest clientData={clientData} requestChangesData={obj}/>
                            }
                            
                            { obj.type !== 'profile_change' && <>
                           
                            {obj.type ?  <p className="color-secondary">{obj?.details}</p> : ReactHtmlParser(obj?.details)}
                            {!_.isNull(obj.fields) && !_.isEmpty(obj.fields) && isJSON(obj.fields) && _.isArray(JSON.parse(obj.fields)) && JSON.parse(obj.fields).map((obj2,i2) => {
                                var field = null;
                                for (var key in obj2) {
                                    field = key
                                }
                                return(
                                    <>
                                        <p>{field} from <b>{obj2[field]?.old}</b> to <b>{obj2[field]?.new}</b></p>
                                    </>
                                )
                            })}
                            </>}
                        </Col>
                    </Row>
                   
                </div>
            ))}
            {_.isArray(activityData) && activityData.length < 1 ? <Empty /> :''}
        </div>
        </>
    )
}
export default React.memo(ActivitiesList);


